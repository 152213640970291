import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, Input, Badge, Card, Modal, message } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import "react-simple-keyboard/build/css/index.css";
import Scan from "../../../../assets/images/scan.gif";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import SearchIcon from "../../../../assets/images/searchImg.svg";
import SearchImg from "../../../../assets/images/searchIcon.svg";
import ScanItem from "../../../../assets/images/scanItem.svg";
import AddBag from "../../../../assets/images/bag-outline.svg";
import WeightImg from "../../../../assets/images/outline-weight.svg";
import MoreOptionImg from "../../../../assets/images/more-option.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import Offers from "../../../../assets/images/offers.png";
import { PlusOutlined, MinusOutlined, CloseSquareFilled, SearchOutlined, HomeOutlined, AppstoreOutlined, ShoppingCartOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ProductPanel from "../ProductPanel";
import QuantityUpdate from "../QuantityUpdate";
import BarCodeComponent from "../../../../lib/BarCodeComponet";
import db from "../../../../database";
import { Scrollbars } from "react-custom-scrollbars";
import kioskBGI from "../../../../assets/images/kioskBGI.png";
import MoreOptions from "../../../../assets/images/MoreOptions.svg";
import kioskStyles from "./kioskStyle.json";

const HomeComponent = (props) => {
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  const {
    addProduct,
    isProductsVisible,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    // productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeProductModal,
    ref,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    onBarcodeInput,
    layoutType,
    setLayoutType,
    setSelectedProductInCart,
    setKioskUI,
    kioskFilteredProducts,
    deleteCart,
    // setProductsList,
    pickProduct,
    setIsInputFocused,
    isInputFocused,
    categoriesData,
    selectedCategoryId,
    setSelectedCategoryId,
    productsList,
    setProductsList,
    originalProductsList,
    setOriginalProductsList,
    selectedCategory,
    setSelectedCategory,
  } = props;

  // const [productsList, setProductsList] = useState([]);
  // const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const customerId = tillData?.tillAccess?.csBunit.b2cCustomer.cwrCustomerId;
  const productsDisplay = "kiosk";
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userName = userData?.user;

  const [cartItemCount, setCartItemCount] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState(productsList);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [bestSellers, setBestSellers] = useState([]);
  const [isLocked, setIsLocked] = useState(false);
  const isLandscape = window.innerWidth > window.innerHeight && (themeJSON?.viewMode === "landscape" || !themeJSON?.viewMode);

  const logoStyles = isThemeLogo
    ? { height: "7vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer", aspectRatio: "1/1" } // Theme logo (Square Aspect Ratio)
    : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer", aspectRatio: "1/1" }; // Kiosk logo (Square Aspect Ratio)

  const mainDivStyles = themeJSON.appBgImageURL
    ? {
        backgroundImage: `url(${themeJSON.appBgImageURL})`,
        backgroundColor: "#f3f4f9",
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: isLocked ? "none" : "block",
      }
    : {
        backgroundImage: `url(${kioskBGI})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        backgroundColor: "#f3f4f9",
        height: "100vh",
        width: "100%",
        position: "absolute",
      };

  const handleProductClick = (product) => {
    pickProduct(product);
    setCartItemCount((prevCount) => prevCount + 1);
    // const cart = JSON.parse(localStorage.getItem("cartObj"));
    // if (cart?.items?.length > 0) {
    //   setCartItemCount((prevCount) => prevCount + 1);
    // }
  };

  const AddBag1 = JSON.parse(localStorage.getItem("posConfig") || "{}");
  const showBags = posConfig?.showBags === "Y";

  useEffect(() => {
    db.products.toArray().then((productsFetched) => {
      const bestSellers = productsFetched.filter((product) => product.isBestSellers);
      setBestSellers([...bestSellers]);
    });
  }, []);

  // useEffect(() => {
  //   console.log("productsList-------->", productsList);
  //   setProductsList([...productsList]);
  // }, []);

  useEffect(() => {
    if (posConfig.showProductScanPage === "Y") {
      localStorage.setItem("layoutType", 0);
      setLayoutType(0);
    } else {
      localStorage.setItem("layoutType", 2);
      setLayoutType(2);
    }
    filterProducts("All");
  }, []);

  const filterProducts = async (categories) => {
    try {
      let productsData = await db.products.toArray();

      if (categories !== "All") {
        productsData = productsData.filter((item) => categories === item.mProductCategoryId);
      } else {
        db.products
          // .limit(10)
          .toArray()
          .then((productsFetched) => {
            setProductsList([...productsFetched]);
            setOriginalProductsList([...productsFetched]);
          });
      }

      setProductsList(productsData);
      setOriginalProductsList(productsData);
    } catch (error) {
      console.error("Error filtering products:", error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(null);
    setSelectedCategoryId(categoryId);
    filterProducts(categoryId);
  };

  const handleCategoryClick2 = (category) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "This action will remove all products from the cart.Do you wish to proceed?",
        icon: null,
        cancelText: "No",
        okText: "Yes",
        centered: true,
        autoFocusButton: null,
        onOk() {
          deleteCart();
          setKioskUI(0);
        },
      });
    } else {
      setKioskUI(0);
    }
  };

  useEffect(() => {
    if (productSearchInput === "") {
      setProductsList(originalProductsList);
    }
  }, [productSearchInput]);

  const styles = {
    container: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fff",
    },
    header: {
      width: "100%",
      height: "8vh",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 3vw",
      fontSize: "2.5vh",
      fontWeight: "500",
      color: "#2F3856",
    },
    searchBar: {
      width: "100%",
      padding: "0.7vh 2vw",
      borderRadius: "25px",
    },
    featuredSection: {
      width: "100%",
      padding: "0 0 2vh 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    featuredList: {
      display: "flex",
      gap: "2vw",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    featuredCard: {
      width: "22vw",
      minWidth: "150px",
      padding: "1.5vh",
      borderRadius: "1.5vh",
      textAlign: "center",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
    featuredHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "94%",
    },
    featuredTitle: {
      fontSize: "2vh",
      fontWeight: "600",
      color: "#2F3856",
    },
    viewAll: {
      fontSize: "1.6vh",
      color: "#2F3856",
      textDecoration: "none",
      cursor: "pointer",
    },
    menuCategories: {
      display: "flex",
      justifyContent: "center",
      gap: "2vw",
      margin: "2vh 0",
    },
    categoryBtn: {
      fontSize: "1.8vh",
      padding: "1vh 2vw",
      borderRadius: "1vh",
      backgroundColor: "white",
    },
    activeCategoryBtn: {
      backgroundColor: "#2F3856",
      color: "white",
    },
    productGrid: {
      width: "94%",
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(280px, 1fr))",
      gap: "2vw",
      margin: "2vh auto",
    },
    productCard: {
      padding: "2vh",
      borderRadius: "1.5vh",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
    productName: {
      fontSize: "2vh",
      fontWeight: "500",
    },
    productDesc: {
      fontSize: "1.5vh",
      color: "#555",
    },
    price: {
      fontSize: "2vh",
      fontWeight: "500",
      color: "#2F3856",
    },
    footer: {
      width: "100%",
      height: "8vh",
      backgroundColor: "#2F3856",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      position: "fixed",
      color: "#fff",
      bottom: 0,
    },
    footerIcon: {
      fontSize: "2.5vh",
      color: "white",
      cursor: "pointer",
    },
    menuContainer: {
      display: "flex",
      backgroundColor: "#f3f3f9",
      borderRadius: "20px",
      justifyContent: "space-between",
      alignItems: "center",
      width: "94%",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
    categoryButton: {
      flex: 1, // Ensures equal width for each button
      padding: "10px 0",
      borderRadius: "20px",
      border: "none",
      backgroundColor: "transparent",
      color: "#333",
      fontSize: "14px",
      cursor: "pointer",
      transition: "background 0.3s",
      textAlign: "center", // Centers text inside each button
    },
    activeButton: {
      backgroundColor: "#2F3856",
      color: "#fff",
      fontWeight: "500",
    },
  };

  const cartStyles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100%",
    },
    header: {
      background: "#fff",
      color: "#2F3856",
      textAlign: "center",
      padding: "1.4vh 0",
      fontSize: "2vh",
      fontWeight: "500",
    },
    content: {
      flex: 1,
      padding: "16px",
    },
    card: {
      marginBottom: "10px",
      height: "8.5vh",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      padding: "1vh",
    },
    summary: {
      borderTop: "1px solid #ddd",
      position: "absolute",
      bottom: "9vh",
      width: "100%",
      padding: "1vh 2vw",
    },
    summaryRow: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "1.5vh",
      marginBottom: "8px",
    },

    footer: {
      background: "#fff",
      padding: "10px 0",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      position: "sticky",
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #ddd",
    },
    footerIcon: {
      fontSize: "24px",
    },
    button: {
      width: "100%",
      background: "#2F3856",
      color: "#fff",
      height: "4.5vh",
      fontSize: "1.5vh",
      fontWeight: "500",
    },
  };

  return (
    <div style={{ ...mainDivStyles, display: "flex" }}>
      {!isInputFocused && (
        <BarCodeComponent onScan={onBarcodeInput} isInputFocused={isInputFocused} setProductSearchInput={setProductSearchInput} productSearchInput={productSearchInput} />
      )}
      {/* <div style={isLandscape ? kioskStyles.homeScreen.landscape.header : kioskStyles.homeScreen.portrait.header}>
        <img src={logoURL} style={logoStyles} />
        <p style={isLandscape ? kioskStyles.homeScreen.landscape.userName : kioskStyles.homeScreen.portrait.userName}>
          <ShoppingCartOutlined style={styles.footerIcon} />
        </p>
      </div> */}

      {layoutType === 0 ? (
        <div style={{ width: "77%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div style={{ paddingBottom: "5vh" }}>
            <img src={Scan} className="scan-cl-img" />
          </div>
          <div style={{ paddingBottom: "3vh" }}>
            <p style={{ fontSize: "2vw", fontWeight: "600", color: "#fff", textAlign: "center", margin: "0", letterSpacing: "0.05vw" }}>Please start scanning your items</p>
            {/* <p style={{ fontSize: "1.4vw", fontWeight: "500", color: "#929098" }}>If you miss any item or not able to scan, click search for item option</p> */}
          </div>
        </div>
      ) : layoutType === 1 ? (
        // <div style={{ width: "77%", height: "100vh", display: "flex", flexDirection: "column", padding: " 0 1.5%", paddingTop: "11.3vh", position: "relative" }}>
        //   <Table
        //     className="kiosk-cart-table-pos"
        //     style={{
        //       width: "100%",
        //       overflowY: "auto",
        //       border: "none",
        //       // fontSize: "1.5vw",
        //       borderRadius: "7px",
        //       border: "1px solid rgba(146, 144, 152, 0.4)",
        //       height: "76vh",
        //       background: "#fff",
        //     }}
        //     pagination={false}
        //     dataSource={JSON.parse(localStorage.getItem("cartObj")).items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items ? cart.items : []}
        //     sticky
        //     rowClassName={(record) => (selectedRowKeys[0] === record.key ? "tableRowSelectedBlue" : "tableRowNonSelectedWhite")}
        //     columns={[
        //       {
        //         title: "Item Name",
        //         dataIndex: "name",
        //         key: "name",
        //         width: "47%",
        //         render: (text, record, index) => {
        //           return (
        //             <>
        //               <div className="tableRow">{record.name}</div>
        //               <div className="offerName">
        //                 {cart.discount > 0 ? record.discountName || "" : ""}{" "}
        //                 {cart.discount > 0 ? record.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
        //               </div>
        //             </>
        //           );
        //         },
        //       },
        //       {
        //         title: "Qty",
        //         dataIndex: "weight",
        //         key: "weight",
        //         width: "11%",
        //         render: (text, record, index) => {
        //           const wt = parseFloat(text);
        //           if (Number.isInteger(wt)) {
        //             return (
        //               <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        //                 {selectedProductInCart.lineId === record.lineId ? (
        //                   <MinusOutlined
        //                     title="Decrease Qty"
        //                     onClick={() => decreaseProductQty(selectedProductInCart)}
        //                     style={{
        //                       marginRight: "5px",
        //                       padding: "0.5vh 0.5vh",
        //                       border: "1px solid rgba(0,0,0)",
        //                       fontSize: "1vh",
        //                       borderRadius: "2px",
        //                       cursor: "pointer",
        //                       fontWeight: "500",
        //                     }}
        //                   />
        //                 ) : (
        //                   ""
        //                 )}
        //                 <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "1.1vw" }}>{wt}</div>
        //                 <div className="offerName">
        //                   {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
        //                 </div>
        //                 {selectedProductInCart.lineId === record.lineId ? (
        //                   <PlusOutlined
        //                     title="Increase Qty"
        //                     onClick={() => increaseProductQty(selectedProductInCart)}
        //                     style={{
        //                       marginLeft: "5px",
        //                       padding: "0.5vh 0.5vh",
        //                       border: "1px solid rgba(0,0,0)",
        //                       fontSize: "1vh",
        //                       borderRadius: "2px",
        //                       cursor: "pointer",
        //                     }}
        //                   />
        //                 ) : (
        //                   ""
        //                 )}
        //               </div>
        //             );
        //           } else {
        //             return (
        //               <>
        //                 <div>{parseFloat(text).toFixed(2)}</div>
        //                 <div className="offerName">
        //                   {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
        //                 </div>
        //               </>
        //             );
        //           }
        //         },
        //         align: "center",
        //       },
        //       {
        //         title: "Discount",
        //         dataIndex: "discount",
        //         key: "discount",
        //         width: "11%",
        //         render: (text, record) => {
        //           return (
        //             <div>
        //               <span style={{ fontWeight: "500", fontSize: "1.1vw" }}>{parseFloat(text).toFixed(0)}</span>
        //             </div>
        //           );
        //         },
        //         align: "center",
        //       },
        //       {
        //         title: "Tax",
        //         dataIndex: "taxAmount",
        //         key: "taxAmount",
        //         width: "11%",
        //         render: (text, record) => {
        //           return (
        //             <div>
        //               <span style={{ fontWeight: "500", fontSize: "1.1vw", color: "black" }}>{parseFloat(text).toFixed(2)}</span>
        //             </div>
        //           );
        //         },
        //         align: "center",
        //       },
        //       {
        //         title: "Price",
        //         dataIndex: "realPrice",
        //         key: "realPrice",
        //         width: "11%",
        //         render: (text, record) => {
        //           return (
        //             <div>
        //               <span style={{ fontWeight: "500", fontSize: "1.1vw" }}>{parseFloat(text).toFixed(0)}</span>
        //             </div>
        //           );
        //         },
        //         align: "center",
        //       },
        //       {
        //         title: "Net Value",
        //         dataIndex: "nettotal",
        //         key: "nettotal",
        //         width: "13.5%",
        //         render: (text, record) => {
        //           return (
        //             <div style={{ position: "relative" }}>
        //               {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
        //               &nbsp;
        //               <span style={{ fontWeight: "500", fontSize: "1.1vw", width: "11.5%" }}>{parseFloat(text).toFixed(0)}</span>
        //               &nbsp;
        //               {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
        //               <img
        //                 src={DeleteProduct}
        //                 onClick={() => {
        //                   if (selectedProductInCart.lineId === record.lineId) {
        //                     clearSelectedProductInCart();
        //                     removeProductModal();
        //                   }
        //                 }}
        //                 style={{ height: "18px", width: "18px", cursor: "pointer", position: "absolute", right: 0, top: "15%" }}
        //               />
        //             </div>
        //           );
        //         },
        //         align: "center",
        //       },
        //     ]}
        //     onRow={(record) => ({
        //       onClick: () => {
        //         selectProductInCart(record);
        //       },
        //     })}
        //   />
        //   <Row>
        //     <Col span={16}></Col>
        //     <Col span={8} style={{ height: "15vh" }}>
        //       <Row style={{ paddingTop: "2vh" }}>
        //         <Col span={13} offset={1}>
        //           <p style={{ margin: "0", color: "black", fontSize: "1.2vw" }}>Tax:</p>
        //         </Col>
        //         <Col span={10}>
        //           <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "black", fontSize: "1.2vw" }}>{cart.tax.toFixed(2)}</p>
        //         </Col>
        //       </Row>
        //       <Row>
        //         <Col span={13} offset={1}>
        //           <p style={{ margin: "0", color: "black", fontSize: "1.2vw" }}>Discount:</p>
        //         </Col>
        //         <Col span={10}>
        //           <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "black", fontSize: "1.2vw" }}>{cart.discount.toFixed(2)}</p>
        //         </Col>
        //       </Row>
        //       <Row>
        //         <Col span={13} offset={1}>
        //           <p style={{ margin: "0", color: "black", fontSize: "1.5vw", fontWeight: "600", lineHeight: "1.2" }}>Total Amount:</p>
        //         </Col>
        //         <Col span={10}>
        //           <p style={{ textAlign: "right", margin: "0", fontWeight: "600", color: "black", fontSize: "1.5vw", lineHeight: "1.2" }}>{cart.total.toFixed(2)}</p>
        //         </Col>
        //       </Row>
        //     </Col>
        //   </Row>
        //   <QuantityUpdate
        //     selectedProductInCart={selectedProductInCart}
        //     setSelectedProductInCart={setSelectedProductInCart}
        //     addProduct={addProduct}
        //     setIsQtyUpdate={setIsQtyUpdate}
        //     isQtyUpdate={isQtyUpdate}
        //     increaseProductQty={increaseProductQty}
        //     decreaseProductQty={decreaseProductQty}
        //     removeProductModal={removeProductModal}
        //     setIsSearchProducts={setIsSearchProducts}
        //     pickProduct={pickProduct}
        //   />
        // </div>
        <div style={cartStyles.container}>
          <header style={cartStyles.header}>Your Cart</header>
          <div style={cartStyles.content}>
            <Scrollbars style={{ height: "65vh" }}>
              {(JSON.parse(localStorage.getItem("cartObj")).items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items ? cart.items : []).map((item, index) => (
                <Card key={index} style={cartStyles.card}>
                  <p style={{ fontSize: "1.5vh", fontWeight: 500 }}>{item.name}</p>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <MinusOutlined
                      title="Decrease Qty"
                      onClick={() => decreaseProductQty(item)}
                      style={{
                        marginRight: "5px",
                        padding: "0.5vh 0.5vh",
                        border: "1px solid rgba(0,0,0)",
                        fontSize: "1vh",
                        borderRadius: "2px",
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    />
                    <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "1.5vh" }}>{item.weight}</div>
                    <PlusOutlined
                      title="Increase Qty"
                      onClick={() => increaseProductQty(item)}
                      style={{
                        marginLeft: "5px",
                        padding: "0.5vh 0.5vh",
                        border: "1px solid rgba(0,0,0)",
                        fontSize: "1vh",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Card>
              ))}
            </Scrollbars>
          </div>
          <div style={cartStyles.summary}>
            <div style={cartStyles.summaryRow}>
              <span>Subtotal:</span>
              <span>
                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                {(cart.total - cart.tax).toFixed(2)}
              </span>
            </div>
            <div style={cartStyles.summaryRow}>
              <span>Tax:</span>
              <span>
                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                {cart.tax.toFixed(2)}
              </span>
            </div>
            <div style={cartStyles.summaryRow}>
              <strong>Total:</strong>
              <strong>
                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                {cart.total.toFixed(2)}
              </strong>
            </div>
            <Button
              style={cartStyles.button}
              onClick={() => {
                if (!cart?.items || cart.items.length === 0) {
                  message.warn("Your cart is empty. Please add items before proceeding to payment.");
                  return;
                }
                openPaymentModal();
                setKioskUI(5);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 5 },
                });
                window.dispatchEvent(customEvent);
                localStorage.setItem("kioskUI", 5);
              }}
            >
              Proceed to Payment
            </Button>
          </div>
        </div>
      ) : layoutType === 2 ? (
        <div style={styles.container}>
          {/* Header */}
          <header style={styles.header}>
            <div>
              <img src={logoURL} style={{ height: "4vh", borderRadius: "50%" }} />
              {tillData.tillAccess.csBunit.name}
            </div>
            <Badge count={cart?.items?.length} overflowCount={99} showZero>
              <ShoppingCartOutlined
                onClick={() => {
                  if (cart.items.length > 0) setLayoutType(1);
                }}
                style={{ fontSize: "2.5vh", color: "#2F3856", cursor: "pointer" }}
              />
            </Badge>
          </header>
          <div style={{ width: "95%", padding: "2vh 0" }}>
            <Input
              placeholder="Search for products by code"
              prefix={<img src={SearchImg} alt="" onClick={getSearchedProducts} />}
              onChange={(e) => {
                const searchValue = e.target.value.toLowerCase();
                setProductSearchInput(searchValue);
                if (searchValue === "") {
                  setProductsList(originalProductsList);
                } else {
                  // const filtered = productsList.filter((product) => product.name.toLowerCase().includes(searchValue));
                  // setProductsList(filtered);
                  const filtered = originalProductsList.filter((product) => product.name.toLowerCase().includes(searchValue));
                  setProductsList(filtered);
                }
              }}
              value={productSearchInput}
              onFocus={() => {
                setIsInputFocused(true);
              }}
              onBlur={() => {
                setIsInputFocused(false);
              }}
              onPressEnter={getSearchedProducts}
              className="productSearch-ks"
              style={styles.searchBar}
            />
          </div>

          {/* Featured Items */}
          {bestSellers.length > 0 && (
            <section style={styles.featuredSection}>
              <div style={styles.featuredHeader}>
                <h2 style={styles.featuredTitle}>Featured Items</h2>
                <span style={styles.viewAll}>View All</span>
              </div>
              <div style={styles.featuredList}>
                {bestSellers.slice(0, 4).map((item, index) => (
                  <Card
                    key={index}
                    style={{
                      ...styles.featuredCard,
                      backgroundImage: `url(${item.imageurl})`,
                      backgroundColor: "#f3f4f9",
                      backgroundPosition: "100% 100%",
                      backgroundSize: "cover",
                    }}
                  >
                    <p>{item.name}</p>
                    <p style={styles.price}>{item.sunitprice}</p>
                  </Card>
                ))}
              </div>
            </section>
          )}

          {/* Menu Categories */}
          <div style={styles.menuContainer}>
            <button
              style={{
                ...styles.categoryButton,
                ...(selectedCategoryId === "All" ? styles.activeButton : {}),
              }}
              onClick={() => handleCategoryClick("All")}
            >
              All
            </button>
            {categoriesData.map((category) => (
              <button
                key={category.mProductCategoryId}
                style={{
                  ...styles.categoryButton,
                  ...(selectedCategoryId === category.mProductCategoryId ? styles.activeButton : {}),
                }}
                onClick={() => handleCategoryClick(category.mProductCategoryId)}
              >
                {category.name}
              </button>
            ))}
          </div>

          {/* Product List */}
          <ProductPanel
            productsDisplay={productsDisplay}
            checkIsManualWeight={checkIsManualWeight}
            setIsSearchProducts={setIsSearchProducts}
            getMoreProducts={getMoreProducts}
            productsList={productsList}
            productItems={productItems}
            setProductItems={setProductItems}
            selectProductInCart={selectProductInCart}
            productArray={productArray}
            setProductArray={setProductArray}
            isProductsFilter={isProductsFilter}
            getSearchedProducts={getSearchedProducts}
            productListCardRef={productListCardRef}
            setIsProductsVisible={setIsProductsVisible}
            addProduct={addProduct}
            cart={cart}
            decreaseProductQty={decreaseProductQty}
            // pickProduct={pickProduct}
            pickProduct={handleProductClick}
            // isProductsVisible={isProductsVisible}
          />
        </div>
      ) : null}

      {/* {layoutType === 0 || layoutType === 1 ? (
        <div style={{ width: "23%", height: "100vh", display: "flex", flexDirection: "column", position: "relative" }}>
          <div
            onClick={() => {
              localStorage.setItem("layoutType", 2);
              const customEvent = new CustomEvent("customStorageChange", {
                detail: { key: "layoutType", newValue: 2 },
              });
              window.dispatchEvent(customEvent);
            }}
            style={{
              width: "93%",
              height: "16.8vh",
              // backgroundColor: "#626367",
              backgroundColor: themeJSON.productTableBGColor || "#626367",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "9.3vh",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            <img src={SearchIcon} style={{ height: "2.4vw" }} />
            <p style={{ margin: "0", fontSize: "1.4vw", color: "#fff", paddingTop: "1.6vh" }}>Search For Item</p>
          </div>

          {showBags && (
            <div
              onClick={() => {
                db.products.toArray().then((productsFetched) => {
                  const bagItems = productsFetched.filter((item) => item?.productSegment === "BG");
                  setProductsList(bagItems);
                  setSelectedCategoryId(null);
                });
                localStorage.setItem("layoutType", "2");
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 2 },
                });
                window.dispatchEvent(customEvent);
              }}
              style={{
                width: "92%",
                height: "16.8vh",
                backgroundColor: themeJSON.productTableBGColor || "#626367",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "3vh",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <img src={AddBag} style={{ height: "2.4vw" }} />
              <p
                style={{
                  margin: "0",
                  fontSize: "1.4vw",
                  color: "#fff",
                  paddingTop: "1.6vh",
                }}
              >
                Add Bag
              </p>
            </div>
          )}

          {layoutType === 1 ? (
            <div
              style={{
                backgroundColor: themeJSON.orderNoTextColor,
                position: "absolute",
                bottom: "1.5vh",
              }}
              className="custom-box"
              onClick={() => {
                openPaymentModal();
                setKioskUI(5);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 5 },
                });
                window.dispatchEvent(customEvent);
                localStorage.setItem("kioskUI", 5);
              }}
            >
              <p
                style={{
                  margin: "0",
                  fontSize: "1.86vw",

                  // color: themeJSON.orderNoTextColor,
                  textAlign: "center",
                  color: "#FFF",
                }}
              >
                Finish & Pay
              </p>
            </div>
          ) : null}
        </div>
      ) : null} */}
      <div className="left-bottom-images">
        <footer style={styles.footer}>
          <HomeOutlined style={styles.footerIcon} onClick={() => handleCategoryClick2("back")} />
          <AppstoreOutlined
            style={styles.footerIcon}
            onClick={() => {
              setKioskUI(3);
              setLayoutType(2);
            }}
          />
          <Badge count={cart?.items?.length} overflowCount={99} showZero>
            <ShoppingCartOutlined
              style={styles.footerIcon}
              onClick={() => {
                if (cart.items.length > 0) setLayoutType(1);
              }}
            />
          </Badge>
          <QuestionCircleOutlined style={styles.footerIcon} />
        </footer>
      </div>
    </div>
  );
};

export default HomeComponent;
