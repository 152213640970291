import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Input, Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { MinusOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/icon.png";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import moment from "moment";
import { getMultilingualText } from "../../utility/getMultilingualText ";

const CloseTill = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const paymentMethodList = tillData.tillAccess.csBunit.paymentMethodList;
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const [loading, setLoading] = useState(false);
  const sessionId = tillSession.tillSessionId;
  const history = useHistory();

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });
  const [cashExpectedAmount, setCashExpectedAmount] = useState(0);
  const [denominations, setDenominations] = useState([]);
  const [allPaymentsData, setAllPaymentsData] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [cashReturnAmount, setCashReturnAmount] = useState(0);
  const [cashValues, setCashValues] = useState({
    cashIn: 0,
    cashOut: 0,
    pettCashIn: 0,
    pettCashOut: 0,
  });

  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  useEffect(() => {
    const fetchData = async () => {
      if (!tillSession) return history.push("/");

      // let cashStats = { cashIn: 0, cashOut: 0, pettyCashIn: 0, pettyCashOut: 0 };
      let cashStats = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };

      try {
        setLoading(true);
        // Fetch cash transactions
        const cashDetails = await db.cashInCashOut.toArray();
        cashDetails.forEach(({ type, amount }) => {
          cashStats[type] += parseFloat(amount || 0);
        });
        setCashValues(cashStats);

        // Fetch currency details
        const registrations = await db.tillRegistrations.toArray();
        const currency = registrations[0]?.tillAccess?.[0]?.csBunit?.csCurrency;
        if (currency) {
          setCurrencyType({
            currSymbolLeft: currency.symbolrightside ? "" : currency.currSymbol,
            currSymbolRight: currency.symbolrightside ? currency.currSymbol : "",
            stdPrecision: currency.stdPrecision || 2,
          });
        }

        // Fetch orders for the session
        const orders = await db.orders.where("tillSessionId").equals(sessionId).toArray();
        setTotalOrdersCount(orders.length);

        // Process payments
        const updatedPayments = paymentMethodList.map((payment) => {
          let expectedAmount = 0,
            paymentReturn = 0,
            transactionCount = 0;

          orders.forEach(({ total, payments = [] }) => {
            payments.forEach(({ name, amount }) => {
              if (payment.name.toLowerCase() === name.toLowerCase()) {
                const value = parseFloat(amount || 0);
                total > 0 ? (expectedAmount += value) : (paymentReturn += Math.abs(value));
                transactionCount++;
              }
            });
          });

          return {
            ...payment,
            expectedAmount,
            amount: 0,
            paymentReturn,
            difference: Math.abs(paymentReturn) - expectedAmount,
            transactionCount,
          };
        });
        setAllPaymentsData(updatedPayments);

        // Handle cash payments
        const cashPayment = updatedPayments.find((p) => p.name.toLowerCase() === "cash");
        if (cashPayment) {
          const { expectedAmount, paymentReturn, transactionCount } = cashPayment;
          const openingAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount || 0;
          const includeOpening = tillData?.tillAccess?.csBunit?.incDayOpening !== "N";

          setTotalOpeningAmount(openingAmount);
          setCashSaleAmount(expectedAmount);
          setCashReturnAmount(paymentReturn);
          setCashExpectedAmount(
            // expectedAmount - paymentReturn + cashStats.cashIn + cashStats.pettyCashIn - cashStats.pettyCashOut - cashStats.cashOut + (includeOpening ? openingAmount : 0)
            expectedAmount - paymentReturn + cashStats.cashIn + cashStats.pettCashIn - cashStats.pettCashOut - cashStats.cashOut + (includeOpening ? openingAmount : 0)
          );
        }

        // Update till session details
        const tillEvent = await db.tillEvents.where("tillSessionId").equals(sessionId).toArray();
        if (tillEvent.length > 0 && tillEvent[0]?.cashInOutData) {
          console.log("tillEvent[0].cashInOutData--------->", tillEvent[0].cashInOutData);
          setCashValues(tillEvent[0].cashInOutData);
          cashStats = tillEvent[0].cashInOutData;
        }

        const updatedTillSession = { ...tillSession };
        const cashIndex = updatedPayments.findIndex((p) => p.name.toLowerCase() === "cash");
        const closingCash = tillSession?.closingCash || 0;
        let updatedAmount = 0;
        if (cashIndex !== -1) {
          updatedAmount = parseFloat(updatedPayments[cashIndex].expectedAmount) + cashStats.cashIn - cashStats.cashOut + cashStats.pettyCashIn - cashStats.pettyCashOut;
          if (tillData?.tillAccess?.csBunit?.incDayOpening !== "N") {
            updatedAmount += parseFloat(totalOpeningAmount || 0);
          }

          updatedPayments[cashIndex] = {
            ...updatedPayments[cashIndex],
            expectedAmount: updatedAmount,
            amount: closingCash,
            actualAmount: closingCash,
            cashSaleAmount: parseFloat(cashSaleAmount?.toFixed(tillData?.tillAccess?.csBunit?.currencies?.[0]?.prcPrecision || 2)),
            difference: parseFloat(
              (closingCash - (updatedAmount - updatedPayments[cashIndex].paymentReturn)).toFixed(tillData?.tillAccess?.csBunit?.currencies?.[0]?.prcPrecision || 2)
            ),
          };
        }

        Object.assign(updatedTillSession, {
          closingTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          closingCash,
          closingCashDenominations: denominations || [],
          allPaymentsData: updatedPayments,
          totalOrdersCount,
        });

        await db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);
        localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
        if (cashIndex === -1 || posConfig.showDenominations === "N") {
          history.push("/close-till-all");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error during data fetch:", error);
      }
    };

    fetchData();
  }, []);

  // Manage denominations
  useEffect(() => {
    if (tillSession?.closingCashDenominations) {
      setDenominations(tillSession.closingCashDenominations);
    } else {
      const sortedDenominations = currenciesList[0]?.denominations
        ?.map((denom) => ({
          ...denom,
          count: 0,
          amount: 0,
        }))
        .sort((a, b) => a.value - b.value);
      setDenominations(sortedDenominations || []);
    }
  }, []);

  // Set total amount
  useEffect(() => {
    if (tillSession?.closingCash) {
      setTotalAmount(tillSession.closingCash);
    }
  }, []);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    if (di !== -1 && denominations[di]) {
      denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
      denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    }
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount || 0;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const excuteCloseTill = async () => {
    let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };

    try {
      const tillEvent = await db.tillEvents.where("tillSessionId").equals(sessionId).toArray();

      if (tillEvent.length > 0 && tillEvent[0]?.cashInOutData) {
        console.log("tillEvent[0].cashInOutData------>", tillEvent[0].cashInOutData);
        setCashValues(tillEvent[0].cashInOutData);
        obj = tillEvent[0].cashInOutData;
      }

      confirmBtnRef.current.disabled = true;
      cancelBtnRef.current.disabled = true;

      const updatedTillSession = { ...tillSession };
      const updatedAllPaymentsData = [...allPaymentsData];
      const aPi = updatedAllPaymentsData.findIndex((apd) => apd.name.toLowerCase() === "cash");

      if (aPi === -1) {
        console.error("Cash payment data not found.");
        return;
      }

      let updatedAmount = 0;
      if (tillData?.tillAccess?.csBunit?.incDayOpening === "N") {
        updatedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut;
      } else {
        updatedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount) + parseFloat(totalOpeningAmount || 0) + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut;
      }

      updatedAllPaymentsData[aPi].expectedAmount = updatedAmount;
      updatedAllPaymentsData[aPi].amount = totalAmount || 0;
      updatedAllPaymentsData[aPi].actualAmount = totalAmount || 0;
      updatedAllPaymentsData[aPi].cashSaleAmount = parseFloat(cashSaleAmount?.toFixed(tillData.tillAccess.csBunit.currencies[0]?.prcPrecision || 2));
      updatedAllPaymentsData[aPi].difference = parseFloat(
        (totalAmount - parseFloat(updatedAmount - updatedAllPaymentsData[aPi].paymentReturn)).toFixed(tillData.tillAccess.csBunit.currencies[0]?.prcPrecision || 2)
      );
      updatedTillSession.cashInOutData = tillEvent[0]?.cashInOutData ? tillEvent[0].cashInOutData : obj;
      updatedTillSession.closingTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      updatedTillSession.closingCash = totalAmount || 0;
      updatedTillSession.closingCashDenominations = denominations || [];
      updatedTillSession.allPaymentsData = updatedAllPaymentsData;
      updatedTillSession.totalOrdersCount = totalOrdersCount || 0;

      await db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);

      localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));

      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;

      history.push("/close-till-all");
    } catch (error) {
      console.error("Error executing close till operation:", error);
      confirmBtnRef.current.disabled = false;
      cancelBtnRef.current.disabled = false;
    }
  };

  let reconstructedObject;

  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    const closeTillObject = rawDataArray.find((item) => item.key === "Close Till Denominations");

    const closeTillValue = closeTillObject?.value;

    if (closeTillValue !== undefined) {
      const cleanedData = closeTillValue.replace(/[{}"]/g, "");
      const keyValuePairs = cleanedData.split(",");

      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where closeTillValue is undefined
      console.error("closeTillValue is undefined");
    }
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      {loading ? (
        <div style={data.openTill.mainContainer}></div>
      ) : (
        <div style={data.openTill.mainContainer}>
          <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
            <Col span={1} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
              <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
            </Col>
            <Col span={23} style={{ fontSize: "1vw", paddingTop: "0.6vh" }}>
              <label
                style={{
                  fontSize: "1.8em",
                  color: "#0F0718",
                  letterSpacing: "0.36px",
                  opacity: 1,
                  fontWeight: "500",
                  textAlign: "center",
                  paddingLeft: "1vw",
                  marginTop: "0.5vh",
                }}
              >
                Close Till
              </label>
            </Col>
          </Row>

          <div style={data.openTill.mainCardContainer}>
            <div
              style={{
                padding: "1vw",
                display: "flex",
                paddingBottom: "0",
                fontSize: "1vw",
              }}
            >
              <div style={{ borderRadius: "3px", width: "68.5%" }}>
                <div key="z" style={data.openTill.mainCardFirst}>
                  <Row
                    style={{
                      padding: "1% 0%",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
                    }}
                  >
                    <Col span={7} style={data.openTill.colHead}>
                      {/* <strong>Denomination</strong> */}
                      <span>{getMultilingualText("Denomination")}</span>
                    </Col>
                    <Col span={10} style={data.openTill.colHead}>
                      {/* <strong>Count</strong> */}
                      <span>{getMultilingualText("Count")}</span>
                    </Col>
                    <Col span={7} style={data.openTill.colHead}>
                      {/* <strong>Total Amount</strong> */}
                      <span>{getMultilingualText("Total Amount")}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Scrollbars className="closeTillScroll">
                      {denominations.map((denom, index) => (
                        <Row>
                          <Col span={7} style={data.openTill.colAlign}>
                            <Input tabIndex={-1} readOnly={true} style={data.openTill.currency} value={denom.value} />
                          </Col>
                          <Col span={10} style={data.openTill.colAlign}>
                            <div style={data.openTill.calInputDiv}>
                              <MinusOutlined style={data.openTill.incInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                              <Form.Item style={data.openTill.countFormitem}>
                                <Input name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                              </Form.Item>
                              <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                            </div>
                          </Col>
                          <Col span={7} style={data.openTill.colAlign}>
                            <Input
                              tabIndex={-1}
                              style={data.openTill.totalInput}
                              readOnly={true}
                              value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${
                                currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                              }`}
                            />
                          </Col>
                        </Row>
                      ))}
                      <span tabIndex={0}></span>
                    </Scrollbars>
                  </Row>
                </div>
              </div>
              <div
                style={{
                  width: "32.5%",
                  paddingLeft: "2.3%",
                  position: "relative",
                }}
              >
                <div style={{ ...data.openTill.opentillRightCard }}>
                  <p style={data.openTill.emptyP} />
                  <p style={data.openTill.emptyP}>{getMultilingualText("Opened On")}</p>
                  <div
                    style={{
                      marginBottom: "0.5vh",
                      marginTop: "-6px",
                      marginTop: "1vh",
                    }}
                  >
                    <span style={data.openTill.currentDate}>{currentDate}</span>
                    <span style={data.openTill.displayClock}>{displayClock}</span>
                  </div>
                  <Form style={{ fontSize: "1vw" }}>
                    <p
                      style={{
                        marginBottom: "1vh",
                        fontSize: "1.2em",
                        fontFamily: "Inter",
                        fontWeight: "500",
                        letterSpacing: "0px",
                        color: "#0F0718",
                        marginTop: "1vh",
                      }}
                    >
                      {getMultilingualText("Transactions")}
                    </p>
                    <Scrollbars className="closeTillScroll2">
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Opening Amount")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalOpeningAmount).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          // className="transactionAmtInputClose"
                          className="transactionAmtInput"
                          style={{ height: "5.5vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          fontSize: "1vw",
                          display: reconstructedObject ? (reconstructedObject.SalesAmount === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Cash Sale Amount")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashSaleAmount).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh", marginTop: "1vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          fontSize: "1vw",
                          display: reconstructedObject ? (reconstructedObject.SalesReturnAmount === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Cash Sale Return Amount")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(Math.abs(cashReturnAmount)).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh", marginTop: "1vh" }}
                        />
                      </Form.Item>
                      {/* my changes */}
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Cash In")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.cashIn).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Cash Out")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.cashOut).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Petty Cash In")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.pettCashIn).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Petty Cash Out")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashValues.pettCashOut).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          marginBottom: "0.8vh",
                          fontSize: "1vw",
                          display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block",
                        }}
                      >
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Expected Cash")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(cashExpectedAmount).toFixed(
                            currencyType.stdPrecision
                          )} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh", marginTop: "1vh" }}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: "0.8vh", fontSize: "1vw" }}>
                        <p style={data.openTill.transactionsAmtClose}>{getMultilingualText("Actual Cash")}</p>
                        <Input
                          value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${
                            currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                          }`}
                          readOnly={true}
                          className="transactionAmtInput"
                          style={{ height: "5.5vh", marginTop: "1vh" }}
                        />
                      </Form.Item>
                    </Scrollbars>
                  </Form>
                  <Row
                    style={{
                      fontSize: "1vw",
                      position: "absolute",
                      bottom: 0,
                      width: "94%",
                    }}
                  >
                    <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                      <Link to={"/pos"}>{getMultilingualText("Back")}</Link>
                    </Button>
                    <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteCloseTill}>
                      {getMultilingualText("Next")}
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};

export default CloseTill;
