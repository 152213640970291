import React, { useState, useEffect, useRef } from "react";
import { message, Modal, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { clock, timeStamp, timeValidator, currentDay, dateValidator } from "../../../utility/clock";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import useDebounce from "../../../lib/hooks/useDebounce";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import db from "../../../database";
import Axios from "axios";
import { debounce } from "lodash";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import moment from "moment";
import { SyncData } from "../Restaurant/Tables-Management/SyncData";
import { useEventBus } from "../../../lib/hooks/EventBusProvider";

// OMS Orders Modal Imports //
import NewWhite from "../../../assets/images/pending.svg";
import PreparingWhite from "../../../assets/images/parkedOrder.svg";
import ReadyWhite from "../../../assets/images/completed.svg";
import CompletedWhite from "../../../assets/images/prepared.svg";
import New from "../../../assets/images/new.svg";
import Preparing from "../../../assets/images/preparing.svg";
import Ready from "../../../assets/images/ready.svg";
import Completed from "../../../assets/images/todayOrders.svg";
import _ from "lodash";
import CoreModals from "./coreModals";
import ReturnBill from "./returnBill";
import { CheckoutTotalManualDiscount } from "./PricingRules/CheckoutTotalManualDiscount";
import { CheckoutFlatDiscount } from "./PricingRules/CheckoutFlatDiscount";
import { CheckoutPercentageDiscount } from "./PricingRules/CheckoutPercentageDiscount";
import { createCustomer, getCustomer, updateCustomer } from "./customer";
import { pricingRuleController } from "./PricingRules/pricingRuleController";
import { barcodeScaner } from "./scaner";
import { TotalBillDiscount } from "./PricingRules/totalBillDiscount";
import { TotalBillFreeProductDiscount } from "./PricingRules/totalBillFreeProductDiscount";
import { paymentProcess } from "./payment/paymentProcess";
import { addProductToCart } from "./cart";
import { useTranslation } from "react-i18next";
import { addAmount, completePayment } from "./payment/paymentController";
import { stock } from "./stock";
import PoleDisplay from "../../../lib/printer/poleDisplay";
import PrintController from "../../../lib/printer/printController";
import upsertPOSLog from "../Retail/posLog";
import PointOfSuperMarketModals from "../Retail/SuperMarket/SuperMarketModal";
import NotFoundImg from "../../../assets/images/NotFoundImg.svg";
import CWPoleDisplay from "../../../lib/printer/CWPoleDisplay";

// PointOfsaleCore Component Start
const PointOfsaleCore = (props) => {
  const { t } = useTranslation();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const edcUrl = process.env.REACT_APP_edcUrl;
  const RenderComponent = props.component;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const precision = tillData?.tillAccess?.csBunit?.currencies[0]?.prcPrecision || 2;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const CWHardwareController = tillData.tillAccess.cwrTill.printTemplate.cwController === "Y" ? true : false;
  const tillDataPaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
  const [saleTypeProducts, setSaleTypeProducts] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const authHeaders = getOAuthHeaders();
  const history = useHistory();
  const qs = require("querystring");
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  const { dispatchEvent, discountApproval } = useEventBus();
  // let setAuthTokens;
  const paymentModalInputRef = useRef();
  const customerSearchInputRef = useRef();
  const quantityInputRef = useRef();
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(async () => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // KeyBord changes start
  const [orderHistoryInput, setOrderHistoryInput] = useState("");
  const [keyboardType, setKeyboardType] = useState({
    product: false,
    parkedBill: false,
    salesHistoryDoc: false,
    salesHistoryCus: false,
    searchCus: false,
    productSearch: false,
    giftCardSearch: false,
    addCustomer: false,
  });
  const [layout, setLayout] = useState("default");
  const [loading, setLoading] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputFocused, setInputFocused] = useState(null);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filtersFlag, setFiltersFlag] = useState(false);
  const [filteredDate, setFilterdDate] = useState(null);
  const [searchHistoryInput, setSearchhistoryInput] = useState("");
  const keyboardProduct = useRef(null);
  const keyboardParkbill = useRef(null);
  const keyboardRef = useRef(null);
  const orderHistorySearchInputRef = useRef(null);
  const [notesValue, setNotesValue] = useState("");
  const [selectedProductForNotes, setSelectedProductForNotes] = useState(null);
  const [open, setOpen] = useState(false);
  const productSearchInputRef = useRef(null);
  const giftCardRef = useRef(null);
  const salesRepRef = useRef(null);
  const [layoutName, setLayoutName] = useState("default");
  const [searchTerm, setSearchTerm] = useState("");
  const [salesRepValue, setSalesRepValue] = useState("");
  const [productStock, setProductStock] = useState(false);
  const [currenciesData, setCurrenciesData] = useState(tillData?.tillAccess?.csBunit?.currencies[0]?.denominations?.sort((a, b) => b.value - a.value) || []);
  const [selectedDocumentType, setSelectedDocumentType] = useState("Invoice");
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [currentInput, setCurrentInput] = useState(null);
  const [tableCards, setTableCards] = useState([]);
  const [inputValues, setInputValues] = useState({
    name: "",
    lastName: "",
    email: "",
    mobile: "",
    pincode: "",
    street: "",
    city: "",
    pincode1: "",
    taxID: "",
    cardNumber: "",
  });
  const [keyboardInputFields, setKeyboardInputFields] = useState({});

  const refs = {
    name: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    mobile: useRef(null),
    pincode: useRef(null),
    street: useRef(null),
    city: useRef(null),
    pincode1: useRef(null),
    taxID: useRef(null),
    cardNumber: useRef(null),
  };

  const keyboard = useRef(null);
  const [keyValue, setKeyValue] = useState("0");
  const [isCardPaymentLoading, setIsCardPaymentLoading] = useState(false);
  const [cardPaymnetError, setCardPaymnetError] = useState(false);
  const [cardPaymnetStatus, setCardPaymnetStatus] = useState({
    paymentStatus: false,
    message: "Payment processing failed. Please try again. If the problem persists, contact support.",
  });

  const tillId = tillData.tillAccess?.cwrTill?.cwrTillID;

  const documentTypes = [
    { type: "Invoice", isEnabled: true, isVisible: true },
    {
      type: "Advance",
      isVisible: posConfig?.requireCustomerforLayaway === "Y",
      isEnabled: JSON.parse(localStorage.getItem("saleTypeData"))?.cwrSaletype?.enableLayaway === "Y",
    },
    { type: "Quotation", isEnabled: posConfig.enableQuotation === "Y", isVisible: posConfig.enableQuotation === "Y" },
  ];

  // useEffect(() => {
  //   const activeKeyboard = Object.keys(keyboardType).find(key => keyboardType[key]);
  //   if (activeKeyboard) {
  //     console.log(`Active keyboard: ${activeKeyboard}`);
  //   }
  // }, [keyboardType]);

  useEffect(() => {
    if (productStock) {
      setProductStock(true);

      // Hide the modal after 1.5 seconds
      const timer = setTimeout(() => {
        setProductStock(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [productStock]);

  const handleKeyPress = (button) => {
    if (button === "{shift}" || button === "{caps}") setLayout("shift");
    if (button === "{default}" || button === "{small}") setLayout("default");
    if (button === "{numbers}") setLayout("numbers");
    if (button === "{number}") setLayout("number");
    if (button === "{done}") {
      switch (true) {
        case keyboardType.parkedBill:
          searchParkedBill();
          break;
        case keyboardType.salesHistoryDoc:
          searchOrderHistory();
        case keyboardType.salesHistoryCus:
          searchOrderHistory();
          break;
        case keyboardType.product:
          // getSearchedProducts();
          break;
        // Add more cases as needed
        default:
          // Handle the default case or do nothing
          break;
      }
    }
  };

  const handleKeyboardInput = (inputs) => {
    if (keyboardType.parkedBill === true) {
      setProductSearchInput("");
      setParkedBillSearchInput(inputs.default);
    } else if (keyboardType.product === true) {
      if (inputs.default === "") {
        clearProductSearchResults();
      } else {
        setProductSearchInput(inputs.default);
      }
    } else if (keyboardType.salesHistoryDoc === true) {
      setOrderHistoryInput(inputs.default);
    } else if (keyboardType.salesHistoryCus === true) {
      setOrderHistoryInput(inputs.default);
    }
  };

  // KeyBord changes End

  // ORDER TYPE BLOCK START
  const [orderType, setOrderType] = useState();
  const [posSaleTypes, setPosSaleTypes] = useState([]);
  const [displaySetOrderType, setDisplayOrderType] = useState(false);
  const changeOrderType = (type) => {
    setDisplayOrderType(false);
    setOrderType(type);
  };
  useEffect(() => {
    const totalRecords = 12;
    let filledCurrencies = currenciesData;
    while (filledCurrencies.length < totalRecords) {
      filledCurrencies.push({ id: `placeholder-${filledCurrencies.length}`, value: 999999 });
    }
    setCurrenciesData(filledCurrencies?.sort((a, b) => b.value - a.value));
    db.posSaletypes.toArray().then((saleType) => {
      setPosSaleTypes([...saleType]);
      const saleIndex = saleType.findIndex((st) => st.cwrSaletype.isdefault === "Y");
      setOrderType(saleType[saleIndex]);
    });
  }, []);
  // ORDER TYPE BLOCK END

  // Cash Management Start
  const [cashAddInFlag, setCashAddInFlag] = useState(false);
  const [cashManagementForm] = Form.useForm();
  const [cashIn, setCashOut] = useState(true);
  const [pettCashIn, setPettCashIn] = useState(false);
  const [addCashFlag, setAddCashFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [giftCardFlag, setGiftCardFlag] = useState(false);
  const [isGiftCardFlag, setIsGiftCardFlag] = useState(false);
  const [isCardPaymentFlag, setIsCardPaymentFlag] = useState(false);
  const [CardPaymentForm] = Form.useForm();
  const [showSaleType, setShowSaleType] = useState(() => (posConfig?.enableSaleType === "Y" ? true : false));
  const [isSaleTypeFlag, setIsSaleTypeFlag] = useState(false);
  const [selectedSaleType, setSelectedSaleType] = useState(() => {
    if (localStorage.getItem("saleTypeData") !== null) {
      const saleTypeData = JSON.parse(localStorage.getItem("saleTypeData"));
      return saleTypeData?.cwrSaletype?.name;
    } else {
      return "";
    }
  });
  const [selectedSaleTypeData, setSelectedSaleTypeData] = useState(() => {
    if (localStorage.getItem("saleTypeData") !== null) {
      const saleTypeData = JSON.parse(localStorage.getItem("saleTypeData"));
      return saleTypeData;
    } else {
      return "";
    }
  });

  const handleCahInOut = async (data) => {
    let formData = cashManagementForm.getFieldsValue(true);
    formData.key = uuidv4().replace(/-/g, "").toUpperCase();
    formData.id = uuidv4().replace(/-/g, "").toUpperCase();
    formData.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (cashAddInFlag === true) {
      if (formData.type === "cashOut" || !formData.type) {
        formData.type = "cashOut";
      } else {
        formData.type = "pettyCashOut";
      }
    } else {
      if (formData.type === "cashIn" || !formData.type) {
        formData.type = "cashIn";
      } else {
        formData.type = "pettyCashIn";
      }
    }
    // formData.notes = data.note
    if (formData.type === "cashIn") {
      upsertPOSLog(formData, "CAI");
    } else if (formData.type === "cashOut") {
      upsertPOSLog(formData, "CAO");
    }
    setPettCashIn(false);
    setCashOut(true);
    db.cashInCashOut.add(formData);
    cashManagementForm.resetFields();
    setAddCashFlag(false);

    let obj = { cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 };
    let cashDetails = await db.cashInCashOut.toArray();

    cashDetails.map((item) => {
      if (item.type === "cashIn") {
        obj.cashIn += parseFloat(item.amount);
      } else if (item.type === "cashOut") {
        obj.cashOut += parseFloat(item.amount);
      }
      if (item.type === "pettyCashIn") {
        obj.pettCashIn += parseFloat(item.amount);
      }
      if (item.type === "pettyCashOut") {
        obj.pettCashOut += parseFloat(item.amount);
      }
    });
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    db.tillEvents
      .where("tillSessionId")
      .equals(tillSession.tillSessionId)
      .modify((tillEvent) => {
        tillEvent.cashInOutData = obj;
      });
  };

  const onChangeCheckbox = (e) => {
    const eventId = e.target.id;
    const checkedValue = e.target.checked;
    let formData = cashManagementForm.getFieldsValue(true);
    if (cashAddInFlag) {
      if (eventId === "cashOut") {
        if (checkedValue === true) {
          setCashOut(true);
          setPettCashIn(false);
          formData.type = "cashOut";
        }
      }
      if (eventId === "pettyCashOut") {
        if (checkedValue === true) {
          formData.type = "pettyCashOut";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    } else {
      if (eventId === "cashIn") {
        if (checkedValue === true) {
          formData.type = "cashIn";
          setCashOut(true);
          setPettCashIn(false);
        }
      }
      if (eventId === "pettyCashIn") {
        if (checkedValue === true) {
          formData.type = "pettyCashIn";
          setCashOut(false);
          setPettCashIn(true);
        }
      }
    }
    cashManagementForm.setFieldsValue(formData);
  };

  // Cash Management End
  const [giftCardForm] = Form.useForm();
  const [giftCardItems, setGiftCardItems] = useState([]);
  const [giftCardType, setGiftCardType] = useState("giftCard");
  const [selectGiftCardItem, setSelectGiftCardItem] = useState({});
  const [validateGiftCard, setValidateGiftCard] = useState(false);
  const [giftCardData, setGiftCardData] = useState([]);
  const [giftCardBalance, setGiftCardBalance] = useState(0);
  const [validateGiftCardForm] = Form.useForm();

  const handleGiftCardDetails = async (data) => {
    try {
      const setAuthTokens = authHeaders?.access_token || "";
      const returnFlag = cart.isReturn === true || cart.isReturn === "Y" ? data.amount <= Math.abs(cart.total) : true;

      if (!returnFlag || data.amount <= 0) {
        message.error(`The ${giftCardType} amount cannot exceed the return amount. Please enter a valid amount.`);
        return;
      }

      const matchedGiftCardData = await findMatchedGiftCardData();
      if (!matchedGiftCardData) return;

      const refId = generateRefId();
      const giftCardResponse = await verifyGiftCard(data, matchedGiftCardData, refId, setAuthTokens);

      if (giftCardResponse?.status === "200") {
        processVerifiedGiftCard(data, matchedGiftCardData, refId, giftCardResponse);
      } else {
        handleGiftCardError(data, refId, matchedGiftCardData, giftCardResponse);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // Helper Functions

  const findMatchedGiftCardData = async () => {
    const giftCardData = await db.giftCardData.toArray();
    return giftCardData.find((giftcard) => giftcard.mProductId === selectGiftCardItem.mProductId);
  };

  const generateRefId = () => uuidv4().replace(/-/g, "").toUpperCase();

  const verifyGiftCard = async (data, matchedGiftCardData, refId, setAuthTokens) => {
    const query = `
      mutation {
        verifyGiftCard(giftCards: [{
          cwrGiftcardTypeId: "${matchedGiftCardData?.cwrGiftcardTypeId}"
          cardNo: ${data.number ? `"${data.number}"` : null}
          referenceNo: "${refId}"
          b2cCustomerId: "${cart.customer?.cwrCustomerId || null}"
          cardPin: ${data.cardPin ? `"${data.cardPin}"` : null}
          currentBalance: ${parseFloat(data.amount)}
        }]) {
          status
          message
          cardNo
          expiryDate
        }
      }`;

    const response = await Axios.post(
      serverUrl,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${setAuthTokens}`,
        },
      }
    );

    return response.data.data.verifyGiftCard;
  };

  const processVerifiedGiftCard = (data, matchedGiftCardData, refId, giftCardResponse) => {
    setValidateGiftCard(false);
    setGiftCardBalance("0.00");

    const futureDate = moment().add(matchedGiftCardData.validity, "days");
    const updatedItem = {
      ...selectGiftCardItem,
      isGiftCard: true,
      realPrice: parseFloat(data.amount),
      total: parseFloat(data.amount),
      nettotal: parseFloat(data.amount),
      salePrice: parseFloat(data.amount),
      discAmount: 0,
      taxRate: 0,
      taxAmount: 0,
      unitPrice: parseFloat(data.amount),
      giftCardType: matchedGiftCardData.type,
      cardNo: matchedGiftCardData.type === "PHY" ? data.number : giftCardResponse.cardNo,
      expiryGiftCard: futureDate.format("DD-MM-YYYY"),
    };

    const cardDetails = [
      ...giftCardData,
      {
        number: updatedItem.cardNo,
        amount: parseFloat(data.amount),
        refId,
      },
    ];

    setCart({ ...cart, giftCardData: cardDetails });
    setGiftCardData(cardDetails);
    upsertPOSLog(cart, "GCI");
    addProduct(updatedItem, 1);
  };

  const handleGiftCardError = (data, refId, matchedGiftCardData, response) => {
    Sentry.captureException(new Error("Verify gift card failed"), {
      extra: JSON.stringify({
        cwrGiftcardTypeId: matchedGiftCardData?.cwrGiftcardTypeId || null,
        cardNo: data.number ? `"${data.number}"` : null,
        referenceNo: refId,
        b2cCustomerId: cart.customer?.cwrCustomerId || null,
        cardPin: data.cardPin ? `"${data.cardPin}"` : null,
        currentBalance: data.amount || null,
        response,
      }),
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const redeemGiftCard = async (data) => {
    setIsSubmitting(true);
    const uniqId = uuidv4().replace(/-/g, "").toUpperCase();
    let giftCardAmount = 0;
    const setAuthTokens = authHeaders?.access_token ?? "";

    try {
      const giftCardResponse = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `
            query {
              checkGiftCardBalance(cardNo: "${data.number}", cardPin: ${data.pin ? `"${data.pin}"` : null}) {
                currentBalance
                status
                message
              }
            }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const balanceData = giftCardResponse?.data?.data?.checkGiftCardBalance?.[0];
      if (balanceData?.status === "200") {
        giftCardAmount = balanceData.currentBalance ?? 0;
      }
    } catch (err) {
      message.error(err);
      setIsSubmitting(false);
      return;
    }

    if (cart?.total - cart?.paid <= data?.amount && data?.amount > 0) {
      try {
        const upsertGiftCard = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `
              mutation {
                upsertGiftCardTransaction(giftCardTransactions: [{
                  cardNo: "${data.number}"
                  type: "RD"
                  referenceNo: "${uniqId}"
                  amount: ${parseFloat(data.amount).toFixed(2)}
                  cardPin: ${data.pin ? `"${data.pin}"` : null}
                }]) {
                  status
                  message
                }
              }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });

        const transactionData = upsertGiftCard?.data?.data?.upsertGiftCardTransaction;
        if (transactionData?.status === "200") {
          setGiftCardFlag(false);
          giftCardForm.resetFields();
          setGiftCardBalance(0);

          const cardDetails = [...(giftCardData ?? [])];
          const giftCardIndex = cardDetails.findIndex((item) => item.number === data.number);

          if (giftCardIndex >= 0) {
            cardDetails[giftCardIndex] = {
              ...cardDetails[giftCardIndex],
              number: data.number,
              amount: Math.min(data.amount, giftCardAmount + (cardDetails[giftCardIndex]?.amount ?? 0)),
              redemptionId: uniqId,
              pin: data.pin ?? null,
            };
          } else {
            cardDetails.push({
              number: data.number,
              amount: Math.min(data.amount, giftCardAmount),
              redemptionId: uniqId,
              pin: data.pin ?? null,
            });
          }

          setCart({ ...cart, giftCardData: cardDetails });
          setGiftCardData(cardDetails);
          requestPayment(selectedPaymentMethod, Math.min(data.amount, giftCardAmount));
          upsertPOSLog(cart, "GCR");
          setIsSubmitting(false);
        } else {
          setIsSubmitting(false);
          message.error(transactionData?.message ?? "Transaction failed.");
          Sentry.captureException(new Error("Gift card transaction failed"), {
            extra: {
              cardNo: data.number,
              type: "RD",
              referenceNo: uniqId,
              amount: data.amount,
              cardPin: data.pin ?? null,
              response: transactionData,
            },
          });
        }
      } catch (err) {
        setIsSubmitting(false);
        Sentry.captureException(err);
        message.error(err);
      }
    } else if (cart?.total - cart?.paid > data?.amount) {
      message.warn("Overpayment not allowed with non-cash methods. Please adjust the amounts!");
      setIsSubmitting(false);
    } else {
      message.error("The redeem amount exceeds your gift card balance. Please enter an amount up to the available balance.");
      setIsSubmitting(false);
    }
  };

  const handleGiftCard = async (data) => {
    try {
      const formData = giftCardForm.getFieldsValue(true);
      const setAuthTokens = authHeaders?.access_token || "";

      if (!formData.number) {
        message.error("Please enter the Gift Card Number.");
        return;
      }

      const giftCardResponse = await checkGiftCardBalance(formData, setAuthTokens);

      if (giftCardResponse?.status === "200") {
        setGiftCardBalance(giftCardResponse.currentBalance);
      } else {
        message.error(giftCardResponse?.message || "Failed to fetch gift card balance.");
      }
    } catch (error) {
      message.error(error.message || "An error occurred while processing the gift card.");
    }
  };

  // Helper Function
  const checkGiftCardBalance = async (formData, setAuthTokens) => {
    const query = `
      query {
        checkGiftCardBalance(
          cardNo: "${formData.number}",
          cardPin: ${formData.pin ? `"${formData.pin}"` : null}
        ) {
          currentBalance
          status
          message
        }
      }`;

    const response = await Axios.post(
      serverUrl,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${setAuthTokens}`,
        },
      }
    );

    return response.data.data.checkGiftCardBalance[0];
  };

  // CUSTOMER  SEARCH AND SELECTION BLOCK START
  const [displayCustomerSearch, setDisplayCustomerSearch] = useState(false);
  const [displayUAECustomerSearch, setDisplayUAECustomerSearch] = useState(false);
  const [displayUAECustomer, setDisplayUAECustomer] = useState(false);
  const [customerSearchType, setCustomerSearchType] = useState(() => (posConfig?.defaultCustomerSearch === "Search Key" ? "searchKey" : "mobile"));
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [customerSearchResults, setCustomerSearchResults] = useState();
  const [closeCustomerFlag, setCloseCustomerFlag] = useState(false);
  const [properties, setProperties] = useState("");
  const [kioskUI, setKioskUI] = useState(parseFloat(localStorage.getItem("kioskUI")) ? parseFloat(localStorage.getItem("kioskUI")) : 0);
  const [kioskLogin] = Form.useForm();
  const [layoutType, setLayoutType] = useState(parseFloat(localStorage.getItem("layoutType")) ? parseFloat(localStorage.getItem("layoutType")) : 0);
  const [addToBagProducts, setAddToBagProducts] = useState([]);
  const [addToBagFlag, setAddToBagFlag] = useState(false);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      const dynamoDBValue = event.detail.newValue;
      if (event.detail.key === "kioskUI") {
        setKioskUI(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("kioskUI")) || 0);
      } else if (event.detail.key === "layoutType") {
        setLayoutType(dynamoDBValue ? dynamoDBValue : parseFloat(localStorage.getItem("layoutType")) || 0);
      }
    };

    db.products.toArray().then((productsFetched) => {
      let items = [];
      let bagItems = [];
      productsFetched.map(async (item) => {
        if (item.productSegment === "GC") {
          const giftCardData = await db.giftCardData.toArray();
          const matchingGiftCard = giftCardData.filter((giftcard) => giftcard.mProductId === item.mProductId);
          item.matchingGiftCard = matchingGiftCard;
          items.push(item);
        } else if (item.productSegment === "BG") {
          bagItems.push(item);
        }
      });
      setAddToBagProducts(bagItems);
      setGiftCardItems(items);
    });
    window.addEventListener("customStorageChange", handleCustomEvent);

    return () => {
      window.removeEventListener("customStorageChange", handleCustomEvent);
    };
  }, []);

  const debouncedCustomerSearch = useDebounce(customerSearchInput, 350);
  useEffect(() => {
    if (debouncedCustomerSearch !== "") {
      handleCustomerSearch(debouncedCustomerSearch);
    } else {
      setCustomerSearchResults([]);
    }
  }, [debouncedCustomerSearch]);

  const closeCustomerSearch = (obj) => {
    setDisplayCustomerSearch(false);
    setDisplayUAECustomerSearch(false);
    setCustomerSearchInput("");
    setCustomerSearchResults();
    setIsInputFocused(false);
    const productSearchInput = document.getElementById("sm-product-search");
    if (productSearchInput) {
      productSearchInput.focus();
    }
  };

  const handleCustomerSearch = async () => {
    getCustomer(form, tillLayout, customerSearchInput, setKioskUI, setCustomerSearchResults, kioskLogin);
  };

  const [orderTimeDetails, setOrderTimeDetails] = useState({ orderStartTime: "", orderEndTime: "", paymentStartTime: "" });

  const selectCustomer = async (index) => {
    setCustomerFlag(false);
    let latestData = removeAllDiscounts(false, true);

    const truncateDecimal = (num, decimalPlaces) => {
      // Convert the number to a string
      let numStr = num.toString();

      // Find the position of the decimal point
      let decimalIndex = numStr.indexOf(".");

      // If there is no decimal point or if there are less decimal places than desired, return the number as is
      if (decimalIndex === -1 || numStr.length - decimalIndex - 1 <= decimalPlaces) {
        return num;
      }

      // Truncate the string to the desired number of decimal places
      numStr = numStr.slice(0, decimalIndex + decimalPlaces + 1);

      // Parse the truncated string back to a number
      return parseFloat(numStr);
    };

    let num = customerSearchResults[index]?.retlLoyaltyBalance || 0;
    let truncatedNum = truncateDecimal(num, 2);

    customerSearchResults[index].retlLoyaltyBalance = truncatedNum;
    const cartObj = {
      ...latestData,
      customer: customerSearchResults[index],
    };
    let updatedCart = cartObj;

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          let matchedPriceIndex = [];
          if (defaultCustomer?.cwrCustomerId !== updatedCart?.customer?.cwrCustomerId && addToCart?.priceList?.length > 0) {
            let taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
            matchedPriceIndex = addToCart?.priceList.filter((i) => i.sPricelistId === updatedCart.customer.sPriceListId);
            if (matchedPriceIndex.length > 0) {
              addToCart.price = matchedPriceIndex[0].pricestd;
              addToCart.salePrice = matchedPriceIndex[0].pricestd;
              addToCart.realPrice = matchedPriceIndex[0].pricestd;
              addToCart.sunitprice = matchedPriceIndex[0].pricestd;
            } else if (!updatedCart.customer.sPriceListId) {
              addToCart.price = addToCart?.originalPrice;
              addToCart.salePrice = addToCart?.originalPrice;
              addToCart.realPrice = addToCart?.originalPrice;
              addToCart.sunitprice = addToCart?.originalPrice;
            }
            const addOnsPriceSum = _.sumBy(addToCart?.selectedAddons, "price");
            const mrp = (parseFloat(addToCart.salePrice) + addOnsPriceSum * addToCart.weight) * parseFloat(addToCart.weight);
            const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addToCart.taxRate / 100) : mrp * (addToCart.taxRate / 100);
            addToCart.taxAmount = tax;
            addToCart.nettotal =
              taxIncludeFlag === "Y"
                ? parseFloat((mrp - parseFloat(addToCart.discount ? addToCart.discount : 0)).toFixed(precision))
                : parseFloat((mrp - parseFloat(addToCart.discount ? addToCart.discount : 0)).toFixed(precision)) + tax;
          }

          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    } else {
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        orderStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
      };
      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }
    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }
    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    if (updatedCart?.manualDiscountData?.lineLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.lineLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        let productSelected = updatedCart.items.filter((cartItem) => cartItem.productId === item.productId);
        if (pricingRule?.type === "FD") {
          try {
            const newCart = await CheckoutFlatDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying flat discount:", err);
          }
        } else if (pricingRule?.type === "PD") {
          try {
            const newCart = await CheckoutPercentageDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying percentage discount:", err);
          }
        }
      });
    }

    if (updatedCart?.manualDiscountData?.totalLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.totalLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        if (pricingRule?.type === "TD") {
          try {
            const newCart = await CheckoutTotalManualDiscount(pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying Total discount:", err);
          }
        }
      });
    }

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice = parseFloat((updatedTotalPrice + nettotalFixed).toFixed(precision));
      updatedTotalItemsQty = parseFloat((updatedTotalItemsQty + item.weight).toFixed(precision));
      updatedTotalTax = parseFloat((updatedTotalTax + taxAmountFixed).toFixed(precision));
      updatedTotalDiscounts = parseFloat((updatedTotalDiscounts + discountFixed).toFixed(precision));

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let lineTax = item.listPrice * (1 + item.taxRate / 100);
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        let unitTax = item.taxAmount / item.weight;
        item.sunitprice = taxIncludeFlag === "Y" ? item.sunitprice : parseFloat((item.sunitprice + unitTax).toFixed(precision));
        const gross_list = taxIncludeFlag === "Y" ? item.listPrice : item.listPrice * (1 + item.taxRate / 100);
        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;
        item.listPrice = taxIncludeFlag === "Y" ? item.listPrice : parseFloat(lineTax.toFixed(precision));
        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? gross_list : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);
    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      total: updatedTotalPrice,
      tax: updatedTotalTax,
      customer: customerSearchResults[index],
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
    closeCustomerSearch(finalCartObj, true);
    setShowPaymentMethods(false);
    setIsInputFocused(false);
    upsertPOSLog(finalCartObj, "ACT");
    // setOpen(false);
  };
  // CUSTOMER  SEARCH AND SELECTION BLOCK END

  // ADD NEW CUSTOMER BLOCK START
  const [form] = Form.useForm();
  const [UAECustomerForm] = Form.useForm();
  const [displayAddNewCustomer, setDisplayAddNewCustomer] = useState(false);

  const showAddNewCustomerFields = async () => {
    setDisplayCustomerSearch(false);

    const searchResult = customerSearchInput || keyboardInputFields?.search;
    let customerSearchType = "name";

    if (/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(searchResult)) {
      customerSearchType = "email";
    } else if (/^\d+$/.test(searchResult)) {
      customerSearchType = "mobile";
    }

    const defaultAddress = {
      country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
      state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
    };

    const fieldValues = {
      mobile: customerSearchType === "mobile" ? searchResult : "",
      name: customerSearchType === "name" ? searchResult : "",
      email: customerSearchType === "email" ? searchResult : "",
      type: "addCustomerForm",
      ...defaultAddress,
    };

    setKeyboardInputFields(fieldValues);
    form.setFieldsValue(fieldValues);

    const loyaltyData = await db.loyalityData.toArray();
    const defaultLoyalty = loyaltyData.find((item) => item.isDefault === "Y");

    if (defaultLoyalty) {
      form.setFieldsValue({ program: defaultLoyalty.loyaltylevelId });
    }

    setDisplayAddNewCustomer(true);
  };

  const showAddNewUAECustomerFields = async () => {
    setDisplayUAECustomerSearch(false);

    const customerSearchType = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput)
      ? "email"
      : /^\d+$/.test(customerSearchInput)
      ? "mobile"
      : "name";

    const defaultAddress = {
      country: tillData.tillAccess.csBunit.customerAddress.csCountry.name,
      state: tillData.tillAccess.csBunit.customerAddress.csRegion.name,
    };

    const fieldValues = {
      mobile: customerSearchType === "mobile" ? customerSearchInput : "",
      name: customerSearchType === "name" ? customerSearchInput : "",
      email: customerSearchType === "email" ? customerSearchInput : "",
      type: "UAECustomerForm",
      ...defaultAddress,
    };
    setKeyboardInputFields(fieldValues);
    UAECustomerForm.setFieldsValue(fieldValues);

    const loyaltyData = await db.loyalityData.toArray();
    const defaultLoyalty = loyaltyData.find((item) => item.isDefault === "Y");

    if (defaultLoyalty) {
      form.setFieldsValue({ program: defaultLoyalty.loyaltylevelId });
    }

    setDisplayUAECustomer(true);
  };

  const addNewCustomer = async (data) => {
    createCustomer(data, cart, setCart, form, tillLayout, closeCustomerSearch, tillData, posLogActivity, setKioskUI, setDisplayAddNewCustomer, UAECustomerForm);
  };
  // ADD NEW CUSTOMER BLOCK END

  // EDIT CUSTOMER BLOCK END
  const [displayEditOldCustomer, setDisplayEditOldCustomer] = useState(false);
  const [selectedEditOldCustomer, setSelectedEditOldCustomer] = useState();

  const showEditOldCustomerFields = async (customer) => {
    setDisplayCustomerSearch(false);
    setSelectedEditOldCustomer(customer);
    if (!customer) {
      console.error("Customer data not available");
      return;
    }

    const fieldValues = {
      editName: customer.name || "",
      editMobile: customer.mobileNo || "",
      editEmail: customer.email || "",
      editPincode: customer.pincode || "",
      name: customer.name || "",
      mobile: customer.mobileNo || "",
      birthday: customer.birthday ? moment(customer.birthday) : null,
      anniversaryDate: customer.anniversaryDate ? moment(customer.anniversaryDate) : null,
      city: customer.customerAddress?.line2 || "",
      street: customer.customerAddress?.line1 || "",
      gender: customer.gender || "",
      lastName: customer.lastName || "",
      taxID: customer.taxId || "",
      pincode: customer.pincode || "",
      country: customer.customerAddress?.country || tillData.tillAccess.csBunit.customerAddress?.csCountry?.name || "",
      state: customer.customerAddress?.region || tillData.tillAccess.csBunit.customerAddress?.csRegion?.name || "",
      type: "editCustomerForm",
    };

    form.setFieldsValue(fieldValues);
    setKeyboardInputFields(fieldValues);

    try {
      let loyaltyData = await db.loyalityData.toArray();
      loyaltyData.forEach((item) => {
        if (item.isDefault === "Y") {
          form.setFieldsValue({
            program: item.loyaltylevelId || "", // Handle missing loyalty level
          });
        }
      });
    } catch (error) {
      console.error("Error fetching loyalty data:", error);
    }

    setDisplayEditOldCustomer(true);
  };

  const editOldCustomer = async (data) => {
    updateCustomer(
      data,
      cart,
      setCart,
      form,
      closeCustomerSearch,
      selectedEditOldCustomer,
      setSelectedEditOldCustomer,
      setDisplayEditOldCustomer,
      tillData,
      cart?.customer,
      UAECustomerForm
    );
  };
  // EDIT CUSTOMER BLOCK END

  //// CENTER BUTTON BLOCK START /////

  const [isQtyUpdate, setIsQtyUpdate] = useState(false);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedProductQty, setSelectProductQty] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const selectProductInCart = (record, e) => {
    if (record.productId === selectedProductInCart.productId && e !== "1") {
      // setSelectedRowKeys([]);
      // setSelectedProductInCart({});
    } else {
      record.weight = parseFloat(record.weight.toFixed(record?.isQtyDesimal));
      setSelectedRowKeys([record.key]);
      setSelectedProductInCart(record);
    }
  };

  const enterTotalQty = async (type) => {
    // stopCallingGetWeight();
    setDisplayManualQtyWeightInput(false);
    if(CWHardwareController){
      CWPoleDisplay(selectedProductInCart, "Quantity update")
    }
    else{
      PoleDisplay(selectedProductInCart, "Quantity update");
    }
    if (cart.items.length > 0 && selectedProductInCart.weight > 0 && !selectedProductInCart.isReturn) {
      let totalQtyFlag = true;
      setSelectedProductInCart({});
      let product = {};
      const addedToCart = cart.items;

      // Use Promise.all to wait for all async operations to complete
      await Promise.all(
        addedToCart.map(async (item) => {
          if (item.value === selectedProductInCart.value && item.lineId === selectedProductInCart.lineId) {
            product = item;
          }
          // if (item.value === selectedProductInCart.value && !item.bundleId) {
          //   product = item;
          // }
          // if (item.value === selectedProductInCart.value && item.bundleId) {
          //   const productItem = await getProductData(item.productId);
          //   product = productItem;
          //   product.weight = 1;
          //   product.isReturn = false;
          //   product.bundleId = null;
          // }
        })
      );
      delete product.expiryId;
      setIsInputFocused(false);
      if (type === "weight") {
        addProduct(selectedProductInCart, selectedProductInCart.weight, totalQtyFlag);
      } else {
        addProduct(product, selectedProductInCart.weight, totalQtyFlag);
      }

      const amountInput = document.getElementById("sm-amount-input");
      if (amountInput) {
        amountInput?.focus();
        amountInput?.blur();
      }
    }
  };

  const onChangeTotalQuantity = async (e) => {
    if (!selectedProductInCart.isReturn && Object.keys(selectedProductInCart).length > 0) {
      delete selectedProductInCart.expiryId;
      setSelectedProductInCart({ ...selectedProductInCart, weight: selectedProductInCart.isDecimalQty === true ? e : e.replaceAll(".", "") });
    }
  };

  const handleTotalQty = async (value) => {
    // if (isInputFocused === false) {
    //   if (productSearchInput === "" && value === "x") {
    //     setProductSearchInput("");
    //   } else if (value === "x") {
    //     setProductSearchInput(`${productSearchInput.toString().substring(0, productSearchInput.toString().length - 1)}`);
    //   } else {
    //     setProductSearchInput(`${productSearchInput ? productSearchInput : ""}${value}`);
    //   }
    // } else {
    if (Object.keys(selectedProductInCart).length > 0 && !selectedProductInCart.isReturn) {
      if (selectedProductQty === "" && value === "x") {
        setSelectedProductInCart({ ...selectedProductInCart, weight: 0 });
      } else if (value === "x") {
        setSelectedProductInCart({
          ...selectedProductInCart,
          weight: `${selectedProductInCart.weight.toString().substring(0, selectedProductInCart.weight.toString().length - 1)}`,
        });
      } else if (value === "clear") {
        setSelectedProductInCart({ ...selectedProductInCart, weight: 0 });
      } else {
        if (qtyNumberFlag === 0) {
          setQtyNumberFlag(1);
          setSelectedProductInCart({ ...selectedProductInCart, weight: value });
        } else {
          setSelectedProductInCart({
            ...selectedProductInCart,
            weight: selectedProductInCart.isDecimalQty === true ? `${selectedProductInCart.weight}${value}` : `${selectedProductInCart.weight}${value}`.replaceAll(".", ""),
          });
        }
      }
    }
    const amountInput = document.getElementById("sm-amount-input");
    if (amountInput) {
      amountInput?.focus();
    }
    // }
  };
  const selectProduct = (record) => {
    setSelectedKeys([record.key]);
    // setSelectedProduct(record);
  };

  const selectSalseProduct = (record) => {
    setSelectedKeys([record.sOrderID]);
    // setSelectedProduct(record);
  };

  const deleteProduct = async (addToCart) => {
    try {
      clearSelectedProductInCart();

      if (cart.payments.length > 0) {
        message.warning("Please delete the payment before clearing the cart items.");
        return;
      } else if (addToCart.isReturn) return;

      addProduct(addToCart, -addToCart.weight);
      if (giftCardData?.length > 0) {
        let authToken = authHeaders?.access_token || "";
        const { referenceId } = cart;
        const giftCardDetails = giftCardData.map(
          (ele) => `{
          cardNo: ${ele.number ? `"${ele.number}"` : null},
          type: "RD",
          referenceNo: "${referenceId}",
          amount: ${ele.amount * -1},
          cardPin: ${ele.pin ? `"${ele.pin}"` : null}
        }`
        );
        const query = `
          mutation {
            upsertGiftCardTransaction(giftCardTransactions: [${giftCardDetails.join(",")}]) {
              status
              message
            }
          }`;

        await Axios.post(
          serverUrl,
          { query },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authToken}`,
            },
          }
        );

        const paidAmount = giftCardData.reduce((sum, ele) => sum + ele.amount, 0);
        const updatedPayments = cart.payments.filter((payment) => payment.name.toLowerCase() !== "gift card");

        cart.payments = updatedPayments;
        cart.giftCardData = [];
        cart.total -= paidAmount;

        setGiftCardData([]);
        setCart(cart);
      }

      const posConfig = JSON.parse(localStorage.getItem("posConfig")) || {};
      if (posConfig?.DLN === "Y") {
        posLogActivity(addToCart, "DLN");
      }
    } catch (error) {
      message.error(error.message || "An error occurred while deleting the product.");
    }
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        if (addToCart.weight - 1 !== 0) {
          delete addToCart.expiryId;
        }
        addProduct(addToCart, -1);
        if (JSON.parse(localStorage.getItem("posConfig"))?.RQT === "Y") {
          posLogActivity(addToCart, "RQT");
        }
        const amountInput = document.getElementById("sm-amount-input");
        if (amountInput) {
          amountInput?.focus();
          amountInput?.blur();
        }
      }
    }
  };

  // const increaseProductQty = (addToCart) => {
  //   if (!addToCart.isReturn) {
  //     if (!addToCart.isManualQty) {
  //       delete addToCart.expiryId;
  //       addProduct(addToCart, 1);
  //       const amountInput = document.getElementById("sm-amount-input");
  //       if (amountInput) {
  //         amountInput?.focus();
  //         amountInput?.blur();
  //       }
  //     }
  //   }
  // };

  const increaseProductQty = async (addToCart) => {
    if (!addToCart.isReturn) {
      if (!addToCart.isManualQty) {
        delete addToCart.expiryId;
        addProduct(addToCart, 1);
        const amountInput = document.getElementById("sm-amount-input");
        if (amountInput) {
          amountInput?.focus();
          amountInput?.blur();
        }
      }
    }
  };

  const deleteReceipt = (param) => {
    let cartObj = {
      items: [],
      couponInput: [],
      advancePayment: 0,
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      docType: "Invoice",
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
      giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
      salesRepId: null,
      cardPaymentData: {},
      documentno: param ? `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}` : `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 2}`,
    };
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    setAmount("");
    setSelectedPaymentMethod("");
  };

  const deleteCart = (status = false, type) => {
    if (status === true) {
      if (JSON.parse(localStorage.getItem("posConfig"))?.DOR === "Y") {
        upsertPOSLog(cart, "DOR");
      }
    }
    deleteReceipt(type);
    /* if (parkedList.length > 0) {
        selectParkedBill(parkedList[0]);
      } */
  };

  // ORDER HISTORY BLOCK START
  const [displayOrderHistory, setDisplayOrderHistory] = useState(false);
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [salesHistoryType, setSalesHistoryType] = useState([]);
  const [startRowData, setStartRowData] = useState({ startRow: "0", endRow: "10" });

  const [orderHistorySearchType, setOrderHistorySearchType] = useState("orderDocumentNo");
  const changeOrderHistorySearchType = (value, e) => {
    setOrderHistorySearchType(value);
    setSelectDate(e);
  };

  const showOrderHistory = () => {
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        setDisplayOrderHistory(true);
      });
  };

  const [selectedOrderHistoryLine, setSelectedOrderHistoryLine] = useState("");
  const showOrderHistoryLine = (orderID) => {
    if (selectedOrderHistoryLine === orderID) {
      setSelectedOrderHistoryLine("");
    } else {
      setSelectedOrderHistoryLine(orderID);
    }
  };

  useEffect(() => {
    localStorage.setItem("dataLength", orderHistoryDetails.length);
  }, [orderHistoryDetails]);

  const getSalesHistoryData = async (order_id) => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    setLoading(true);
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {salesHistoryDetails(
                      orderId: "${order_id}"
                    ) {
                      sOrderID
                      created
                      createdby
                      updated
                      updatedby
                      documentno
                      dateordered
                      cwrProductQty
                      orderTime
                      taxamt
                      grosstotal
                      discAmount
                      layAway
                      isReturn
                      paid
                      csUser {
                        user
                      }
                      csBUnit {
                        csBunitId
                        name
                      }
                      csbUnitLocation {
                        fulladdress
                      }
                      cwrB2cCustomer {
                        cwrCustomerId
                        code
                        name
                        mobileNo
                        pincode
                        email
                        retlLoyaltyBalance
                        sCustomer {
                          sCustomerID
                          customerCategory {
                            sCustomerCateforyId
                            value
                            name
                            description
                          }
                        }
                      }
                      saleType {
                        cwrSaletypeId
                        name
                        value
                      }
                      cwrTill {
                        cwrTillID
                        till
                      }
                      tablename
                      fboRder {
                        guests
                      }
                      saLesRep {
                        waitername
                      }
                      finReceiptPlan {
                        finReceiptPlanDetails {
                          amount
                          cwrPaymentmethod {
                            cWRPaymentMethodID
                            finFinancialAccountId
                            finPaymentmethodId
                            integratedPayment
                            isloyalty
                            paymentProvider
                          }
                        }
                      }
                      line {
                        sOrderlineID
                        sOrderId
                        line
                        description
                        qty
                        netlist
                        netunit
                        created
                        linetax
                        unittax
                        unitprice
                        linenet
                        linegross
                        grosslist
                        grossstd
                        returnline
                        returnQty
                        discount
                        product {
                          mProductId
                          name
                          value
                          upc
                          hsncode
                          imageurl
                          isManualQty
                          shortDescription
                          returnable
                          returnDays
                        }
                        uom {
                          csUomId
                          name
                        }
                        tax {
                          csTaxID
                          name
                          rate
                        }
                        pricingRule {
                          mPricingrulesId
                          name
                        }
                      }
                    }
                  }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      // Processing the fetched data
      let totalQty = 0;
      const arrayData = await Promise.all(
        response?.data?.data?.salesHistoryDetails?.map(async (item) => {
          const paymentMethods = [];
          let change = 0;

          const lineItems = await Promise.all(
            item.line.map(async (pro) => {
              const productObj = await db.products.where("mProductId").equals(pro.product.mProductId).first();
              const matchedGiftCardData = await db.giftCardData.toArray().then((giftCardData) => giftCardData.find((giftcard) => giftcard.mProductId === item.mProductId));

              const productDefined = {
                batchno: productObj?.batchno || null,
                description: productObj?.description || "",
                discount: pro?.discount ?? 0,
                discountName: pro?.pricingRule?.name || "",
                imageurl: productObj?.imageurl || "",
                isDecimal: productObj?.isDecimal || false,
                isManualQty: productObj?.isManualQty || false,
                isPromoApplicable: productObj?.isPromoApplicable || false,
                isReturn: false,
                mBatchId: null,
                giftCardType: matchedGiftCardData ? matchedGiftCardData?.type : null,
                mPricingruleId: null,
                name: productObj?.name || "",
                name2: productObj?.name2 || "",
                nettotal: pro.linegross || 0,
                primaryOrderLine: null,
                productId: productObj?.mProductId || null,
                realPrice: pro.unitprice || 0,
                listPrice: productObj?.slistprice || 0,
                sunitprice: pro?.unitprice || 0,
                returnQty: null,
                originalPrice: pro?.unitprice || 0,
                productSegment: pro?.productSegment || "",
                salePrice: pro?.unitprice || 0,
                stock: productObj?.onhandQty || 0,
                tax: productObj?.cTaxId || null,
                taxCategory: productObj?.taxCategory || "",
                taxName: productObj?.taxName || "",
                taxFlag: productObj?.taxFlag || "N",
                taxAmount: pro.linetax || 0,
                taxRate: productObj?.taxRate || 0,
                uom: productObj?.csUomId || null,
                uom_name: productObj?.uomName || "",
                addNewLine: productObj?.addNewLine || "N",
                customAttributes: productObj?.customAttributes || [],
                type: productObj?.type || "",
                newCustomAttributes: [],
                priceList: productObj?.priceList || [],
                selectedAddons: [],
                isDecimalQty: productObj?.uomData?.[0]?.decimal === "Y",
                isQtyDesimal: productObj?.uomData?.[0]?.stdprecision ?? 2,
                upc: pro.product?.upc || null,
                value: productObj?.value || null,
                weight: pro.qty || 0,
                order: "N",
                productionCenter: productObj?.productionCenter || "",
                shortDescription: productObj?.shortDescription || "",
                hsncode: productObj?.hsncode || null,
                csBunitId: productObj?.csBunitId || null,
                mProductCategoryId: productObj?.mProductCategoryId || null,
                productManufacturerId: productObj?.productManufacturerId || null,
                productBrandId: productObj?.productBrandId || null,
                productCategoryName: productObj?.productCategoryName || "",
                productAddons: productObj?.productAddons || [],
                batchedProduct: productObj?.batchedProduct || false,
                batchedForSale: productObj?.batchedForSale || false,
                batchedForStock: productObj?.batchedForStock || false,
                multiPrice: productObj?.multiPrice || [],
                shelfLife: productObj?.shelfLife || 0,
                lineId: uuidv4().replace(/-/g, "").toUpperCase(),
                layAway: item.layAway || "N",
              };

              return { ...pro, ...productDefined };
            })
          );

          let paidAmount = 0;
          item?.finReceiptPlan?.[0]?.finReceiptPlanDetails?.forEach((ele) => {
            const paymentMethod = tillDataPaymentMethods.find((pi) => pi.finPaymentmethodId === ele.cwrPaymentmethod.finPaymentmethodId);
            if (paymentMethod && ele.amount > 0) {
              paymentMethods.push({ ...paymentMethod, amount: parseFloat(ele.amount.toFixed(2)), advancePayment: parseFloat(ele.amount.toFixed(2)) });
              paidAmount += ele.amount;
            } else if (ele.amount < 0) {
              change = ele.amount;
            }
          });

          return {
            change,
            items: lineItems || [],
            cardPaymentData: {},
            originalPrice: item.grosstotal || 0,
            customer: item.cwrB2cCustomer || "",
            documentno: item.documentno,
            total: (item.grosstotal || 0).toFixed(2),
            discount: item.discAmount || 0,
            tax: item.taxamt || 0,
            orderTime: item.orderTime,
            orderDate: item.dateordered,
            totalQty: item.cwrProductQty,
            payments: [],
            paymentData: paymentMethods || [],
            roundOff: 0,
            sOrderID: item.sOrderID,
            key: item.documentno,
            salesHistory: "Y",
            status: "Success",
            isSynced: 0,
            creditAmount: 0,
            createdBy: "",
            layAway: item?.layAway || "N",
            isReturn: item?.isReturn || "N",
            paid: paidAmount || 0,
            advancePayment: paidAmount || 0,
            redemptionPoints: 0,
            accumulationPoints: 0,
            saleType: item.saleType || {},
            referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
          };
        })
      );
      if (arrayData.length > 0) {
        // Create a new array by updating or adding records
        const updatedOrderDetails = orderHistoryDetails.map((order) => {
          // Check if the current order matches any entry in arrayData
          const matchingData = arrayData.find((newData) => newData.sOrderID === order.sOrderID);
          return matchingData ? { ...order, ...matchingData } : order; // Update if match found
        });

        // Update state with the new array
        setOrderHistoryDetails(updatedOrderDetails);
        setOrdersCopy(updatedOrderDetails);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching order history:", error);
      // Handle error appropriately, for example, set a state to show an error message
    } finally {
      setLoading(false);
    }
    setOrderHistoryInput("");
  };

  const searchOrderHistory = async (date, dateValue, row, flag, dateFlag) => {
    let setAuthTokens;
    let startDate;
    let endDate;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const sortOptions = {
      documentno: "desc",
      dateordered: "desc",
      customer: null,
      totalAmount: null,
    };
    const filteredSortOptions = Object.fromEntries(Object.entries(sortOptions).filter(([key, value]) => value !== null));
    if (dateValue?.length > 0) {
      localStorage.setItem("orderType", JSON.stringify([date, dateValue]));
      startDate = moment(dateValue[0]).format("YYYY-MM-DD");
      endDate = moment(dateValue[1]).format("YYYY-MM-DD");
    }

    const productSearchInput = document.getElementById("sm-salesHistory-customer");
    if (productSearchInput) {
      productSearchInput.focus();
    }
    const dateFiltered = `"{\\"DateRange\\":[\\"${startDate}\\",\\"${endDate}\\"]}"`;
    const sortData = JSON.stringify(filteredSortOptions).replace(/"/g, '\\"');
    if (date === "orderDateSearchKey") {
      setLoading(true);
      try {
        const startRow = row;
        const endRow = parseInt(startRow, 10) + 50;

        const orderHistoryData = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {salesHistory(
                      q: ${searchHistoryInput?.trim() && flag ? `"${searchHistoryInput?.trim()}"` : null},
                      filter_by: ${dateFiltered},
                      startRow: "${startRow}",
                      endRow: "${endRow}",
                      sort_by: "${sortData}",
                      tillId: ${dateFlag ? `"${tillValue.cwr_till_id}"` : null}
                    ) {
                    sOrderID
                    documentno
                    dateordered
                    totalQty
                    orderTime
                    grosstotal
                    layAway
                    isReturn
                    cwrB2cCustomer {
                      name
                      }
                    cwrTill {
                      till
                    }
                  }         
                  }
                  `,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });

        if (orderHistoryData?.data?.data?.salesHistory.length === 0) {
          message.error("No more records!");
          setFiltersFlag(true);
          setLoading(false);
        } else {
          setFilterdDate(dateFiltered);
          const arrayData = await Promise.all(
            orderHistoryData?.data?.data?.salesHistory?.map(async (item) => {
              const obj = {
                change: 0,
                items: [],
                cardPaymentData: {},
                originalPrice: item.grosstotal || 0,
                customer: item.cwrB2cCustomer || "",
                documentno: item.documentno,
                total: (item.grosstotal || 0).toFixed(2),
                discount: item.discAmount || 0,
                tax: item.taxamt || 0,
                orderTime: item.orderTime,
                orderDate: item.dateordered,
                totalQty: item.totalQty || 0,
                payments: [],
                paymentData: [],
                roundOff: 0,
                sOrderID: item.sOrderID,
                key: item.documentno,
                salesHistory: "Y",
                status: "Success",
                isSynced: 0,
                creditAmount: 0,
                createdBy: "",
                layAway: item.layAway || "N",
                isReturn: item.isReturn || "N",
                paid: 0,
                advancePayment: 0,
                redemptionPoints: 0,
                accumulationPoints: 0,
                // saleType: item.saleType || {},)
                referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
              };

              return obj;
            })
          );

          setOrderHistoryDetails((prevData) => [...prevData, ...arrayData]);
          setOrdersCopy((prevData) => [...prevData, ...arrayData]);
          setFiltersFlag(dateFlag ? false : true);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching order history data:", error);
        setLoading(false);
      }
    } else {
      setLoading(true);
      setOrderHistoryDetails([]);
      prevHistoryRef.current = orderHistoryDetails;

      try {
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query { salesHistory(
                q: "${dateValue.trim()}",
                filter_by: ${flag ? filteredDate : null},
                startRow: "0",
                endRow: "100",
                sort_by: "${sortData}",
                tillId: ""
              ) {
                    sOrderID
                    documentno
                    dateordered
                    totalQty
                    orderTime
                    grosstotal
                    layAway
                    isReturn
                    cwrB2cCustomer {
                      name
                      }
                    cwrTill {
                      till
                    }
                  }
                  }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });

        // Processing the fetched data
        const arrayData = await Promise.all(
          response?.data?.data?.salesHistory?.map(async (item) => {
            return {
              change: 0,
              items: [],
              cardPaymentData: {},
              originalPrice: item.grosstotal || 0,
              customer: item.cwrB2cCustomer || "",
              documentno: item.documentno,
              total: (item.grosstotal || 0).toFixed(2),
              discount: item.discAmount || 0,
              tax: item.taxamt || 0,
              orderTime: item.orderTime,
              orderDate: item.dateordered,
              totalQty: item.cwrProductQty,
              payments: [],
              paymentData: [],
              roundOff: 0,
              sOrderID: item.sOrderID,
              key: item.documentno,
              salesHistory: "Y",
              status: "Success",
              isSynced: 0,
              creditAmount: 0,
              createdBy: "",
              layAway: item?.layAway || "N",
              isReturn: item?.isReturn || "N",
              paid: 0,
              advancePayment: 0,
              redemptionPoints: 0,
              accumulationPoints: 0,
              referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
            };
          })
        );

        setOrdersCopy(arrayData);
        setOrderHistoryDetails(arrayData);
        setFiltersFlag(!dateFlag);
      } catch (error) {
        console.error("Error fetching order history:", error);
        // Handle error appropriately, for example, set a state to show an error message
      } finally {
        setLoading(false);
      }
    }

    setLoading(false);
    setOrderHistoryInput("");
  };

  // parked block

  const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
  const initialParkedList = storedParkedList ? storedParkedList : [];
  const [displayParkedBillModal, setDisplayParkedBillModal] = useState(false);
  const [parkedList, setParkedList] = useState(initialParkedList);
  const [filterdParkedList, setFilterdParkedList] = useState(initialParkedList);

  useEffect(() => {
    setFilterdParkedList(parkedList);
  }, [parkedList]);

  const discardParkedBill = async (record) => {
    upsertPOSLog(record.parkedCart, "DPO");
    let array = [];
    filterdParkedList.map((item) => {
      if (item.key !== record.key) {
        array.push(item);
      }
    });
    localStorage.setItem("parkedList", JSON.stringify(array));
    setParkedList([...array]);
    setFilterdParkedList(array);
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    const tillSessionId = tillSession?.tillSessionId;
    let cartToDb = record.parkedCart;
    cartToDb.orderTime = timeStamp();
    cartToDb.createdBy = tillData.tillAccess?.csUserId || "";
    cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId || "";
    cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
    cartToDb.tillSessionId = tillSessionId;
    cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
    cartToDb.isSynced = 0;
    cartToDb.syncAttempts = 0;
    cartToDb.customerSearchKey = cart?.customer?.code || "";
    cartToDb.total = 0;
    cartToDb.totalQty = 0;
    cartToDb.tax = 0;
    cartToDb.isReturn = false;
    cartToDb.items.forEach((item) => {
      item.salePrice = 0;
      item.sunitprice = 0;
      item.netStd = 0;
      item.nettotal = 0;
      item.unitTax = 0;
      item.taxAmount = 0;
      item.weight = 0;
      item.unitPrice = 0;
      item.netList = 0;
      item.discount = 0;
      item.isReturn = false;
      item.linetax = 0;
      item.linenet = 0;
      item.linegross = 0;
      item.netunit = 0;
      item.listPrice = 0;
      item.grossunit = 0;
      item.grossstd = 0;
      item.grosslist = 0;
      item.realPrice = 0;
    });
    if (record.parkedCart.type === "Layaway") {
      setDocumnetSequence(documentSequence);
    } else {
      await db.orders.add(cartToDb);
    }
  };

  const openDisplayParkedBillModal = (param, record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          setSelectedDocumentType("Invoice");
          let PaymentMethods = getFilteredPaymentMethods() || [];
          let payments =
            PaymentMethods.length > 5
              ? _.sortBy(PaymentMethods, "sequenceNo").slice(
                  0,
                  tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && PaymentMethods.length === 5 ? 5 : cart.layAway === "Y" ? 4 : 5
                )
              : PaymentMethods;

          setShowPayments(payments);
          parkBill(param, record);
          const productSearchInput = document.getElementById("sm-product-search");
          setTimeout(() => {
            productSearchInput.focus();
            setOpen(false);
          }, 600);
          // message.info("Please wait...");
          // setTimeout(() => {
          //   setDisplayParkedBillModal(true);
          // }, 2000);
        },
        onCancel() {
          const productSearchInput = document.getElementById("sm-product-search");
          setTimeout(() => {
            productSearchInput?.focus();
            setOpen(false);
          }, 300);
          setDisplayParkedBillModal(true);
        },
      });
    } else {
      setDisplayParkedBillModal(true);
    }
  };

  const parkedListRef = useRef(initialParkedList);
  useEffect(() => {
    parkedListRef.current = parkedList;
  }, [parkedList]);

  const parkBill = (value, record) => {
    const presentParkedList = value === "parkKey" ? parkedListRef.current : parkedList;
    if (cart.parked !== "Y" && cart.layAway !== "Y") {
      const newDocumentSequence = documentSequence + 1;
      localStorage.setItem("documentSequence", newDocumentSequence);
      setDocumnetSequence(newDocumentSequence);
      deleteReceipt();
      if (value === "retrieve") {
        selectParkedBill(record, "management");
      }
    } else {
      if (value === "retrieve") {
        selectParkedBill(record, "management");
      } else {
        let cartObj = {
          items: [],
          total: 0,
          tax: 0,
          discount: 0,
          paid: 0,
          change: 0,
          totalQty: 0,
          roundOff: 0,
          payments: [],
          redemptionPoints: 0,
          accumulationPoints: 0,
          creditAmount: 0,
          docType: "Invoice",
          sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
          referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
          giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
          couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
          customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
          salesRepId: null,
          cardPaymentData: {},
          documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
        };
        setCart(cartObj);
        localStorage.setItem("cartObj", JSON.stringify(cartObj));
      }
    }
    cart.parked = "Y";
    const presentCart = cart;
    const presentTimeStamp = timeStamp();
    const parkedBill = {
      parkedCart: presentCart,
      parkedTime: presentTimeStamp,
      parkedDocNo: cart.documentno,
      parkedOrderID: cart.items[0].sOrderReturnId,
      parkedBillId: uuidv4().replace(/-/g, "").toUpperCase(),
    };
    presentParkedList.push(parkedBill);
    localStorage.setItem("parkedList", JSON.stringify(presentParkedList));
    setParkedList([...presentParkedList]);
    {
      message.success(`${t("bill_parking_successful")}`);
    }
  };

  const selectParkedBill = (item, fieldName) => {
    const listItemIndex = parkedList.findIndex((bill) => bill.parkedBillId === item.parkedBillId);
    const selectedParkedBill = { ...parkedList[listItemIndex].parkedCart, isRetrived: "Y" };

    // Remove the selected bill from the parked list
    const updatedParkedList = [...parkedList];
    updatedParkedList.splice(listItemIndex, 1);
    setParkedList(updatedParkedList);
    localStorage.setItem("parkedList", JSON.stringify(updatedParkedList));

    // Handle layaway logic
    if (selectedParkedBill?.layAway === "Y") {
      setSelectedDocumentType("Advance");

      const PaymentMethods = getFilteredPaymentMethods() || [];
      const maxPayments =
        PaymentMethods.length > 5
          ? _.sortBy(PaymentMethods, "sequenceNo").slice(
              0,
              tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && PaymentMethods.length === 5 ? 5 : cart.layAway === "Y" ? 4 : 5
            )
          : PaymentMethods;

      setShowPayments(maxPayments);
    }
    setCart(selectedParkedBill);
    localStorage.setItem("cartObj", JSON.stringify(selectedParkedBill));

    setDisplayParkedBillModal(false);
    if (fieldName === "management") {
      setManagementScreenShow(false);
    }
  };

  const selectLayAwayOrder = (item, fieldName) => {
    const itemWithNumberTotal = {
      ...item,
      total: parseFloat(item.total),
    };
    setShowPaymentMethods(true);
    localStorage.setItem("cartObj", JSON.stringify(itemWithNumberTotal));
    setCart(itemWithNumberTotal);
  };

  const [parkedBillSearchInput, setParkedBillSearchInput] = useState("");
  const [salesHistoryCustomerSearchInput, setSalesHistoryCustomerSearchInput] = useState("");
  const [salesHistoryDocumentNoSearchInput, setSalesHistoryDocumentNoSearchInput] = useState("");

  const handleParkedBillSearchInput = (e) => {
    setParkedBillSearchInput(e.target.value);
    searchParkedBill(e.target.value);
  };

  const searchParkedBill = (key) => {
    const lowercaseKey = key.toLowerCase();
    const filteredBills = parkedList.filter((list) => {
      const lowercaseCustomer = list.customer.toLowerCase();
      const lowercaseParkedDocNo = list.parkedDocNo.toLowerCase();
      return lowercaseCustomer.includes(lowercaseKey) || lowercaseParkedDocNo.includes(lowercaseKey);
    });

    // Return the filtered records
    if (filteredBills.length > 0) {
      setFilterdParkedList(filteredBills);
    }
  };

  const closeParkedBillModal = () => {
    setDisplayParkedBillModal(false);
    setParkedBillSearchInput("");
    setFilterdParkedList([...parkedList]);
  };

  // PARKED BILL BLOCK END

  // prodcut add ons start

  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [isUpdatingAddon, setIsUpdatingAddon] = useState(false);

  const addDefinedProductWithAddons = (productObjs, upc, batchno, mBatchId, price) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      const originalPrice = price - (price - price * (100 / (100 + productObj.taxRate)));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    productObj.salePrice = productObj?.sunitprice || 0;
    productObj.realPrice = price;
    productObj.order = "N";
    (productObj.isQtyDesimal = productObj?.uomData[0]?.length > 0 ? productObj.uomData[0].stdprecision : 2), (productObj.productId = productObj?.mProductId || null);
    productObj.mBatchId = mBatchId || null;
    addProductToCart(
      productObj,
      productObj.weight,
      false,
      cart,
      setCart,
      setSelectedProductInCart,
      deleteCart,
      processTotalManualDiscount,
      setLoader,
      salesRepresentDefaultLine,
      tillData,
      cartRef,
      productsCopy,
      salesRepresent,
      orderType,
      processBillDiscounts,
      undefined,
      setSelectedRowKeys,
      setOrderTimeDetails,
      setDisplayAddOnSelection,
      displayAddOnSelection,
      handleAddOnModal,
      manualDiscountTypes,
      CheckoutFlatDiscount,
      CheckoutPercentageDiscount,
      CheckoutTotalManualDiscount,
      setProductStock
    );
    setSelectedAddons([]);
  };

  const handleAddOnModal = (record, qty) => {
    setDisplayAddOnSelection(true);
    record.qty = qty;
    setSelectedProduct(record);
    setAddOnsList({
      requiredList: record.productAddons,
      optionsList: record.productAddons,
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }
    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  // const handleSelectedSaleTypeForProducts = (obj) => async () => {
  //   try {
  //     setSelectedSaleTypeData(obj);
  //     const paymentDetails = getFilteredPaymentMethods(obj?.cwrSaletype?.cwrSaletypeId || null);
  //     setShowPayments(paymentDetails);
  //     localStorage.setItem("saleTypeData", JSON.stringify(obj));
  //     setSelectedSaleType(obj?.cwrSaletype?.name);
  //     setIsSaleTypeFlag(false);
  //     changeCustomerId(obj);

  //     const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  //     const taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  //     // let updatedCart = { ...cart };
  //     let updatedCart = JSON.parse(localStorage.getItem("cartObj"));
  //     updatedCart.customer = obj?.customer?.b2cCustomerId ? obj?.customer : tillData.tillAccess.csBunit.b2cCustomer;

  //     const updatedProducts = await db.products.toArray();
  //     const processedProducts = updatedProducts.map((product) => {
  //       const matchedIndex = product.priceList.find((priceList) => priceList.sPricelistId === updatedCart.customer?.sPriceListId);
  //       if (matchedIndex) {
  //         return {
  //           ...product,
  //           price: matchedIndex.pricestd,
  //           salePrice: matchedIndex.pricestd,
  //           realPrice: matchedIndex.pricestd,
  //           sunitprice: matchedIndex.pricestd,
  //         };
  //       }
  //       if (!updatedCart?.customer?.sPriceListId) {
  //         return {
  //           ...product,
  //           // price: product.originalPrice,
  //           price: product.sunitprice,
  //           salePrice: product.sunitprice,
  //           realPrice: product.sunitprice,
  //           sunitprice: product.sunitprice,
  //         };
  //       }

  //       return product;
  //     });
  //     setSaleTypeProducts(processedProducts);
  //     const finalCartObj = { ...updatedCart };
  //     localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
  //     setCart(finalCartObj);
  //     if (obj?.cwrSaletype?.value === "DI" || obj?.cwrSaletype?.value === "TA") {
  //       return;
  //     } else {
  //       setShowProducts(true);
  //     }
  //   } catch (e) {
  //     console.log("Error", e);
  //   }
  // };

  const handleSelectedSaleTypeForProducts = (obj) => async () => {
    try {
      setSelectedSaleTypeData(obj);
      const paymentDetails = getFilteredPaymentMethods(obj?.cwrSaletype?.cwrSaletypeId || null);
      setShowPayments(paymentDetails);
      localStorage.setItem("saleTypeData", JSON.stringify(obj));
      setSelectedSaleType(obj?.cwrSaletype?.name);
      setIsSaleTypeFlag(false);
      changeCustomerId(obj);

      let updatedCart = JSON.parse(localStorage.getItem("cartObj"));
      updatedCart.customer = obj?.customer?.b2cCustomerId ? obj?.customer : tillData.tillAccess.csBunit.b2cCustomer;
      let processedProducts;
      let updatedProducts = await db.products.toArray();
      const filteredProducts = updatedProducts.filter((product) => product.priceList?.some((priceList) => priceList?.sPricelistId === updatedCart.customer?.sPriceListId));
      processedProducts = filteredProducts.map((product) => {
        const matchedPrice = product.priceList.find((priceList) => priceList.sPricelistId === updatedCart.customer?.sPriceListId);
        return {
          ...product,
          price: matchedPrice.pricestd,
          salePrice: matchedPrice.pricestd,
          realPrice: matchedPrice.pricestd,
          sunitprice: matchedPrice.pricestd,
        };
      });
      if (!updatedCart?.customer?.sPriceListId) {
        processedProducts = updatedProducts;
      }

      setSaleTypeProducts(processedProducts);

      const finalCartObj = { ...updatedCart };
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
      setCart(finalCartObj);

      if (obj?.cwrSaletype?.value === "DI" || obj?.cwrSaletype?.value === "TA") {
        return;
      } else {
        setShowProducts(true);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleAdd = async () => {
    console.log("selected addon");
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        selectedAddons: selectedAddons || [],
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
    setFilterDrawer(false);
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  // product add ons end

  //// CENTER BUTTON BLOCK END ////

  //// CART OPERATIONS START ////

  // DEFAULT CART START
  const [cart, setCart] = useState({
    items: [],
    couponInput: [],
    advancePayment: 0,
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    docType: "Invoice",
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
    giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
    couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
    salesRepId: null,
    cardPaymentData: {},
    documentno: `${tillData.tillAccess.cwrTill.prefix}${tillDocumentSequence + 1}`,
  });
  // DEFAULT CART END

  // BARCODE READER BLOCK START
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);

  const onBarcodeInput = (data, flag) => {
    keyboard?.current?.setInput("");
    let chackBarcodeFlag = false;
    if (data?.trim() !== "" && data !== null) {
      barcodeScaner(
        data,
        tillData,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        chackBarcodeFlag,
        setSelectedProductInCart,
        setProductSearchInput,
        productSearchInputRef,
        cart
      );
    }
  };

  const [isStockModal, setIsStockModal] = useState(false);
  const [tempCart, setTempCart] = useState({});

  const verifyStock = async () => {
    if (posConfig?.enableStockCheck === "N" || !posConfig?.enableStockCheck) {
      handleCartTotalClick();
      return;
    }

    let stockCheckResult = true;
    let isFinalValidation = true;
    stockCheckResult = await stock({
      cart,
      setCart,
      tillData,
      setProductStock,
      isFinalValidation,
      setTempCart,
    });

    setSelectedProductInCart({});
    if (stockCheckResult) {
      handleCartTotalClick();
    } else {
      setIsStockModal(true);
    }
  };

  const handleCartTotalClick = async () => {
    try {
      let index = cart.items.findIndex((item) => item.productSegment === "AD");
      if (index !== -1 && selectedDocumentType !== "Advance") {
        setShowPaymentMethods(false);
        message.warn("Advance option not selected. Please select the Advance option to proceed with this order");
        return;
      }
      setAmount(parseFloat(parseFloat(amount || cart.total - cart.paid).toFixed(2)));
      const workflowRules = (await db.POSWorkFlowRules.toArray()) || [];
      const approvers = (await db.approvers.toArray()) || [];

      let localCart = JSON.parse(localStorage.getItem("cartObj")) || cart;

      if (!localCart.items) {
        localCart.items = [];
      }

      let PaymentMethods = getFilteredPaymentMethods() || [];
      const advanceItem = cart.items.find((item) => item.type === "S" && item.weight > 0);
      if (cart.layAway === "Y" && cart?.docType === "Advance" && !advanceItem) {
        PaymentMethods = [
          ...PaymentMethods,
          {
            cWRPaymentMethodID: "",
            sequenceNo: 9999,
            finPaymentmethodId: "",
            finFinancialAccountId: "",
            finDayCloseAccountId: null,
            name: "Complete Order",
            integratedPayment: false,
            isloyalty: false,
            paymentProvider: null,
            iscredit: false,
            isGiftCard: false,
            isDefault: "N",
            csCurrencyId: "",
            isoCode: "AED",
            isactive: true,
            salesType: [],
          },
        ];
      }
      let payments =
        PaymentMethods.length > 5
          ? _.sortBy(PaymentMethods, "sequenceNo").slice(
              0,
              tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && PaymentMethods.length === 5 ? 5 : cart.layAway === "Y" ? 4 : 5
            )
          : PaymentMethods;

      setShowPayments(payments);
      setStartIndex(cart.layAway === "Y" && payments.length >= 5 ? 5 : payments.length);

      if (workflowRules.length > 0 && localCart.isReturn === true && localCart.items.length > 0 && !showPaymentMethods) {
        workflowRules.forEach((res) => {
          if (res.eventName === "Sales Return Process") {
            dispatchEvent(res.eventName, res.ruleName, res.ruleCondition, approvers, (eventData) => {
              if (eventData?.approvalGranted) {
                setSelectedProductInCart({});
                openPaymentModalByCustomer(cart);
                setShowPaymentMethods(true);
                if(CWHardwareController){
                  CWPoleDisplay(cart, "Total to pay")
                }
                else{
                  PoleDisplay(cart, "Total to pay");
                }
              }
            });
          }
        });
      } else if (localCart.items.length > 0) {
        setSelectedProductInCart({});
        openPaymentModalByCustomer(cart);
        setShowPaymentMethods(true);
        if(CWHardwareController){
          CWPoleDisplay(cart, "Total to pay")
        }
        else{
          PoleDisplay(cart, "Total to pay");
        }
      }
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails")) || { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        paymentStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };

      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    } catch (error) {
      console.error("Error handling cart total:", error);
    }
  };

  const filterPaymentsBySaleType = (paymentMethods, id) => {
    const saleTypeId = id || selectedSaleTypeData?.cwrSaletype?.cwrSaletypeId;
    if (!saleTypeId) return paymentMethods; // If saleTypeId is null or falsy, return all payment methods

    return paymentMethods.filter(({ salesType }) => Array.isArray(salesType) && salesType.some(({ cwrSaletypeId }) => cwrSaletypeId === saleTypeId));
  };

  const getFilteredPaymentMethods = (id) => {
    if (!Array.isArray(tillDataPaymentMethods)) return [];

    const filteredBySaleType = filterPaymentsBySaleType(tillDataPaymentMethods, id);

    const filteredMethods = _.sortBy(
      filteredBySaleType.filter((pm) => {
        const isLoyaltyDisabled = posConfig?.loyaltyApplicable === "N" && pm.name === "Loyalty";
        const isNoLoyaltyLevel = !cart?.customer?.loyaltyLevel?.cwrLoyaltyLevelId && pm.name === "Loyalty";
        const isNoCredit = !cart?.customer?.iscredit && pm.name === "Credit";
        const isCafeTips = pm.name.toLowerCase() === "cafe tips";

        return !(isLoyaltyDisabled || isNoLoyaltyLevel || isNoCredit || isCafeTips);
      }),
      "sequenceNo"
    );

    return filteredMethods;
  };

  const filteredPaymentMethods = getFilteredPaymentMethods();

  const initialShowPayments = filteredPaymentMethods.length > 5 ? filteredPaymentMethods.slice(0, 5) : filteredPaymentMethods;
  const [isShowingAll, setIsShowingAll] = useState(false);
  const [showPayments, setShowPayments] = useState(initialShowPayments);
  const [startIndex, setStartIndex] = useState(0);

  const addDefinedProduct = async (productObjs, upc, batchno, mBatchId, price, checkWeight, modifiedQty, batchedItem, modifiedPrice, scanFlag) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    let matchedPriceIndex = [];
    if (!scanFlag) {
      if (productObj?.priceList?.length > 0) {
        let cartData = JSON.parse(localStorage.getItem("cartObj"));
        matchedPriceIndex = productObj?.priceList?.filter((i) => i.sPricelistId === cartData.customer.sPriceListId);
        price = matchedPriceIndex[0]?.pricestd || price;
        productObj.originalPrice = productObj.sunitprice;
        productObj.sunitprice = matchedPriceIndex[0]?.pricestd || productObj.sunitprice;
      }
    }

    let productDefined = {
      batchno: batchno || null,
      description: productObj?.description || "",
      discount: 0,
      discountName: "",
      imageurl: productObj?.imageurl || "",
      isDecimal: productObj?.isDecimal || false,
      isManualQty: productObj?.isManualQty || false,
      isPromoApplicable: productObj?.isPromoApplicable || false,
      isReturn: false,
      mBatchId: mBatchId || null,
      mPricingruleId: null,
      name: productObj?.name || "",
      name2: productObj?.name2 || "",
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj?.mProductId || null,
      realPrice: price,
      listPrice: productObj?.slistprice || 0,
      sunitprice: productObj?.sunitprice || 0,
      returnQty: null,
      salePrice: price,
      stock: productObj?.onhandQty || 0,
      tax: productObj?.cTaxId || null,
      taxCategory: productObj?.taxCategory || "",
      taxName: productObj?.taxName || "",
      taxFlag: productObj?.taxFlag || "N",
      taxAmount: 0,
      taxRate: productObj?.taxRate || 0,
      uom: productObj?.csUomId || null,
      uom_name: productObj?.uomName || "",
      addNewLine: productObj?.addNewLine || "N",
      customAttributes: productObj?.customAttributes || [],
      type: productObj?.type || "",
      newCustomAttributes: [],
      priceList: productObj?.priceList || [],
      originalPrice: productObj?.originalPrice || productObj?.sunitprice,
      productSegment: productObj?.productSegment || "",
      selectedAddons: [],
      isDecimalQty: productObj?.uomData?.[0]?.decimal === "Y" ? true : false,
      isQtyDesimal: productObj?.uomData?.[0]?.stdprecision || 2,
      upc: upc || "",
      value: productObj?.value || "",
      weight: 0,
      order: "N",
      productionCenter: productObj?.productionCenter || "",
      shortDescription: productObj?.shortDescription || "",
      hsncode: productObj?.hsncode || null,
      csBunitId: productObj?.csBunitId || null,
      mProductCategoryId: productObj?.mProductCategoryId || null,
      productManufacturerId: productObj?.productManufacturerId || null,
      productBrandId: productObj?.productBrandId || null,
      productCategoryName: productObj?.productCategoryName || "",
      productAddons: productObj?.productAddons || [],
      batchedProduct: productObj?.batchedProduct || false,
      batchedForSale: productObj?.batchedForSale || false,
      batchedForStock: productObj?.batchedForStock || false,
      multiPrice: productObj?.multiPrice || false,
      shelfLife: productObj?.shelfLife || "",
      upcPricingRule: productObj?.upcPricingRule || null,
    };

    let cartItems = JSON.parse(localStorage.getItem("cartObj"))?.items;
    let filterItem = cartItems?.filter((item) => item.productId === productDefined.productId);
    if (filterItem?.length === 1 && filterItem?.selectedAddons?.length <= 0) {
      productDefined.lineId = filterItem[0].lineId;
    }
    setSelectedProductInCart(productDefined);
    if (productDefined.isManualQty && posConfig.showWeightPopup === "Y") {
      // checkIsManualWeight(productDefined);
      setDisplayManualQtyWeightInput(true);
      callGetWeightAPI(productDefined);
      setCurrentWeightSelectedProduct(productDefined);
    }
    if (checkWeight === true) {
      let index = cart.items.findIndex((item) => item.value === productDefined.value);
      if (batchedItem === true) {
        modifiedQty = cart.items > 0 ? cart.items[index].weight + 1 : 1;
      }
      const productSearchInput = document.getElementById("sm-product-search");
      if (productSearchInput) {
        productSearchInput.blur();
      }
      addProduct(productDefined, modifiedQty, false, modifiedPrice);
      setProductSearchInput("");
      if (productSearchInputRef?.current) {
        productSearchInputRef?.current?.clearInput();
      }
    } else {
      addProduct(productDefined, 1);
      setProductSearchInput("");
      if (productSearchInputRef?.current) {
        productSearchInputRef?.current?.clearInput();
      }
    }
    setFilterDrawer(false);
    setIsProductsVisible(false);
    setQtyNumberFlag(0);
  };

  const selectProductToCart = (data) => {
    data.productAddons = data?.productAddons || [];
    data.customAttributes = data?.customAttributes || [];
    data.selectedAddons = data?.selectedAddons || [];
    addProduct(data, 1);
    setDisplayBatchSelection(false);
  };
  // BARCODE READER BLOCK END

  // PRODUCT WEIGHT MODAL START
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});
  const [stockList, setStockList] = useState([]);

  const onProductModalChangeWeight = (event) => {
    setProductWeightModalInput(event.target.value);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const pickProduct = (obj) => {
    if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
      if (obj.mBatch.length === 1) {
        setFilterDrawer(false);
        addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
      } else {
        const productSet = [];
        const localObj = obj;
        for (let i = 0; i < obj.mBatch.length; i += 1) {
          const batchItem = { ...localObj.mBatch[i] };
          const obj = { ...localObj };
          if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
            // prettier-ignore
            const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
            const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
            batchItem.price = taxedPrice;
            obj.cTaxId = obj.contraTaxId;
            obj.taxRate = obj.contraRate;
          }
          const productDefined = {
            batchno: batchItem.batchno,
            description: obj.description || "",
            discount: 0,
            discountName: "",
            imageurl: obj.imageurl || "",
            isDecimal: obj.isDecimal || false,
            isManualQty: obj.isManualQty || false,
            isPromoApplicable: obj.isPromoApplicable || false,
            isReturn: false,
            mBatchId: batchItem?.mBatchId || null,
            mPricingruleId: null,
            name: obj.name || "",
            name2: obj.name2 || "",
            nettotal: 0,
            primaryOrderLine: null,
            productId: obj.mProductId || "",
            realPrice: batchItem.price || 0,
            listPrice: obj.slistprice || 0,
            sunitprice: obj.sunitprice || 0,
            returnQty: null,
            salePrice: batchItem.price || 0,
            originalPrice: obj.sunitprice || 0,
            productSegment: obj?.productSegment || "",
            mrpPrice: batchItem.listPrice || 0,
            stock: obj.onhandQty || 0,
            tax: obj.cTaxId || null,
            taxCategory: obj.taxCategory || "",
            taxName: obj?.taxName || "N",
            productSegment: obj.productSegment || "",
            taxFlag: obj?.taxFlag || "N",
            customAttributes: obj.customAttributes || [],
            type: obj.type || "",
            newCustomAttributes: [],
            priceList: obj?.priceList || [],
            selectedAddons: [],
            taxAmount: 0,
            taxRate: obj.taxRate || 0,
            uom: obj.csUomId || null,
            uom_name: obj.uomName || "",
            addNewLine: obj?.addNewLine || "N",
            isDecimalQty: obj.uomData?.[0]?.decimal === "Y" || false,
            isQtyDesimal: obj.uomData?.[0]?.stdprecision || 2,
            upc: batchItem.upc || null,
            value: obj.value || null,
            weight: 0,
            order: "N",
            shortDescription: obj.shortDescription || "",
            hsncode: obj.hsncode || null,
            csBunitId: obj.csBunitId || null,
            mProductCategoryId: obj.mProductCategoryId || null,
            productManufacturerId: obj.productManufacturerId || null,
            productBrandId: obj.productBrandId || null,
            batchedProduct: obj.batchedProduct || "",
            batchedForSale: obj.batchedForSale || "",
            batchedForStock: obj.batchedForStock || "",
            multiPrice: obj.multiPrice || false,
            shelfLife: obj.shelfLife || "",
          };

          productSet.push(productDefined);
        }
        setFilterDrawer(false);
        setBatchSetAvailable([...productSet]);
        setDisplayBatchSelection(true);
      }
    } else {
      addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice);
    }
  };

  const checkIsManualWeight = (prod) => {
    if (prod.isManualQty && posConfig?.showWeightPopup === "Y") {
      setDisplayManualQtyWeightInput(true);
      setCurrentWeightSelectedProduct(prod);
    } else {
      addProduct(prod, 1);
    }
  };

  const addManualWeightToProduct = () => {
    let inputValidator = new RegExp(/^[0-9]*$/);
    if (currentWeightSelectedProduct.isDecimal) inputValidator = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    if (inputValidator.test(productWeightModalInput) && parseFloat(productWeightModalInput) > 0) {
      setDisplayManualQtyWeightInput(false);
      addProduct(currentWeightSelectedProduct, productWeightModalInput);
      setTimeout(() => {
        setProductWeightModalInput("");
        setCurrentWeightSelectedProduct({});
      }, 500);
    } else {
      console.warn("Invalid weight input: ", productWeightModalInput);
      message.warning("Invalid input value");
    }
  };

  // PRODUCT WEIGHT MODAL END

  // PRODUCT FILTER START
  const [isProductsFilter, setIsProductsFilter] = useState(() => (posConfig?.defaultSearchScreen === "Category Search" ? true : false));
  const [allProductCategories, setAllProductCategories] = useState([]);
  const [selectedProductBrand, setSelectedProductBrand] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("allProducts");
  const [selectCategotyList, setSelectCategotyList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const prevProductsListRef = useRef(null);
  const prevHistoryRef = useRef(orderHistoryDetails);
  const [productsCopy, setProductsCopy] = useState([]);

  const callGetWeightAPI = async (productDefined) => {
    try {
      const response = await Axios({
        url: `${tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl}getWeight`,
        method: "POST",
      });
      const productToUpdate = productDefined && Object.keys(productDefined)?.length > 0 ? productDefined : selectedProductInCart;
      if (response?.data?.Weight) {
        const weight = parseFloat(response.data.Weight.replace("kg", "").trim());
        if (!isNaN(weight)) {
          productToUpdate.weight = weight;
          setSelectedProductInCart(productToUpdate);
        }
      }
    } catch (error) {
      console.error("Error in callGetWeightAPI:", error);
    }
  };

  useEffect(() => {
    db.AllProductCategories.toArray().then((res) => {
      setAllProductCategories(res);
    });
    if (!localStorage.getItem("cartObj")) {
      localStorage.setItem("cartObj", JSON.stringify(cart));
    }
  }, []);

  useEffect(() => {
    getCategoryProducts(selectedProductCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectProductCategory = (category) => {
    if (selectedProductCategory !== category) {
      setSelectedProductCategory(category);
    }
  };

  const filterProducts = (brands, categories) => {
    let filteredItems = productsCopy;

    if (brands?.length > 0 || categories?.length > 0) {
      filteredItems = productsCopy.filter(
        (item) => (brands?.length === 0 || brands?.includes(item.brandId)) && (categories?.length === 0 || categories?.includes(item.mProductCategoryId))
      );
    }

    // Update the productsList with filtered items
  };

  // const restaurantProductCategory = async (categorie) => {
  //   await db.products
  //     .where("mProductCategoryId")
  //     .equals(categorie)
  //     .toArray()
  //     .then(async (response) => {
  //       setProductsData(response);
  //     });
  // };

  // Debounced handler for brand checkbox changes
  const debouncedBrandChange = debounce((brandId) => {
    const isSelected = selectedProductBrand?.includes(brandId);
    let updatedSelectedBrands = [];

    if (isSelected) {
      // Brand is already selected, remove it from the selectedProductBrand array
      updatedSelectedBrands = selectedProductBrand?.filter((id) => id !== brandId);
    } else {
      // Brand is not selected, add it to the selectedProductBrand array
      let tempBrand = selectedProductBrand || [];
      updatedSelectedBrands = [...tempBrand, brandId];
    }

    setSelectedProductBrand(updatedSelectedBrands || []); // Ensure it's always an array

    // Filter products based on selected brands and categories
    filterProducts(updatedSelectedBrands || [], selectCategotyList);
  }, 300);

  const handleBrandCheckboxChange = (brandId) => {
    debouncedBrandChange(brandId);
  };

  // Debounced handler for category checkbox changes
  const debouncedCategoryChange = debounce((checkedValues) => {
    setSelectCategotyList(checkedValues);
    // Filter products based on selected brands and categories
    filterProducts(selectedProductBrand, checkedValues);
  }, 300); // Adjust debounce delay as needed
  const handleCategoryCheckboxChange = (checkedValues) => {
    debouncedCategoryChange(checkedValues);
  };

  const handleSelectProduct = () => {
    prevProductsListRef.current = [];
    const lowerCaseSearchInput = productSearchInput.toLowerCase();
    const filteredProducts = productsCopy.filter(
      (product) =>
        product?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
        product?.batchIndex === lowerCaseSearchInput ||
        product?.upcIndex === lowerCaseSearchInput ||
        product?.value === lowerCaseSearchInput ||
        product?.upc === lowerCaseSearchInput
    );
    if (filteredProducts.length === 0) {
      {
        message.info(`${t("product_search_category_error")}`);
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedProductCategory !== "all") {
      getCategoryProducts();
    }
  }, [selectedProductCategory]);

  const getMoreProducts = () => {
    if (productSearchInput === "") {
      getCategoryProducts(selectedProductCategory);
    }
  };

  const getCategoryProducts = () => {
    // if (selectedProductCategory === "allProducts") {
    //   db.products.toArray().then((productsFetched) => {
    //     const additionalProductsFetched = productsList.concat(productsFetched);
    //     additionalProductsFetched.map((ele) => {
    //       cart.items.map((item) => {
    //         if (ele.mProductId === item.productId) {
    //           ele.selected = "Y";
    //         }
    //       });
    //     });
    //     setProductsList([...additionalProductsFetched.slice(0, 50)]);
    //     setProductsCopy([...additionalProductsFetched]);
    //   });
    // } else {
    //   db.products
    //     .where("mProductCategoryId")
    //     .equalsIgnoreCase(selectedProductCategory)
    //     .offset(productsList.length)
    //     .limit(50)
    //     .toArray()
    //     .then((productsFetched) => {
    //       const additionalProductsFetched = productsList.concat(productsFetched);
    //       setProductsList([...additionalProductsFetched]);
    //     });
    // }
  };
  // PRODUCT FILTER END

  // PRODUCT SEARCH START
  const [isSearchProducts, setIsSearchProducts] = useState(() =>
    posConfig?.defaultSearchScreen === "Product Search" || posConfig?.defaultSearchScreen === "Category Search" || posConfig?.defaultSearchScreen === undefined ? true : false
  );
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [numb, setNumb] = useState(0);
  const [qtyNumberFlag, setQtyNumberFlag] = useState(0);

  const getSearchedProducts = () => {
    db.products
      .filter(
        (product) =>
          (typeof product.name === "string" && product.name.toLowerCase().includes(productSearchInput.toLowerCase())) ||
          (typeof product.value === "string" && product.value.toLowerCase() === productSearchInput.toLowerCase())
      )
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          setProductsData(productsFetched);
          setIsProductsVisible(true);
        } else if (productsFetched.length === 1) {
          const obj = productsFetched[0];

          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            if (Array.isArray(obj.mBatch) && obj.mBatch.length > 0) {
              const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === productSearchInput.toLowerCase());

              if (batchProductIndex >= 0 && obj.multiPrice === "N") {
                const batchItem = obj.mBatch[batchProductIndex];
                addDefinedProduct(obj, batchItem.upc || "", batchItem.batchno || "", batchItem.mBatchId || "", batchItem.price || 0);
                setNumb(0);
                paymentModalInputRef.current?.focus();
              } else if (obj.mBatch.length === 1) {
                const batchItem = obj.mBatch[0];
                addDefinedProduct(obj, batchItem.upc || "", batchItem.batchno || "", batchItem.mBatchId || "", batchItem.price || 0);
                paymentModalInputRef?.current?.focus();
                setNumb(0);
              } else {
                const productSet = [];
                const localObj = obj;

                for (let i = 0; i < obj.mBatch.length; i += 1) {
                  const batchItem = { ...localObj.mBatch[i] };
                  const newObj = { ...localObj };

                  if (newObj.overRideTax === "Y" && batchItem.price <= newObj.overRideCondition) {
                    const originalPrice = batchItem.price - (batchItem.price - batchItem.price * (100 / (100 + newObj.taxRate)));
                    const taxedPrice = originalPrice + (originalPrice * newObj.contraRate) / 100;
                    batchItem.price = taxedPrice;
                    newObj.cTaxId = newObj.contraTaxId;
                    newObj.taxRate = newObj.contraRate;
                  }

                  const productDefined = {
                    batchno: batchItem.batchno || null,
                    description: newObj.description || "",
                    discount: 0,
                    discountName: "",
                    imageurl: newObj.imageurl || "",
                    isDecimal: newObj.isDecimal || false,
                    isManualQty: newObj.isManualQty || false,
                    isPromoApplicable: newObj.isPromoApplicable || false,
                    isReturn: false,
                    mBatchId: batchItem.mBatchId || null,
                    mPricingruleId: null,
                    name: newObj.name || "",
                    name2: newObj.name2 || "",
                    nettotal: 0,
                    primaryOrderLine: null,
                    productId: newObj.mProductId || null,
                    realPrice: newObj.sunitprice || 0,
                    listPrice: newObj.slistprice || 0,
                    sunitprice: newObj.sunitprice || 0,
                    returnQty: null,
                    salePrice: newObj.sunitprice || 0,
                    originalPrice: newObj.sunitprice || 0,
                    productSegment: newObj?.productSegment || "",
                    mrpPrice: batchItem.listPrice || 0,
                    stock: newObj.onhandQty || 0,
                    taxCategory: newObj.taxCategory || "",
                    taxName: newObj?.taxName || "",
                    taxFlag: newObj?.taxFlag || "N",
                    tax: newObj.cTaxId || null,
                    taxAmount: 0,
                    taxRate: newObj.taxRate || 0,
                    uom: newObj.csUomId || null,
                    customAttributes: newObj.customAttributes || {},
                    type: newObj.type || "",
                    addNewLine: newObj?.addNewLine || "N",
                    newCustomAttributes: [],
                    priceList: newObj?.priceList || [],
                    selectedAddons: [],
                    uom_name: newObj.uomName || "",
                    isDecimalQty: newObj?.uomData?.[0]?.decimal === "Y" || false,
                    isQtyDesimal: newObj?.uomData?.[0]?.stdprecision || 2,
                    upc: batchItem.upc || null,
                    value: newObj.value || null,
                    weight: 0,
                    shortDescription: newObj.shortDescription || "",
                    hsncode: newObj.hsncode || null,
                    csBunitId: newObj.csBunitId || null,
                    mProductCategoryId: newObj.mProductCategoryId || null,
                    productManufacturerId: newObj.productManufacturerId || null,
                    productBrandId: newObj.productBrandId || null,
                    batchedProduct: newObj.batchedProduct || "N",
                    batchedForSale: newObj.batchedForSale || "N",
                    batchedForStock: newObj.batchedForStock || "N",
                    multiPrice: newObj.multiPrice || "N",
                    shelfLife: newObj.shelfLife || "",
                  };

                  productSet.push(productDefined);
                }

                setBatchSetAvailable([...productSet]);
                setDisplayBatchSelection(true);
              }
            }
          } else {
            addDefinedProduct(obj, obj.upc || "", null, null, obj.sunitprice || 0);
            paymentModalInputRef?.current?.focus();
            setProductSearchInput("");
            if (productSearchInputRef?.current) {
              productSearchInputRef?.current?.clearInput();
            }
            setNumb(0);
          }
        } else {
          message.warning(`${t("product_search_error")}`);
        }
      });
  };

  const kioskFilteredProducts = (filter) => {
    db.products
      .limit(100)
      .toArray()
      .then((productsFetched) => {
        if (productsFetched.length > 1) {
          let filteredData = [];
          productsFetched.forEach((item) => {
            const itemName = item.name[0].toUpperCase();

            if (filter.includes("-")) {
              const filters = filter.split("-");
              const start = filters[0];
              const end = filters[filters.length - 1];

              if (itemName >= start && itemName <= end) {
                filteredData.push(item);
              }
            } else if (itemName === filter) {
              filteredData.push(item);
            }
          });

          setIsProductsVisible(true);

          if (filter === "All") {
            // setProductsList([...productsFetched]);
          } else {
            // setProductsList([...filteredData]);
          }
        }
      });
  };

  const getSearchedItem = () => {
    db.products
      .where("name")
      .startsWithIgnoreCase(productSearchInput)
      .or("batchIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("upcIndex")
      .equalsIgnoreCase(productSearchInput)
      .or("value")
      .equalsIgnoreCase(productSearchInput)
      .limit(100)
      .toArray()
      .then((product) => {
        if (product.length > 0) {
          const obj = product[0] || {};
          const mBatch = obj.mBatch || [];
          const uomData = obj.uomData || [{}];
          const defaultPrice = obj.sunitprice || 0;

          if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
            const batchProductIndex = mBatch.findIndex((bp) => bp.batchno?.toLowerCase() === (productSearchInput || "").toLowerCase());
            if (batchProductIndex >= 0 && obj.multiPrice === "N") {
              addDefinedProduct(
                obj,
                mBatch[batchProductIndex]?.upc || "",
                mBatch[batchProductIndex]?.batchno || "",
                mBatch[batchProductIndex]?.mBatchId || "",
                mBatch[batchProductIndex]?.price || 0
              );
            } else if (mBatch.length === 1) {
              addDefinedProduct(obj, mBatch[0]?.upc || "", mBatch[0]?.batchno || "", mBatch[0]?.mBatchId || "", mBatch[0]?.price || 0);
            } else {
              const productSet = [];
              for (let i = 0; i < mBatch.length; i++) {
                const batchItem = { ...mBatch[i] };
                const productDefined = {
                  batchno: batchItem.batchno || null,
                  description: obj.description || "",
                  discount: 0,
                  discountName: "",
                  imageurl: obj.imageurl || "",
                  isDecimal: obj.isDecimal || false,
                  isManualQty: obj.isManualQty || false,
                  isPromoApplicable: obj.isPromoApplicable || false,
                  isReturn: false,
                  mBatchId: batchItem.mBatchId || null,
                  mPricingruleId: null,
                  name: obj.name || "",
                  name2: obj.name2 || "",
                  nettotal: 0,
                  primaryOrderLine: null,
                  productId: obj.mProductId || null,
                  realPrice: obj.sunitprice || 0,
                  listPrice: obj.slistprice || 0,
                  sunitprice: obj.sunitprice || 0,
                  originalPrice: obj.sunitprice || 0,
                  productSegment: obj?.productSegment || "",
                  returnQty: null,
                  salePrice: obj.sunitprice || 0,
                  mrpPrice: batchItem.listPrice || 0,
                  stock: obj.onhandQty || 0,
                  taxCategory: obj.taxCategory || "",
                  taxName: obj?.taxName || "",
                  taxFlag: obj?.taxFlag || "N",
                  tax: obj.cTaxId || null,
                  taxAmount: 0,
                  taxRate: obj.taxRate || 0,
                  uom: obj.csUomId || null,
                  customAttributes: obj.customAttributes || {},
                  type: obj.type || "",
                  addNewLine: obj.addNewLine || "N",
                  newCustomAttributes: [],
                  priceList: obj?.priceList || [],
                  selectedAddons: [],
                  uom_name: obj.uomName || "",
                  isDecimalQty: uomData[0]?.decimal === "Y" || false,
                  isQtyDesimal: uomData[0]?.stdprecision || 2,
                  upc: batchItem.upc || null,
                  value: obj.value || null,
                  weight: 0,
                  shortDescription: obj.shortDescription || "",
                  hsncode: obj.hsncode || null,
                  csBunitId: obj.csBunitId || null,
                  mProductCategoryId: obj.mProductCategoryId || null,
                  productManufacturerId: obj.productManufacturerId || null,
                  productBrandId: obj.productBrandId || null,
                  batchedProduct: obj.batchedProduct || "",
                  batchedForSale: obj.batchedForSale || "",
                  batchedForStock: obj.batchedForStock || "",
                  multiPrice: obj.multiPrice || "",
                  shelfLife: obj.shelfLife || "",
                };
                productSet.push(productDefined);
              }
              setBatchSetAvailable([...productSet]);
              setDisplayBatchSelection(true);
            }
          } else {
            addDefinedProduct(obj, obj.upc || "", null, null, defaultPrice);
          }
        } else {
          message.warning(`${t("product_search_error")}`);
        }
      });
  };

  const clearProductSearchResults = () => {
    setProductSearchInput("");
  };

  const productListCardRef = useRef(null);

  const closeProductPanel = () => {
    const productSearchInput = document.getElementById("sm-product-search");
    productSearchInput.focus();
    setIsSearchProducts(false);
    setIsProductsVisible(false);
    setIsProductsFilter(false);
    setProductSearchInput("");
    clearProductSearchResults();
    setProductsData([]);
  };
  // PRODUCT SEARCH END

  const cartRef = useRef();
  useEffect(() => {
    let cartObj = JSON.parse(localStorage.getItem("cartObj")) ? JSON.parse(localStorage.getItem("cartObj")) : cart;
    cartRef.current = { ...cart };
    if (cart.items.length === 0) {
      cart.couponInput = [];
      cart.discount = 0;
      cart.payments = [];
      cart.paid = 0;
      cart.giftCardRefId = null;
      cart.totalBillDicount = null;
      setOverPayedAmount(0);
      setSelectedProductInCart({});
      setShowPaymentMethods(false);
      setCart(cart);
    }
    let PaymentMethods = getFilteredPaymentMethods() || [];
    const advanceItem = cart.items.find((item) => item.type === "S" && item.weight > 0);
    if (cart.layAway === "Y" && cart?.docType === "Advance" && !advanceItem) {
      PaymentMethods = [
        ...PaymentMethods,
        {
          cWRPaymentMethodID: "",
          sequenceNo: 9999,
          finPaymentmethodId: "",
          finFinancialAccountId: "",
          finDayCloseAccountId: null,
          name: "Complete Order",
          integratedPayment: false,
          isloyalty: false,
          paymentProvider: null,
          iscredit: false,
          isGiftCard: false,
          isDefault: "N",
          csCurrencyId: "",
          isoCode: "AED",
          isactive: true,
          salesType: [],
        },
      ];
      let payments =
        PaymentMethods.length > 5
          ? _.sortBy(PaymentMethods, "sequenceNo").slice(
              0,
              tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && PaymentMethods.length === 5 ? 5 : cart.layAway === "Y" ? 4 : 5
            )
          : PaymentMethods;

      setShowPayments(payments);
      setStartIndex(cart.layAway === "Y" && payments.length >= 5 ? 5 : payments.length);
    }
    adjustDiscount(cartObj);
  }, [cart]);

  const adjustDiscount = (cartObj) => {
    if (cartObj.items.length > 0 && cartObj.total > 0) {
      let cartTotal = cartObj.items.reduce((sum, item) => sum + item.nettotal + item.discount, 0);

      let newCartTotal = parseFloat(cartObj.total.toFixed(2)) + parseFloat(cartObj.discount.toFixed(2));

      if (parseFloat(newCartTotal.toFixed(2)) !== parseFloat(cartTotal.toFixed(2))) {
        let adjustAmount = newCartTotal - cartTotal;

        let lastDiscountedItemIndex = [...cartObj.items].reverse().findIndex((item) => item.discount > 0);
        if (lastDiscountedItemIndex !== -1) {
          let indexToUpdate = cartObj.items.length - 1 - lastDiscountedItemIndex;

          let updatedItems = [...cartObj.items];
          updatedItems[indexToUpdate].discount = parseFloat((updatedItems[indexToUpdate].discount - adjustAmount).toFixed(2));
          updatedItems[indexToUpdate].nettotal = parseFloat((updatedItems[indexToUpdate].nettotal + adjustAmount).toFixed(2));

          let updatedCart = {
            ...cartObj,
            items: updatedItems,
            discount: cartObj.discount - adjustAmount,
          };

          setCart(updatedCart);
          localStorage.setItem("cartObj", JSON.stringify(updatedCart));
        }
      }
    }
  };

  // dynamic pop up modal start
  const [dynamicForm, setDynamicForm] = useState(false);
  const [dynamicModalForm] = Form.useForm();

  const submitCustomAttributs = (prod) => {
    const productObj = { ...prod };

    addProductToCart(
      productObj,
      productObj.qty,
      false,
      cart,
      setCart,
      setSelectedProductInCart,
      deleteCart,
      processTotalManualDiscount,
      setLoader,
      salesRepresentDefaultLine,
      tillData,
      cartRef,
      productsCopy,
      salesRepresent,
      orderType,
      processBillDiscounts,
      undefined,
      setSelectedRowKeys,
      setOrderTimeDetails,
      setDisplayAddOnSelection,
      displayAddOnSelection,
      handleAddOnModal,
      manualDiscountTypes,
      CheckoutFlatDiscount,
      CheckoutPercentageDiscount,
      CheckoutTotalManualDiscount,
      setProductStock
    );
    setSelectedAddons([]);
  };

  const addProduct = async (addToCart, qty, totalQtyFlag, modifiedPrice) => {
    setStockList([]);
    const cart = JSON.parse(localStorage.getItem("cartObj")) || { ...cartRef.current };

    // if (cart.layAway === "Y") {
    //   message.info("This order cannot be edited!");
    //   return;
    // }

    if (Object.keys(addToCart).length === 0) {
      return;
    }

    if (!("isBlindReceipt" in cart) || (cart.isBlindReceipt && addToCart.isGiftCard)) {
      // Do nothing
    } else {
      setShowPaymentMethods(false);
    }

    setProductSearchInput("");

    let freeFlag = true;
    if (addToCart.isFree) {
      const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(addToCart.mPricingruleId).toArray();
      const matchingProductIndex = matchingPricingRules[0]?.mPricingXProducts.find((op) => op.mProductId === addToCart.productId);
      freeFlag = matchingProductIndex && addToCart.weight + qty <= matchingProductIndex.quantity;
    }

    const findItemIndex = (condition) =>
      cart?.items?.findIndex(
        (p) =>
          p.productId === addToCart.productId &&
          p.upc === addToCart.upc &&
          p.mBatchId === addToCart.mBatchId &&
          p.isReturn === false &&
          p.lineId === addToCart.lineId &&
          condition(p)
      );

    const addonIndex = findItemIndex((p) => p?.selectedAddons?.length > 0);
    const attributeIndex = findItemIndex((p) => p?.newCustomAttributes?.length > 0);

    let addOnFlag = false;

    if (addToCart?.productAddons?.length > 0 && addToCart.weight + qty !== 0 && qty > 0) {
      if (addonIndex !== -1) {
        addOnFlag = true;
      } else {
        setDisplayAddOnSelection(true);
        handleAddOnModal(addToCart, qty);
      }
    }

    if (addToCart?.customAttributes?.length > 0 && addToCart.weight + qty !== 0 && qty > 0) {
      if (attributeIndex !== -1) {
        addOnFlag = true;
      } else {
        setDynamicForm(true);
        addToCart.qty = qty;
        setSelectedProductInCart(addToCart);
      }
    }

    if (
      freeFlag &&
      (addToCart?.productAddons?.length <= 0 || addToCart.weight + qty === 0 || qty < 0 || addOnFlag) &&
      (addToCart?.customAttributes?.length <= 0 || addToCart.weight + qty === 0 || qty < 0 || addOnFlag)
    ) {
      await addProductToCart(
        addToCart,
        qty,
        totalQtyFlag,
        cart,
        setCart,
        setSelectedProductInCart,
        deleteCart,
        processTotalManualDiscount,
        setLoader,
        salesRepresentDefaultLine,
        tillData,
        cartRef,
        productsCopy,
        salesRepresent,
        orderType,
        processBillDiscounts,
        modifiedPrice,
        setSelectedRowKeys,
        setOrderTimeDetails,
        setDisplayAddOnSelection,
        displayAddOnSelection,
        handleAddOnModal,
        manualDiscountTypes,
        CheckoutFlatDiscount,
        CheckoutPercentageDiscount,
        CheckoutTotalManualDiscount,
        setProductStock,
        setTableCards
      );
    }
  };

  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);
  const [orderDelay, setOrderDelay] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [activeOrderProcess, setActiveOrderProcess] = useState(false);
  const orderState = useRef(0);

  const processOrder = (param) => {
    orderState.current += 1;
    if (orderState.current === 1 && cart.items.length !== 0) {
      processOrderApproved(param);
    } else {
      setPaymentModal(false);
    }
  };

  const processOrderApproved = async (param, obj) => {
    setSelectedPaymentMethod({});
    if (!activeOrderProcess) {
      setOverPayedAmount(0);
      setAmount(0);
      setActiveOrderProcess(true);
      const cartToDb = typeof obj === "object" ? obj : cart;
      const newDocumentSequence = documentSequence + 1;

      const tillSession = JSON.parse(localStorage.getItem("tillSession"));
      const tillSessionId = tillSession?.tillSessionId || null;

      if (param !== "layaway") {
        if (cart.layAway === "Y") {
          cartToDb.layAway === "Y";
        } else {
          cartToDb.layAway = "N";
        }
        const change = cartToDb.paid - Math.abs(cartToDb.total);

        if (cart.items.filter((it) => it.isReturn === true).length > 0 && cartToDb.total === 0) {
          cartToDb.change = 0;
          let paymentType = selectedPaymentMethod.name.toLowerCase() === "cash" ? "cash" : selectedPaymentMethod.name.toLowerCase() === "card" ? "card" : "cash";
          const pMindex = tillDataPaymentMethods.findIndex((pi) => pi.name.toLowerCase() === paymentType);
          cartToDb.payments.push({ ...tillDataPaymentMethods[pMindex], amount: 0 });
        } else {
          cartToDb.change = parseFloat(cartToDb.change.toFixed(2));
        }
        // console.log(cPi,"====>cPi")
        if (cart.isReturn === true || cart.isReturn === "Y") {
          cartToDb.payments.findIndex((pi) => {
            pi.amount = pi.amount * -1;
          });
        } else {
          // const updatedCashAmt = parseFloat(cartToDb.payments[0].amount) - change;
          // cartToDb.payments[0].amount = `${updatedCashAmt}`;
        }
      } else {
        cartToDb.layAway = "Y";
        if (selectedPaymentMethod?.name) {
          let payments = [...cartToDb.payments]; // Clone the payments array
          const pMindex = payments.findIndex((pi) => pi.name.toLowerCase() === selectedPaymentMethod.name.toLowerCase());

          if (pMindex !== -1) {
            // Update the amount for the existing payment method
            payments[pMindex] = {
              ...payments[pMindex], // Clone the existing payment method object
              amount: payments[pMindex].amount + amount, // Correctly update the amount
            };
          } else {
            // Add the new payment method if not found
            payments.push({ ...selectedPaymentMethod, amount: amount });
          }

          cartToDb.payments = payments; // Update the cartToDb payments
        }
      }

      if (cartToDb.customer?.loyaltyLevel?.cwrLoyaltyLevelId) {
        let loyaliryData = await db.loyalityData.toArray();
        loyaliryData.forEach((loyality) => {
          if (loyality.loyaltylevelId === cartToDb.customer.loyaltyLevel?.cwrLoyaltyLevelId) {
            const eventCalenders = loyality.loyaltyEventCalenders;
            const hasValidEventCalenders = eventCalenders?.length > 0 ? dateValidator(eventCalenders[0].startDate, eventCalenders[0].endDate) : true;
            // const accumulationLength = loyality.LoyaltyAccumulation?.length;
            loyality.LoyaltyAccumulation?.forEach((item) => {
              const productValidation = item.mProductId && item.mProductId !== "" ? cart.items.some((pc) => pc.mProductId === item.mProductId) : true;
              const categoryValidation =
                item.mProductCategoryId && item.mProductCategoryId !== "" ? cart.items.some((pc) => pc.mProductCategoryId === item.mProductCategoryId) : true;
              const checkEventsCalender =
                item.loyaltyEvent === "Y" && eventCalenders?.length > 0
                  ? eventCalenders.some((i) => dateValidator(i.startDate, i.endDate))
                  : item.loyaltyEvent === "Y" && (!eventCalenders || eventCalenders.length === 0);

              if (
                item.loyaltyEvent === "Y" &&
                item.minPurchase <= Math.abs(cart.total) &&
                productValidation &&
                categoryValidation &&
                hasValidEventCalenders &&
                checkEventsCalender
              ) {
                cartToDb.accumulationPoints = parseFloat((item.pointsPerUnit * (cart.total - cart.tax)).toFixed(3));
              } else if (item.loyaltyEvent === "N" && item.minPurchase <= Math.abs(cart.total) && productValidation && categoryValidation) {
                cartToDb.accumulationPoints = parseFloat((item.pointsPerUnit * (cart.total - cart.tax)).toFixed(3));
              }
            });
          }
        });
      }
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails.orderEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      cartToDb.orderTime = timeStamp();
      cartToDb.createdBy = tillData.tillAccess.csUserId;
      cartToDb.documentno = cart?.isRetrived === "Y" || cart?.type === "Layaway" ? cart.documentno : `${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}`;
      cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId;
      cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
      cartToDb.tillSessionId = tillSessionId;
      cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
      cartToDb.isSynced = 0;
      cartToDb.syncAttempts = 0;
      cartToDb.giftCardData = giftCardData;
      cartToDb.roundOff = parseFloat((cart.isReturn === true || cart.isReturn === "Y" ? cartToDb.roundOff * -1 : cartToDb.roundOff).toFixed(precision));
      cartToDb.discount = parseFloat(cartToDb?.discount?.toFixed(precision));
      cartToDb.customerSearchKey = cartToDb?.customer?.code;
      // cartToDb.customer.retlLoyaltyBalance = (cartToDb?.customer?.retlLoyaltyBalance || 0) + (cartToDb?.accumulationPoints || 0);
      cartToDb.customer && (cartToDb.customer.retlLoyaltyBalance = (cartToDb?.customer?.retlLoyaltyBalance || 0) + (cartToDb?.accumulationPoints || 0));
      cartToDb.orderTimeDetails = orderTimeDetails;
      cartToDb.totalQty = parseFloat(cartToDb?.totalQty?.toFixed(precision));
      cartToDb.dineIn = localStorage.getItem("dineIn") ? localStorage.getItem("dineIn") : "Y";
      cartToDb.typeOrder = localStorage.getItem("homeDelivery") ? localStorage.getItem("homeDelivery") : "N";
      cartToDb.statusType = JSON.parse(localStorage.getItem("cartObj"))?.statusType || "N";
      cartToDb.orderCompleted = cartToDb.statusType === "layAway" ? false : true;
      cartToDb.overPayedAmount = overPayedAmount || 0;
      cartToDb.items = cartToDb.items.map((item, i) => {
        const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
        const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
        const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

        // Update individual item properties
        item.discount = discountFixed;
        item.key = i;
        item.nettotal = parseFloat(item.nettotal.toFixed(precision));

        if (!item.isGiftCard) {
          let lineTax = item.listPrice * (1 + item.taxRate / 100);
          let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
          if (!isFinite(unitPrice)) unitPrice = 0;

          let unitTax = item.taxAmount / item.weight;
          item.sunitprice = taxIncludeFlag === "Y" ? item.sunitprice : parseFloat((item.sunitprice + unitTax).toFixed(precision));
          const gross_list = taxIncludeFlag === "Y" ? item.listPrice : item.listPrice * (1 + item.taxRate / 100);
          const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
          const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;
          item.listPrice = taxIncludeFlag === "Y" ? item.listPrice : parseFloat(lineTax.toFixed(precision));
          item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
          item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
          item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
          item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
          item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
          item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
          item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
          item.grosslist = Math.abs(nettotalFixed) > 0 ? gross_list : 0;
          item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
          item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
          item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        }
        return item;
      });

      let taxDetails = [];

      const combineTaxRates = (items) => {
        // Create an object to store tax rates as keys and total tax amounts as values
        const taxMap = {};

        // Loop through each item to calculate total tax amounts
        items.forEach((item) => {
          if (!item.isGiftCard) {
            let { taxRate, taxAmount, nettotal, tax, cardNo, taxName, taxCategory } = item;
            nettotal = nettotal - taxAmount;
            if (taxMap[taxRate]) {
              // If the tax rate already exists, add the tax amount and nettotal to the existing total
              taxMap[taxRate].taxAmount += parseFloat(taxAmount.toFixed(precision));
              taxMap[taxRate].nettotal += parseFloat(nettotal.toFixed(precision));
            } else {
              // If the tax rate doesn't exist, create a new entry
              taxMap[taxRate] = {
                taxAmount: parseFloat(taxAmount.toFixed(precision)),
                nettotal: parseFloat(nettotal.toFixed(precision)),
                tax: tax,
                taxRate: taxRate,
                taxCategory: taxCategory,
                taxName: taxName,
              };
            }
          }
        });

        // Create a new array from the taxMap object
        const combinedTaxRates = Object.keys(taxMap).map((taxRate) => {
          taxDetails.push({
            csTaxID: taxMap[taxRate].tax,
            taxableAmt: parseFloat(taxMap[taxRate].taxAmount.toFixed(precision)),
            taxAmt: parseFloat(taxMap[taxRate].nettotal.toFixed(precision)),
            taxRate: taxMap[taxRate].taxRate,
            taxCategory: taxMap[taxRate].taxCategory,
            taxName: taxMap[taxRate].taxName,
          });
          return {
            csTaxID: taxMap[taxRate].tax,
            taxableAmt: parseFloat(taxMap[taxRate].nettotal.toFixed(precision)),
            taxAmt: parseFloat(taxMap[taxRate].taxAmount.toFixed(precision)),
          };
        });

        return combinedTaxRates;
      };
      cartToDb.taxDetails = taxDetails;
      cartToDb.combinedTaxRates = combineTaxRates(cart.items);
      localStorage.removeItem("orderTimeDetails");
      setGiftCardData([]);
      setShowPayments(initialShowPayments);

      await db.orders
        .where("tillSessionId")
        .equals(tillSessionId)
        .toArray(async (orders) => {
          let newOrder = initializeOrder();
          let orderExists = false;

          if (orders.length > 0) {
            orders = await Promise.all(
              orders.map(async (order) => {
                if (order.sOrderID === cartToDb.sOrderID) {
                  orderExists = true;
                  return { ...cartToDb };
                }
                return order;
              })
            );

            if (!orderExists) {
              orders.push(cartToDb);
            }

            for (const order of orders) {
              await processOrderData(order, newOrder);
            }
          } else {
            await processOrderData(cartToDb, newOrder);
          }

          await db.ordersData
            .where("tillSessionId")
            .equals(tillSessionId)
            .modify((order) => {
              Object.assign(order, {
                tillSessionId,
                tillStatus: "open",
                ...newOrder,
              });
            });
        });

      // Helper functions
      function initializeOrder() {
        return {
          salesTax: [],
          returnTax: [],
          items: [],
          netTotal: 0,
          grossTotal: 0,
          totalTax: 0,
          netReturnTotal: 0,
          grossReturnTotal: 0,
          totaReturnlTax: 0,
          returnsTransactions: 0,
          salesTransactions: 0,
        };
      }

      async function processOrderData(orderDetails, newOrder) {
        const groupedItems = groupItems(newOrder.items, orderDetails.items);
        const targetTaxArray = orderDetails.isReturn ? newOrder.returnTax : newOrder.salesTax;

        await updateTaxDetails(targetTaxArray, orderDetails.taxDetails);
        await updateOrderTotals(newOrder, orderDetails, targetTaxArray);

        newOrder.items = groupedItems;
      }

      function groupItems(existingItems, newItems) {
        const groupedItems = [...existingItems];

        newItems.forEach((item) => {
          const index = groupedItems.findIndex((ele) => ele.value === item.value);
          if (index >= 0) {
            // Update existing item
            const itemWeight = parseFloat(groupedItems[index].weight) + parseFloat(item.weight);
            groupedItems[index] = {
              ...groupedItems[index],
              taxAmount: +(groupedItems[index].taxAmount + item.taxAmount).toFixed(precision),
              nettotal: +(groupedItems[index].nettotal + item.nettotal).toFixed(precision),
              weight: itemWeight.toFixed(item?.isQtyDesimal || 2),
            };
          } else {
            // Add new item
            groupedItems.push({
              taxAmount: +item.taxAmount.toFixed(precision),
              nettotal: +item.nettotal.toFixed(precision),
              weight: parseFloat(parseFloat(item.weight).toFixed(item?.isQtyDesimal || 2)),
              value: item.value,
              name: item.name,
            });
          }
        });

        return groupedItems;
      }

      function updateTaxDetails(targetTaxArray, taxDetails) {
        taxDetails?.forEach((newTax) => {
          const existingTax = targetTaxArray.find((tax) => tax.taxRate === newTax.taxRate);

          if (existingTax) {
            const taxAmount = parseFloat(existingTax.taxAmt) + parseFloat(newTax.taxAmt);
            const taxableAmount = parseFloat(existingTax.taxableAmount) + parseFloat(newTax.taxableAmount);
            existingTax.taxAmt = +taxAmount.toFixed(precision);
            existingTax.taxableAmt = +taxableAmount.toFixed(precision);
          } else {
            targetTaxArray.push({
              csTaxID: newTax.csTaxID,
              taxableAmt: parseFloat(parseFloat(newTax.taxableAmt || 0).toFixed(precision)),
              taxAmt: parseFloat(parseFloat(newTax.taxAmt || 0).toFixed(precision)),
              taxRate: newTax.taxRate,
              taxCategory: newTax.taxCategory,
              taxName: newTax.taxName,
            });
          }
        });
      }

      async function updateOrderTotals(order, orderDetails, targetTaxArray) {
        const isReturn = orderDetails.isReturn === true || orderDetails.isReturn === "Y";
        const allOrders = await db.orders.toArray();

        const orderIndex = allOrders.findIndex((o) => o.sOrderID === orderDetails.sOrderID);

        if (isReturn) {
          order.returnTax = targetTaxArray;

          const netReturnTotal = parseFloat(order.netReturnTotal ?? 0) + (parseFloat(orderDetails.total ?? 0) - parseFloat(orderDetails.tax ?? 0));
          const grossReturnTotal = parseFloat(order.grossReturnTotal ?? 0) + parseFloat(orderDetails.total ?? 0);
          const totalReturnTax = parseFloat(order.totaReturnlTax ?? 0) + parseFloat(orderDetails.tax ?? 0);

          order.netReturnTotal = +netReturnTotal.toFixed(precision);
          order.grossReturnTotal = +grossReturnTotal.toFixed(precision);
          order.totaReturnlTax = +totalReturnTax.toFixed(precision);
          order.returnsTransactions += 1;

          upsertPOSLog(orderDetails, "SLR");
        } else {
          order.salesTax = targetTaxArray;

          const netTotalValue = parseFloat(orderDetails.total ?? 0) - parseFloat(orderDetails.tax ?? 0);
          const grossTotalValue = parseFloat(order.grossTotal ?? 0) + parseFloat(orderDetails.total ?? 0);
          const totalTaxValue = parseFloat(order.totalTax ?? 0) + parseFloat(orderDetails.tax ?? 0);

          order.netTotal = +(parseFloat(order.netTotal ?? 0) + netTotalValue).toFixed(precision);
          order.grossTotal = +grossTotalValue.toFixed(precision);
          order.totalTax = +totalTaxValue.toFixed(precision);
          order.salesTransactions += 1;
        }

        if (orderIndex !== -1) {
          const advancePayment = parseFloat(orderDetails.advancePayment ?? 0);

          order.netTotal = +(parseFloat(order.netTotal ?? 0) - advancePayment).toFixed(precision);
          order.grossTotal = +(parseFloat(order.grossTotal ?? 0) - advancePayment).toFixed(precision);
        }
      }

      db.paymentsData
        .where("tillSessionId")
        .equals(tillSessionId)
        .modify((event) => {
          let paymentsData = [];
          // event.payments.forEach((payment) => {
          const filteredPayments = event.payments.filter((payment) => payment?.cwrTillId === tillId);
          filteredPayments.forEach((payment) => {
            let currPaymentAmount = 0;
            let transactionCount = 0;
            let change = 0;
            let currPaymentReturn = 0;
            change += cart.change;
            // cart.payments.forEach((orderPayment) => {
            cart?.payments?.flat().forEach((orderPayment) => {
              if (payment.name.toLowerCase() === orderPayment.name.toLowerCase() && cart.total > 0) {
                payment.amount = payment.amount ? payment.amount : 0 + parseFloat(orderPayment.amount);
                payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + 1;
                currPaymentAmount += parseFloat(orderPayment.amount);
              } else if (payment.name.toLowerCase() === orderPayment.name.toLowerCase() && cart.total < 0) {
                payment.paymentReturn = payment.paymentReturn ? payment.paymentReturn : 0 + parseFloat(Math.abs(orderPayment.amount));
                payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + 1;
                currPaymentReturn += parseFloat(Math.abs(orderPayment.amount));
              }
            });
            payment.expectedAmount = payment.expectedAmount ? payment.expectedAmount : 0 + currPaymentAmount;
            payment.amount = payment.amount ? payment.amount : 0 + currPaymentAmount;
            payment.paymentReturn = payment.paymentReturn ? payment.paymentReturn : 0 + currPaymentReturn;
            payment.difference = payment.difference ? payment.difference : 0 + payment.expectedAmount;
            payment.transactionCount = payment.transactionCount ? payment.transactionCount : 0 + transactionCount;
            payment.change = payment.change ? payment.change : 0 + change;
            paymentsData.push(payment);
          });

          event.payments = paymentsData;
        });
      setSelectedDocumentType("Invoice");
      setPaymentProcessFlag(false);
      if (cart.type !== "Layaway") {
        try {
          await db.orders.add(cartToDb);
          setPaymentModal(false);
          let takeAway = localStorage.getItem("dineIn");
          if (takeAway === "Y") {
            let tableData = JSON.parse(localStorage.getItem("tableName"));
            await db.tableData
              .where("cwrFbTableId")
              .equals(tableData.cwrFbTableId)
              .toArray()
              .then(async (response) => {
                if (response.length > 0) {
                  // let finalData = {...response[0]};
                  response[0].salesHistory = "Y";
                  response[0].fbOrderSync = "N";
                  response[0].tableSync = "N";
                  response[0].statusType = "OPN";
                  response[0].fbOrderStatus = "CO";
                  response[0].color = "#a7c957";
                  response[0].ordered = "N";
                  let obj;
                  await db.fbOrderData
                    .where("cwrFbTableId")
                    .equals(tableData?.cwrFbTableId)
                    .toArray()
                    .then((ordersFetched) => {
                      if (ordersFetched.length > 0) {
                        ordersFetched.map(async (fbOrder) => {
                          if (fbOrder.fbOrderStatus === "IP") {
                            fbOrder.salesHistory = "Y";
                            fbOrder.fbOrderSync = "N";
                            fbOrder.tableSync = "N";
                            fbOrder.statusType = "OPN";
                            fbOrder.fbOrderStatus = "CO";
                            fbOrder.color = "#a7c957";
                            fbOrder.sOrderID = cart.sOrderID;
                          }
                          obj = {
                            fbOrderId: fbOrder.fbOrderId,
                            order: fbOrder,
                          };
                          await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                        });
                      }
                    });
                  await db.tableData.put(response[0], response[0].cwrFbTableId);
                  SyncData(response[0], "upsertTableStatus");
                  SyncData(response[0], "upsertFbOrder");
                  const orderData = {
                    tableDetails: {
                      cwrFbTableId: response[0].cwrFbTableId,
                      data: response[0],
                    },
                    fbOrder: obj,
                  };
                  // sendOrder(orderData);
                }
              });
          }
          localStorage.setItem("cartObj", []);
          if (cart?.isRetrived !== "Y") {
            localStorage.setItem("documentSequence", newDocumentSequence);
            setDocumnetSequence(newDocumentSequence);
            deleteCart();
          } else {
            let cartObj = {
              items: [],
              couponInput: [],
              advancePayment: 0,
              total: 0,
              tax: 0,
              discount: 0,
              paid: 0,
              change: 0,
              totalQty: 0,
              roundOff: 0,
              payments: [],
              redemptionPoints: 0,
              accumulationPoints: 0,
              creditAmount: 0,
              docType: "Invoice",
              sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
              referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
              giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
              salesRepId: null,
              cardPaymentData: {},
              documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
            };
            setCart(cartObj);
            localStorage.setItem("cartObj", JSON.stringify(cartObj));
          }
          setNotesValue("");
          setOrderDelay(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
          message.success(`Order ${cartToDb.documentno} Created Successfully`);
          // keyboardRef.current.clearInput();
          // const productSearchInput = document.getElementById("sm-product-search");
          // if (productSearchInput) {
          //   productSearchInput.focus();
          //   productSearchInput.select();
          // }
          setIsInputFocused(false);
          orderState.current = 0;
          setActiveOrderProcess(false);
          localStorage.removeItem("totalDiscountsCartRef");
          if (cartToDb?.giftVoucherType) {
            const csBunitId = tillData?.tillAccess?.csBunit?.csBunitId;
            const cwrTillId = tillData?.tillAccess?.cwrTill?.cwrTillID;
            const customerId = cartToDb?.customer?.cwrCustomerId;
            const voucherType = cartToDb?.giftVoucherType;
            const voucherAmount = cartToDb?.giftVoucherAmount;

            // Check if required values are present
            if (!csBunitId || !cwrTillId || !customerId || !voucherType || !voucherAmount) {
              console.error("Missing required data for gift voucher generation");
              return;
            }

            const query = `
              mutation {
                generateGiftVoucher(
                  giftVoucher: {
                    cSBunitID: "${csBunitId}"
                    tillId: "${cwrTillId}"
                    b2cCustomerId: "${customerId}"
                    giftVoucherType: "${voucherType}"
                    voucherAmount: ${voucherAmount}
                  }
                ) {
                  status
                  message
                  cwrGiftVoucherId
                  voucherAmount
                  voucherCode
                }
              }
            `;

            try {
              Axios({
                url: serverUrl,
                method: "POST",
                data: { query },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${authHeaders.access_token}`,
                },
              })
                .then((response) => {
                  const gGV = response?.data?.data?.generateGiftVoucher;

                  if (gGV?.status === "200") {
                    cartToDb.voucherCode = gGV?.voucherCode;
                    PrintController(cartToDb, cart);
                  } else {
                    console.error("Gift voucher generation failed:", gGV);
                    cartToDb.voucherCode = null;
                    PrintController(cartToDb, cart);
                  }
                })
                .catch((error) => {
                  console.error("Error in generating gift voucher:", error);
                  cartToDb.voucherCode = null;
                  PrintController(cartToDb, cart);
                });
            } catch (error) {
              console.error("Unexpected error during gift voucher API call:", error);
              cartToDb.voucherCode = null;
              PrintController(cartToDb, cart);
            }
          } else {
            cartToDb.voucherCode = null;
            if (tillaccess?.layout === "2") {
              cartToDb["orderSelection"] = orderTypeSelection;
            }
            PrintController(cartToDb, cart);
          }
        } catch (error) {
          console.error("Failed to place an order: ", error);
        }
      } else {
        await db.orders
          .put(cartToDb)
          .then(async () => {
            setPaymentModal(false);
            localStorage.setItem("cartObj", []);
            let cartObj = {
              items: [],
              couponInput: [],
              advancePayment: 0,
              total: 0,
              tax: 0,
              discount: 0,
              paid: 0,
              change: 0,
              totalQty: 0,
              roundOff: 0,
              payments: [],
              redemptionPoints: 0,
              accumulationPoints: 0,
              creditAmount: 0,
              docType: "Invoice",
              sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
              referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
              giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
              customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
              salesRepId: null,
              cardPaymentData: {},
              documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
            };
            setCart(cartObj);
            localStorage.setItem("cartObj", JSON.stringify(cartObj));
            setNotesValue("");
            message.success(`Order ${cartToDb.documentno} Created Successfully`);
            orderState.current = 0;
            setActiveOrderProcess(false);
            localStorage.removeItem("totalDiscountsCartRef");
            if (cartToDb?.hasOwnProperty("giftVoucherType")) {
              if (!tillData?.tillAccess?.csBunit?.csBunitId || !tillData?.tillAccess?.cwrTill?.cwrTillID || !cartToDb?.customer?.cwrCustomerId) {
                console.error("Missing required data for generating gift voucher");
                return;
              }

              const mutationQuery = `
                mutation {
                  generateGiftVoucher(
                    giftVoucher: {
                      cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                      tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                      b2cCustomerId: "${cartToDb.customer.cwrCustomerId}"
                      giftVoucherType: "${cartToDb.giftVoucherType}"
                      voucherAmount: ${cartToDb.giftVoucherAmount ?? 0}
                    }
                  ) {
                    status
                    message
                    cwrGiftVoucherId
                    voucherAmount
                    voucherCode
                  }
                }
              `;

              Axios({
                url: serverUrl,
                method: "POST",
                data: { query: mutationQuery },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${authHeaders.access_token ?? ""}`,
                },
              })
                .then((response) => {
                  const gGV = response?.data?.data?.generateGiftVoucher;

                  if (gGV?.status === "200") {
                    cartToDb.voucherCode = gGV.voucherCode;
                    PrintController(cartToDb, cart);
                  } else {
                    console.error("Gift voucher generation failed:", gGV?.message ?? "Unknown error");
                    cartToDb.voucherCode = null;
                    PrintController(cartToDb, cart);
                  }
                })
                .catch((error) => {
                  console.error("Error generating gift voucher:", error.message);
                  cartToDb.voucherCode = null;
                  PrintController(cartToDb, cart);
                });
            } else {
              cartToDb.voucherCode = null;
              if (tillaccess?.layout === "2") {
                cartToDb["orderSelection"] = orderTypeSelection;
              }
              PrintController(cartToDb, cart);
            }
          })
          .catch((error) => {
            console.error("Failed to place an order: ", error);
          });
      }
      if (tillLayout === 2) {
        setTimeout(() => {
          setLoader(false);
          history.push("/pos");
        }, 4000);
      }
    }
  };

  //// CART OPERATIONS END ////

  // OFFERS AND DISCOUNTS BLOCK START
  const [offerProductsList, setOfferProductList] = useState();
  const [displayOfferProductSelectiton, setDisplayOfferProductSelection] = useState(false);

  const selectOfferProduct = (product) => {
    const pro = product;
    addProduct(pro, 1);
    setDisplayOfferProductSelection(false);
    // message.success(`${pro.name} (Free) Added Successfully`);
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
  };
  // OFFERS AND DISCOUNTS BLOCK START

  //// PAYMENTS BLOCK START ////
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentProcessFlag, setPaymentProcessFlag] = useState(false);
  const [amount, setAmount] = useState("");
  const [overPayedAmount, setOverPayedAmount] = useState(0);
  const [denaminationsKeyboard, setDenaminationsKeyboard] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const [loader, setLoader] = useState(false);

  const onChangeAmount = (event) => {
    let type = "3";
    let value = event.target.value?.replace(/[^\d.]/g, "");
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const handleAmount = (value) => {
    let type = "1";
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const handleCashPayment = (value) => {
    let type = "2";
    paymentProcess(type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb);
  };

  const [paymentModalByCustomerState, setPaymentModalByCustomerState] = useState(false);

  const openPaymentModal = async () => {
    if (tillLayout === 2) {
      setPaymentModal(true);
    }
    setPaymentProcessFlag(true);
    openPaymentModalByCustomer();
  };

  const openPaymentModalByCustomer = (cartObj) => {
    paymentModalInputRef?.current?.select();
    paymentModalInputRef?.current?.focus();
    const cartData = cartObj ? { ...cartObj } : cart;
    localStorage.setItem("totalDiscountsCartRef", JSON.stringify(cartData));
    setPaymentModalByCustomerState(false);
    if (cartData?.items?.length > 0) {
      db.pricingRules.toArray().then((pr) => {
        const pricingRuleCount = pr.length;
        if (pricingRuleCount > 0) {
          pr.sort((a, b) => {
            if (a.priority === null && b.priority === null) {
              return 0;
            } else if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return parseInt(a.priority, 10) - parseInt(b.priority, 10);
          });
          for (let i = 0; i < pricingRuleCount; i++) {
            const pricingRule = pr[i];
            if (dateValidator(pricingRule.startDate, pricingRule.endDate) && pricingRule.iscoupon === "N" && (pricingRule.type === "TD" || pricingRule.type === "TDF")) {
              if (pricingRule.timeSpecific === "Y") {
                const weekDay = currentDay();
                const pStartTime = pricingRule.starttime.substring(11);
                const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                  processBillDiscounts(pricingRule, cartData, false);
                }
              } else {
                processBillDiscounts(pricingRule, cartData, false);
              }
            }
          }
        }
      });
    }
  };

  const processBillDiscounts = (pricingRule, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId) => {
    if (pricingRule.type === "TD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null)) {
      let updatedCart = TotalBillDiscount(pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId);
      return updatedCart;
    }

    if (pricingRule.type === "TDF" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null)) {
      let updatedCart = TotalBillFreeProductDiscount(
        pricingRule,
        setCart,
        cart,
        orderType,
        cartObj,
        addProduct,
        setOfferProductList,
        setDisplayOfferProductSelection,
        iscoupon,
        couponInput,
        uniqReferenceId,
        mPricingCouponId,
        mPricingRulesId
      );
      return updatedCart;
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setSelectedPaymentMethod("");
  };

  const [loyaltyPaymentOtp, setLoyaltyPaymentOtp] = useState();
  const [loyaltyInputValue, setLoyaltyInputValue] = useState("");
  const [loyalityOtpModalVisible, setLoyalityOtpModalVisible] = useState(false);
  const [loyalityOtpData, setLoyalityOtpData] = useState();
  const [paymentModalLoyalityMessages, setPaymentModalLoyalityMessages] = useState({ inputMessage: "Enter Amount", buttonText: "Add Payment" });

  const processOtpInput = () => {
    const { otp, paymentMethod, value, redeemPoints } = loyalityOtpData;
    setLoyalityOtpModalVisible(false);
    if (otp === loyaltyPaymentOtp) {
      message.success("Loyality Points Redeemed !");
      paymentMethod.redemptionPoints = parseInt(redeemPoints);
      processPayment(paymentMethod, value);
    } else {
      message.warning("Invalid OTP");
    }
    setLoyalityOtpData();
    setLoyaltyPaymentOtp();
  };

  const handleLoyalityInput = (value) => {
    if (value === "clear") {
      setLoyaltyInputValue("");
    } else if (value === "x") {
      setLoyaltyInputValue(`${loyaltyInputValue.toString().substring(0, loyaltyInputValue.toString().length - 1)}`);
    } else {
      setLoyaltyInputValue(`${loyaltyInputValue}${value}`);
    }
  };

  const checkLoyality = async () => {
    requestPayment(selectedPaymentMethod, parseFloat(loyaltyInputValue));
    setLoyalityOtpModalVisible(false);
    setLoyaltyInputValue(0);
    // setCart(cartObj);
    // localStorage.setItem("cartObj", JSON.stringify(cartObj));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        setDenaminationsKeyboard(true);
      }
      if (selectedPaymentMethod.isloyalty) {
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Points to Redeem",
          buttonText: "Redeem",
        });
        try {
          let loyaltyData = await db.loyalityData.toArray();
          const matchedLoyalty = loyaltyData.find((loyalty) => loyalty.name === cart.customer.loyaltyLevel.name);
          let cartObj = cart;
          let value = 0;
          loyaltyData?.forEach((loyality) => {
            if (loyality.loyaltylevelId === cartObj?.customer?.loyaltyLevel?.cwrLoyaltyLevelId) {
              loyality.loyaltyRedemption.forEach((redeemItem) => {
                value = parseFloat((cart.total - cart.paid / redeemItem.redemptionValue).toFixed(2));
              });
            }
          });
          if (Math.abs(matchedLoyalty.loyaltyRedemption[0].minPurchase <= cart.total)) {
            setLoyalityOtpModalVisible(true);
            setLoyaltyInputValue(value ? value : 0);
            upsertPOSLog(cart, "LOR");
          } else {
            message.error("The minimum purchase amount required for loyalty redemption has not been met.");
          }
        } catch (error) {
          console.error("Error fetching loyalty data:", error);
        }
      } else if (selectedPaymentMethod.isGiftCard) {
        setGiftCardFlag(true);
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Coupon Code",
          buttonText: "Redeem",
        });
      } else {
        setPaymentModalLoyalityMessages({
          inputMessage: "Enter Amount",
          buttonText: "Add Payment",
        });
      }
    };

    fetchData();
  }, [selectedPaymentMethod]);

  const requestPayment = async (paymentMethod, value, grant) => {
    addAmount(
      paymentMethod,
      value,
      grant,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      setAmount,
      setLoyalityOtpData,
      setLoyalityOtpModalVisible,
      setSelectedPaymentMethod,
      setPaytmQrCodeModalOpens,
      setQrCodeResponse,
      overPayedAmount,
      setCardPaymnetError,
      setCardPaymnetStatus,
      setLoading,
      setIsCardPaymentLoading
    );
  };

  const processPayment = (paymentMethod, value) => {
    completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod);
  };
  //// PAYMENTS BLOCK END ////

  const eBillConfig = useRef({});
  useEffect(() => {
    const fetchPOSConfig = async () => {
      try {
        if (!authHeaders?.access_token || !tillValue?.cwr_till_id) {
          console.error("Missing required parameters: access token or till ID");
          return;
        }

        const accessToken = authHeaders.access_token;

        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
              getPOSConfig(tillId: "${tillValue.cwr_till_id}", name: null) {
                cwrPosConfigId
                name
                posType
                application
                configJson
                PricingRule
                ExpiryDiscount
                activateExpiryDiscount
                registrationTypes
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        });

        const posConfigData = response?.data?.data?.getPOSConfig;

        if (posConfigData?.length > 0) {
          const ebConfig = JSON.parse(posConfigData[0]?.configJson || "{}");
          eBillConfig.current = ebConfig;
        } else {
          console.warn("No POS configuration found for the provided till ID");
        }
      } catch (error) {
        console.error("Error fetching POS configuration:", error);
      }
    };
    fetchPOSConfig();
  }, []);

  const sendWebHookData = async (pendingOrders) => {
    try {
      // const orders = await db.orders.where("isSynced").equals(0).toArray();
      const orders = [...pendingOrders];
      for (const order of orders) {
        let shortId = "";
        const { eBillWebHookURL } = eBillConfig.current;
        try {
          const { data } = await Axios.get(`${eBillWebHookURL}/ebill/uniqueid`);
          shortId = data;
        } catch (error) {
          console.error(error);
          throw new Error("Failed to Fetch ID");
        }

        const orderItems = [];
        const taxKeyValue = [];
        const taxType = [];

        order.items.forEach((orderItem, i) => {
          let taxRate = orderItem.taxRate;
          if (taxType.indexOf(taxRate) !== -1) {
            let taxVal = orderItem.taxAmount / 2;
            for (let j = 0; j < taxKeyValue.length; j += 1) {
              const keys = Object.keys(taxKeyValue[j]);
              if (keys[0].toString() === taxRate.toString()) {
                taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
              }
            }
          } else {
            taxType.push(taxRate);
            let taxVal = orderItem.taxAmount / 2;
            taxKeyValue.push({ [taxRate]: taxVal });
          }

          orderItems.push({
            line_no: i + 1,
            sku: orderItem.value,
            name: orderItem.name,
            description: null,
            hsn_code: null,
            selling_price: orderItem.salePrice,
            quantity: orderItem.weight,
            amount: orderItem.nettotal,
            list_price: orderItem.realPrice,
            uom: orderItem.uom_name,
            attributes: {
              mc: "",
              symc: "",
            },
            taxes: [
              {
                tax_name: "GST",
                percentage: orderItem.taxRate,
                amount: orderItem.taxAmount,
                taxableAmount: orderItem.nettotal,
              },
            ],
            brand: null,
            categoryId: orderItem.mProductCategoryId,
            categoryName: null,
          });
        });

        const payments = [];
        order.payments.forEach((payment, i) => {
          payments.push({
            payment_method: payment.name,
            amount: payment.amount,
          });
        });

        const orderData = {
          clientId: tillData.tillAccess.csClientId,
          storeId: tillData.tillAccess.csBunit.csBunitId,
          eBillCommType: eBillConfig.current.eBillCommType,
          created: order.orderTime,
          updated: order.orderTime,
          short_id: shortId,
          customer: {
            customer_id: order.customer.cwrCustomerId,
            first_name: order.customer.name,
            last_name: "",
            mobile: order.customer.mobileNo,
          },
          order_type: order.orderType,
          order_id: order.sOrderID,
          bill_no: order.documentno,
          bill_status: "PAID",
          order_date: order.orderTime,
          items: orderItems,
          item_count: order.items.length,
          total_qty: order.totalQty,
          net_total: order.total,
          gross_total: order.total,
          payment: payments,
          taxes: [
            {
              tax_name: "GST",
              amount: order.tax,
              taxableAmount: order.total,
            },
          ],
          additonalTaxinfo: taxKeyValue,
          feedback: false,
          feedbackStars: 0,
        };

        try {
          if (orderData.customer.mobile !== "9999999999") {
            await Axios.post(`${eBillWebHookURL}/ebill/webhook`, orderData);
          }
        } catch (error) {
          console.error(error);
          throw new Error("Failed to POST Webhook");
        }
      }
    } catch (e) {
      console.error("Webhook Error", e);
    }
  };

  // ORDER SYNC BLOCK START
  const syncOrders = async (syncTrigger) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    let saleTypeId = null;
    if (localStorage.getItem("saleTypeData") !== null) {
      const saleTypeData = JSON.parse(localStorage.getItem("saleTypeData"));
      saleTypeId = saleTypeData?.cwrSaletype?.cwrSaletypeId;
    }
    if (nWStatus === "online") {
      db.logInformation.toArray().then(async (fetched) => {
        if (fetched.length > 0) {
          const posLogArray = [];
          const trxId = uuidv4().replace(/-/g, "").toUpperCase();
          let shouldCallAxios = false;

          for (const item of fetched) {
            const value = { ...item };
            await db.logInformation.where("id").equals(value.id).delete();

            posLogArray.push(`{
              type: ${JSON.stringify(value.type)}, 
              action: "LOG", 
              description: ${JSON.stringify(value.description)}, 
              date: ${JSON.stringify(value.date)}, 
              time: ${JSON.stringify(value.time)},    
              orderNo: ${JSON.stringify(value.orderNo)},
              remarks: ${JSON.stringify(value.remarks)},
              transactionId: "${trxId}",
              status: "SCS",
              duration: ${value.duration ?? 0}
            }`);

            if (posConfig?.[value.type] === "Y") {
              shouldCallAxios = true;
            }
          }

          if (shouldCallAxios) {
            try {
              if (!tillData?.tillAccess?.cwrTill?.cwrTillID || !tillData?.tillAccess?.csUserId || !tillData?.tillAccess?.csBunit?.csBunitId) {
                console.error("Required till data is missing");
                return;
              }

              const response = await Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation {
                    upsertPOSLog(order: {
                      tillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
                      userId: "${tillData.tillAccess.csUserId}",
                      bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                      lines: [${posLogArray.join(", ")}]
                    }) {
                      status   
                      message
                    }
                  }`,
                },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${setAuthTokens}`,
                },
              });

              if (response.status === 200) {
                console.log("POS logs successfully upserted:", response.data);
              } else {
                console.error("Error upserting POS logs:", response.status, response.data);
              }
            } catch (error) {
              console.error("Axios request failed:", error);
            }
          }
        }
      });

      const csCurrencyId = tillData?.tillAccess?.csBunit?.currencies[0]?.csCurrencyId || "";
      db.orders
        .where("isSynced")
        .equals(0)
        .or("isSynced")
        .equals(2)
        .toArray()
        .then(async (orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            if (eBillConfig.current.eBill === "Y") {
              sendWebHookData(orders);
            }
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const regularOrders = orders.filter((order) => {
                const layAwayStatus = order?.layAway || "N";
                const paidAmount = order.layAway === "Y" && posConfig.advanceLayaway === "Y" && order.advancePayment <= 0 ? 0 + order?.paid : order?.paid || 0;
                const advancePayment = order?.advancePayment || 0;
                const totalAmount = order?.total || 0;
                const totalQty = order?.totalQty || 0;
                const isParked = order?.parked === "Y";

                const isRegularOrderWithPaid =
                  posConfig?.advanceLayaway === "Y"
                    ? layAwayStatus === "Y"
                      ? layAwayStatus === "Y" && paidAmount + advancePayment <= totalAmount && advancePayment <= 0 && order?.docType !== "Advance"
                      : layAwayStatus === "N" && paidAmount !== 0
                    : layAwayStatus === "N" && paidAmount !== 0;

                const isRegularOrderWithoutPaid = (layAwayStatus === "N" || layAwayStatus === "Y") && paidAmount === 0;
                const isCancelledOrder = totalAmount === 0 && totalQty === 0;
                const isParkedOrder = isParked && paidAmount === 0;

                return isRegularOrderWithPaid || isRegularOrderWithoutPaid || isCancelledOrder || isParkedOrder;
              });

              if (regularOrders.length > 0) {
                const orderLines = [];
                for (let j = 0; j < regularOrders[i]?.items.length; j += 1) {
                  let unitTax = regularOrders[i].items[j].taxAmount / regularOrders[i].items[j].weight;
                  unitTax = unitTax * (regularOrders[i].items[j].isReturn ? -1 : 1);

                  let linesMeta = [];
                  if (regularOrders[i].items[j].newCustomAttributes?.length > 0) {
                    Object.entries(regularOrders[i].items[j].newCustomAttributes[0]).forEach(([key, value]) => {
                      linesMeta.push({
                        key: key,
                        value: value,
                      });
                    });
                  }

                  if (regularOrders[i]?.items[j]?.approval?.length > 0) {
                    const approval = regularOrders[i].items[j].approval[0];
                    const keysToRemove = ["type", "min", "max", "roleId", "mPricingrulesId", "pin"];

                    keysToRemove.forEach((key) => delete approval[key]);

                    linesMeta.push({
                      key: "Approval",
                      value: JSON.stringify([approval]),
                    });
                  }

                  orderLines.push({
                    sOrderlineID: uuidv4().replace(/-/g, "").toUpperCase(),
                    sOrderlineReturnId: regularOrders[i].items[j].sOrderlineReturnId || null,
                    created: regularOrders[i].orderTime,
                    createdby: regularOrders[i].createdBy,
                    updated: regularOrders[i].orderTime,
                    updatedby: regularOrders[i].createdBy,
                    sOrderId: regularOrders[i].sOrderID,
                    line: (j + 1) * 10,
                    description: regularOrders[i].items[j].notes || "",
                    mProductId: regularOrders[i].items[j].productId || regularOrders[i].items[j].mProductId,
                    csUomId: regularOrders[i].items[j].uom || regularOrders[i].items[j].uomData[0]?.csUomId,
                    csTaxId: regularOrders[i].items[j].tax || regularOrders[i].items[j].cTaxId,
                    qty: regularOrders[i].items[j].weight,
                    unitprice: parseFloat(regularOrders[i].items[j].realPrice) * (regularOrders[i].items[j].isReturn ? -1 : 1),
                    netlist: parseFloat(regularOrders[i].items[j].netList),
                    discount: regularOrders[i].items[j].discount,
                    returnline: regularOrders[i].items[j].isReturn || false,
                    returnQty: regularOrders[i].items[j].isReturn ? Math.abs(regularOrders[i].items[j].weight) : 0,
                    mBatchId: regularOrders[i].items[j].mBatchId || regularOrders[i].items[j].mBatch || null,
                    mPricingruleId: regularOrders[i].items[j].mPricingruleId || null,
                    batchedForSale: regularOrders[i].items[j].batchedForSale,
                    batchedForStock: regularOrders[i].items[j].batchedForStock,
                    batchedProduct: regularOrders[i].items[j].batchedProduct,
                    salesRepId: regularOrders[i].items[j]?.salesRepId || regularOrders[i]?.salesRepId || null,
                    multiPrice: regularOrders[i].items[j].multiPrice,
                    discountTypeId: null,
                    discountAmount: null,
                    metaData: linesMeta,
                    unittax: unitTax > 0 ? parseFloat(unitTax.toFixed(precision)) : 0,
                    linetax: parseFloat(regularOrders[i].items[j].linetax || 0),
                    linenet: regularOrders[i].items[j].linenet || 0,
                    linegross: regularOrders[i].items[j].linegross || 0,
                    netunit: Math.abs(regularOrders[i].items[j].grossunit) > 0 ? parseFloat((regularOrders[i].items[j].grossunit - unitTax).toFixed(precision)) : 0,
                    netstd: Math.abs(regularOrders[i].items[j].netStd) ? parseFloat(regularOrders[i].items[j].netStd) : 0,
                    listprice: regularOrders[i].items[j].listPrice || 0,
                    grossunit: regularOrders[i].items[j].grossunit || 0,
                    grossstd: regularOrders[i].items[j].grossstd || 0,
                    grosslist: regularOrders[i].items[j].grosslist || 0,
                    tax: [
                      {
                        csTaxID: regularOrders[i].items[j].tax || regularOrders[i].items[j].cTaxId,
                        taxableAmt: parseFloat(regularOrders[i]?.items[j]?.linenet?.toFixed(precision)),
                        taxAmt: parseFloat(regularOrders[i].items[j].taxAmount.toFixed(precision)),
                      },
                    ],
                  });
                }
                let metaData = [];
                const paymentsList = [];

                const paymentMetaData = [];
                let amtMax = -100000000000000000000;
                let maxFinPaymentMethod;
                for (const payment of regularOrders[i]?.payments?.flat() || []) {
                  paymentMetaData.push({
                    [payment.name]: parseFloat(payment.amount).toFixed(2),
                  });
                  if (amtMax < parseFloat(payment.amount)) {
                    amtMax = parseFloat(payment.amount);
                    maxFinPaymentMethod = payment.finPaymentmethodId;
                  }
                  if (payment.name.toLowerCase() === "card") {
                    if (payment.integratedPayment === true) {
                      paymentsList.push({
                        finPaymentmethodID: payment.finPaymentmethodId,
                        amount: parseFloat(payment.amount).toFixed(2),
                        transactionResponse: regularOrders[i].cardPaymentData?.response?.length > 0 ? regularOrders[i].cardPaymentData?.response[0]?.resultMessage : "",
                        transactionId: null,
                        cardType: "",
                        cardNo: "",
                        transactionTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        authorizationCode: null,
                        metaData: [
                          {
                            key: "payload",
                            value: regularOrders[i].cardPaymentData?.payload?.length > 0 ? JSON.stringify(regularOrders[i].cardPaymentData?.payload[0]) : "",
                          },
                          {
                            key: "response",
                            value: regularOrders[i].cardPaymentData?.response?.length > 0 ? JSON.stringify(regularOrders[i].cardPaymentData?.response[0]) : "",
                          },
                        ],
                      });
                    } else {
                      paymentsList.push({
                        finPaymentmethodID: payment.finPaymentmethodId,
                        amount: parseFloat(payment.amount).toFixed(2),
                        transactionResponse: null,
                        transactionId: regularOrders[i]?.cardPaymentData?.manual?.transactionId || null,
                        cardType: regularOrders[i].cardPaymentData?.manual?.cardType || null,
                        cardNo: regularOrders[i].cardPaymentData?.manual?.cardNumber || null,
                        transactionTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        authorizationCode: payment.authorization || null,
                        metaData: [],
                      });
                    }
                  } else {
                    paymentsList.push({
                      finPaymentmethodID: payment.finPaymentmethodId,
                      amount: parseFloat(payment.amount).toFixed(2),
                    });
                  }
                }

                if (paymentMetaData.length > 0) {
                  const details = [
                    ...paymentMetaData,
                    { "Bill Amount": regularOrders[i]?.total },
                    { change: regularOrders[i]?.change },
                    { "Round Off": regularOrders[i]?.roundOff },
                    { "Over Payment": regularOrders[i]?.overPayedAmount },
                  ];

                  if (regularOrders[i]?.tenderedAmount > 0) {
                    details.push({ "Cash Received": regularOrders[i]?.tenderedAmount.toFixed(2) });
                  }

                  metaData.push({
                    key: "Payment Details",
                    value: JSON.stringify(details),
                  });
                }

                let tableData = JSON.parse(localStorage.getItem("tableName"));
                if (localStorage.getItem("dineIn") === "Y") {
                  const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                  localStorage.removeItem("tableName");
                  for (const key of keysToCheck) {
                    if (tableData?.hasOwnProperty(key)) {
                      metaData.push({
                        key: key,
                        value: tableData[key],
                      });
                    }
                  }
                }

                if (regularOrders[i]?.orderTimeDetails) {
                  Object.keys(regularOrders[i].orderTimeDetails).forEach((obj) => {
                    metaData.push({
                      key: obj,
                      value: regularOrders[i].orderTimeDetails[obj],
                    });
                  });
                }

                let giftCards = [];
                regularOrders[i]?.giftCardData?.map((res) => {
                  giftCards.push({
                    redeemRefNo: res.redemptionId || null,
                    cardNo: res.number || null,
                    referenceNo: res.refId || null,
                  });
                });
                const isLoyaltyEnabled =
                  (posConfig?.enableLoyalty || "N") === "N" || ((posConfig?.enableLoyalty || "N") === "Y" && (posConfig?.disablePOSLoyaltyCalculation || "N") === "N");
                if (!regularOrders[i] || !regularOrders[i].items) continue;
                posConfig.disablePOSLoyaltyCalculation = posConfig?.disablePOSLoyaltyCalculation || "N";

                const orderJson = {
                  sOrderID: regularOrders[i].sOrderID,
                  cSClientID: tillData.tillAccess.csClientId,
                  cSBunitID: tillData.tillAccess.csBunit.csBunitId,
                  created: regularOrders[i].orderTime,
                  createdby: regularOrders[i].createdBy,
                  updated: regularOrders[i].orderTime,
                  updatedby: regularOrders[i].createdBy,
                  csDoctypeId: tillData.tillAccess.csBunit.cwrCsDoctypeId,
                  sCustomerId: regularOrders[i]?.customer?.sCustomer?.sCustomerID
                    ? regularOrders[i].customer.sCustomer.sCustomerID
                    : tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID,
                  sCustomerBillingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
                  sCustomerShippingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
                  sPricelistId: tillData.tillAccess.csBunit.cwrSpricelistId,
                  documentno: regularOrders[i].documentno,
                  dateordered: regularOrders[i].orderTime,
                  datepromised: regularOrders[i].orderTime,
                  csPaymenttermID: null,
                  finPaymentmethodId: regularOrders[i].layAway === "N" && maxFinPaymentMethod ? maxFinPaymentMethod : null,
                  csCurrencyId: csCurrencyId,
                  mWarehouseId: tillData.tillAccess.csBunit.mWarehouse.mWarehouseID,
                  cwrLongitude: "",
                  cwrLatitude: "",
                  csUserId: tillData.tillAccess.csUserId,
                  cwrB2cCustomerId: regularOrders[i].customer?.cwrCustomerId ? regularOrders[i].customer?.cwrCustomerId : regularOrders[i].customer?.b2cCustomerId,
                  orderreference: "",
                  cwrPayref: "",
                  cwrPayremarks: "",
                  description: regularOrders[i].description || "",
                  storeDailyOpsTillid: localStorage.getItem("storeDailyOpsTillid"),
                  cwrTillId: tillData.tillAccess.cwrTill.cwrTillID,
                  redemption: isLoyaltyEnabled ? regularOrders[i].redemptionPoints : 0,
                  accumulation: isLoyaltyEnabled ? regularOrders[i].accumulationPoints : 0,
                  redeemRefId: regularOrders[i].referenceId,
                  roundoff: parseFloat(regularOrders[i].roundOff?.toFixed(precision)) || 0,
                  cwrProductQty: regularOrders[i].totalQty,
                  cwrProductCount: regularOrders[i].totalQty,
                  ofdStatus: "Delivered",
                  ofdIspaid: "Y",
                  posLoyaltyCalculation: posConfig?.disablePOSLoyaltyCalculation,
                  mPricingruleId: regularOrders[i].mPricingruleId ?? null,
                  cwrSaletypeId: saleTypeId,
                  salesRepId: regularOrders[i]?.salesRepId || null,
                  discAmount: parseFloat(regularOrders[i].discount.toFixed(precision)),
                  creditAmount: regularOrders[i].creditAmount,
                  metaData: metaData,
                  giftCard: giftCards,
                  pricingCoupon: [
                    {
                      mPricingCouponId: regularOrders[i]?.couponInput?.[0]?.mPricingCouponId || null,
                      redemptionCount: regularOrders[i].couponRedemptionCount ? parseInt(regularOrders[i].couponRedemptionCount) : null,
                      referenceId: regularOrders[i]?.couponInput?.[0]?.referenceId || null,
                    },
                  ],
                  orderTotal: regularOrders[i].total,
                  nettotal: parseFloat((regularOrders[i].total - regularOrders[i].tax).toFixed(2)),
                  taxamt: parseFloat(regularOrders[i].tax.toFixed(2)),
                  isReturn: regularOrders[i].items.some((f) => f.isReturn) ? "Y" : "N",
                  sOrderReturnId: regularOrders[i].items?.[0]?.sOrderReturnId || null,
                  layAway: regularOrders[i].layAway || null,
                  payments: paymentsList,
                  tax: regularOrders[i].combinedTaxRates ? regularOrders[i].combinedTaxRates : [],
                  line: orderLines,
                };

                const stringifiedData = JSON.stringify(orderJson);
                const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"');
                const newStringRequest = JSON.stringify(newStringifiedFields);

                const paramsInput = {
                  query: `
                    mutation {
                      posOrderProcessor1(
                        tillId: "${tillData.tillAccess.cwrTill.cwrTillID}", 
                        orderId: "${regularOrders[i].sOrderID}", 
                        documentNo: "${regularOrders[i].documentno}", 
                        orderJson: ${newStringRequest}
                      ) {
                        documentno
                        status
                        message
                      }
                    }
                  `,
                };

                try {
                  await Axios({
                    url: serverUrl,
                    method: "POST",
                    data: paramsInput,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${setAuthTokens}`,
                    },
                  })
                    .then(async (response) => {
                      const result = response.data.data.posOrderProcessor1;
                      const { status } = result;
                      if (status === "200") {
                        console.info(`Order ${regularOrders[i].documentno} synced to Server`);
                        db.orders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 1 });
                        let rfidData = [];
                        await db.rfidData.toArray((products) => {
                          products.map((ele) => {
                            if (ele.tag_status === "SO") {
                              rfidData.push(` {
                                tagValue: "${ele.tag_value}"
                                taggingDate: null
                                batchNumber: null
                                batchId: null
                                warehouseId: null
                                tagStatus: "SO"
                                lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                scannedBy: null
                                expirydate: null
                                customAttribute: null
                                tagType: null
                                productCode: "${ele.product_code}"
                                }`);
                            }
                          });
                        });
                        await Axios({
                          url: serverUrl,
                          method: "POST",
                          data: {
                            query: `mutation{
                              RFIDTag(rfidTag:[${rfidData}]){
                              status
                              message
                              }
                              }`,
                          },
                          headers: {
                            "Content-Type": "Application/json",
                            Authorization: `${setAuthTokens}`,
                          },
                        });
                      } else {
                        console.error("Failed Order Sync ====> ", response);
                        const syncFailedCount = parseInt(regularOrders[i].syncAttempts) + 1;
                        if (parseInt(regularOrders[i].syncAttempts) < 100) {
                          db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                        } else {
                          db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 2 });
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error, "err------------>");
                    });
                } catch (error) {
                  console.log(error, "err------------>");
                }
              } else {
                const layAwayOrders = orders.filter((order) => {
                  return order.layAway === "Y" && order.paid !== 0;
                });
                if (layAwayOrders.length > 0) {
                  const orderLines = [];
                  for (let j = 0; j < layAwayOrders[i].items.length; j += 1) {
                    let unitPrice;
                    if (layAwayOrders[i].items[j].weight > 0) {
                      unitPrice = layAwayOrders[i].items[j].realPrice;
                      unitPrice = parseFloat(unitPrice).toFixed(2);
                    } else {
                      unitPrice = 0;
                    }
                    let orderLineMeta = [];
                    if (layAwayOrders[i]?.items[j]?.approval?.length > 0) {
                      orderLineMeta.push({});
                    }
                    orderLines.push({
                      sOrderlineID: layAwayOrders[i].items[j].sOrderlineID ? layAwayOrders[i].items[j].sOrderlineID : uuidv4().replace(/-/g, "").toUpperCase(),
                      sOrderlineReturnId: layAwayOrders[i].items[j].sOrderlineReturnId || null,
                      created: layAwayOrders[i].orderTime,
                      createdby: layAwayOrders[i].createdBy,
                      updated: layAwayOrders[i].orderTime,
                      updatedby: layAwayOrders[i].createdBy,
                      sOrderId: layAwayOrders[i].sOrderID,
                      line: (j + 1) * 10,
                      description: "",
                      mProductId: layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].productId : layAwayOrders[i].items[j].product.mProductId,
                      csUomId: layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].uom : layAwayOrders[i].items[j].uom?.csUomId || null,
                      csTaxId: layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].tax : layAwayOrders[i].items[j].tax?.csTaxID || null,
                      qty: layAwayOrders[i].items[j].weight,
                      unitprice: unitPrice || 0,
                      netlist: layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].salePrice : 0,
                      discount: layAwayOrders[i].items[j].discount,
                      returnline: layAwayOrders[i].items[j].isReturn ?? layAwayOrders[i].items[j].returnline,
                      returnQty: layAwayOrders[i].items[j].isReturn === true ? Math.abs(layAwayOrders[i].items[j].weight) : 0,
                      mBatchId: layAwayOrders[i].items[j].mBatchId || null,
                      mPricingruleId: layAwayOrders[i].items[j].mPricingruleId ?? null,
                      batchedForSale: layAwayOrders[i].items[j].batchedForSale || null,
                      batchedForStock: layAwayOrders[i].items[j].batchedForStock || null,
                      batchedProduct: layAwayOrders[i].items[j].batchedProduct || null,
                      salesRepId: layAwayOrders[i].items[j]?.salesRepId || layAwayOrders[i]?.salesRepId || null,
                      multiPrice: layAwayOrders[i].items[j].multiPrice || null,
                      discountTypeId: null,
                      discountAmount: null,
                      unittax:
                        layAwayOrders[i].items[j].weight > 0 && layAwayOrders[i].items[j].taxRate > 0
                          ? layAwayOrders[i].items[j]?.unitTax || layAwayOrders[i].items[j]?.unittax || 0
                          : 0,
                      linetax: parseFloat(layAwayOrders[i].items[j].linetax || 0),
                      metaData: orderLineMeta,
                      linenet: layAwayOrders[i].items[j].linenet || 0,
                      linegross: layAwayOrders[i].items[j].linegross || 0,
                      netunit: Math.abs(layAwayOrders[i].items[j].netunit),
                      listprice: layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].listPrice : 0,
                      grossunit: layAwayOrders[i].items[j].grossunit || 0,
                      grossstd: layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].grossstd : 0,
                      grosslist: layAwayOrders[i].items[j].weight > 0 ? layAwayOrders[i].items[j].listPrice : 0,
                      tax: [
                        {
                          csTaxID: layAwayOrders[i].taxDetails[0]?.csTaxID || "",
                          taxableAmt: parseFloat(layAwayOrders[i].items[j].linenet?.toFixed(precision) || 0),
                          taxAmt: parseFloat(layAwayOrders[i].items[j].taxAmount.toFixed(precision) || 0),
                        },
                      ],
                    });
                  }
                  const paymentsList = [];
                  let amtMax = -100000000000000000000;
                  let maxFinPaymentMethod = null;
                  for (let k = 0; k < layAwayOrders[i].payments.length; k += 1) {
                    if (amtMax < parseFloat(layAwayOrders[i].payments[k].amount)) {
                      amtMax = parseFloat(layAwayOrders[i].payments[k].amount);
                      maxFinPaymentMethod = layAwayOrders[i].payments[k].finPaymentmethodId;
                    }
                    paymentsList.push({
                      finPaymentmethodID: layAwayOrders[i].payments[k].finPaymentmethodId,
                      amount: layAwayOrders[i].payments[k].amount,
                    });
                  }
                  let tableData = JSON.parse(localStorage.getItem("tableName"));
                  let metaData = [];
                  if (localStorage.getItem("dineIn") === "Y") {
                    const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                    localStorage.removeItem("tableName");
                    for (const key of keysToCheck) {
                      if (tableData.hasOwnProperty(key)) {
                        metaData.push({
                          key: key,
                          value: tableData[key],
                        });
                      }
                    }
                  }
                  const orderJson = {
                    sOrderID: layAwayOrders[i].sOrderID,
                    cSClientID: tillData.tillAccess.csClientId,
                    cSBunitID: tillData.tillAccess.csBunit.csBunitId,
                    created: layAwayOrders[i].orderTime,
                    createdby: layAwayOrders[i].createdBy,
                    updated: layAwayOrders[i].orderTime,
                    updatedby: layAwayOrders[i].createdBy,
                    csDoctypeId: tillData.tillAccess.csBunit.cwrCsDoctypeId,
                    sCustomerId: layAwayOrders[i]?.customer?.sCustomer?.sCustomerID
                      ? layAwayOrders[i].customer.sCustomer.sCustomerID
                      : tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID,
                    sCustomerBillingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
                    sCustomerShippingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
                    sPricelistId: tillData.tillAccess.csBunit.cwrSpricelistId,
                    documentno: layAwayOrders[i].documentno,
                    dateordered: layAwayOrders[i].orderTime,
                    datepromised: layAwayOrders[i].orderTime,
                    csPaymenttermID: null,
                    finPaymentmethodId: maxFinPaymentMethod || null,
                    csCurrencyId: csCurrencyId,
                    mWarehouseId: tillData.tillAccess.csBunit.mWarehouse.mWarehouseID,
                    cwrLongitude: "",
                    cwrLatitude: "",
                    csUserId: tillData.tillAccess.csUserId,
                    cwrB2cCustomerId: layAwayOrders[i].customer.cwrCustomerId,
                    orderreference: "",
                    cwrPayref: "",
                    cwrPayremarks: "",
                    description: "",
                    isAdvance: layAwayOrders[i].statusType === "layAway" ? "Y" : "N",
                    cwrTillId: tillData.tillAccess.cwrTill.cwrTillID,
                    redemption: layAwayOrders[i].redemptionPoints || null,
                    accumulation: layAwayOrders[i].accumulationPoints || null,
                    roundoff: Math.abs(layAwayOrders[i].roundOff?.toFixed(precision)) || 0,
                    cwrProductQty: layAwayOrders[i].totalQty,
                    cwrProductCount: layAwayOrders[i].totalQty,
                    ofdStatus: "Delivered",
                    ofdIspaid: "Y",
                    mPricingruleId: layAwayOrders[i].mPricingruleId !== null && layAwayOrders[i].mPricingruleId !== undefined ? layAwayOrders[i].mPricingruleId : null,
                    cwrSaletypeId: saleTypeId,
                    salesRepId: layAwayOrders[i].salesRepId !== null && layAwayOrders[i].salesRepId !== undefined ? layAwayOrders[i].salesRepId : null,
                    discAmount: layAwayOrders[i].discount,
                    creditAmount: layAwayOrders[i].creditAmount,
                    metaData: metaData,
                    pricingCoupon: [
                      {
                        mPricingCouponId: layAwayOrders[i].mPricingCouponId || null,
                        redemptionCount: layAwayOrders[i].couponRedemptionCount ? parseInt(layAwayOrders[i].couponRedemptionCount) : null,
                        referenceId: layAwayOrders[i]?.couponInput?.length > 0 ? layAwayOrders[i].couponInput[0].referenceId : null,
                      },
                    ],
                    orderTotal: layAwayOrders[i].total,
                    isReturn: layAwayOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? "Y" : "N",
                    sOrderReturnId: layAwayOrders[i].items?.[0]?.sOrderReturnId || null,
                    layAway: layAwayOrders[i].layAway,
                    nettotal: parseFloat((layAwayOrders[i].total - layAwayOrders[i].tax).toFixed(2)),
                    taxamt: parseFloat(layAwayOrders[i].tax.toFixed(2)),
                    payments: paymentsList,
                    line: orderLines,
                  };

                  const stringifiedData = JSON.stringify(orderJson);
                  const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"');
                  const newStringRequest = JSON.stringify(newStringifiedFields);

                  const paramsInput = {
                    query: `
                      mutation {
                        confirmPOSLayawayOrder1(
                          tillId: "${tillData.tillAccess.cwrTill.cwrTillID}", 
                          orderId: "${layAwayOrders[i].sOrderID}", 
                          documentNo: "${layAwayOrders[i].documentno}", 
                          orderJson: ${newStringRequest}
                        ) {
                          documentno 
                          status
                          message
                        }
                      }
                    `,
                  };

                  try {
                    await Axios({
                      url: serverUrl,
                      method: "POST",
                      data: paramsInput,
                      headers: {
                        "Content-Type": "Application/json",
                        Authorization: `${setAuthTokens}`,
                      },
                    })
                      .then(async (response) => {
                        const result = response.data.data.confirmPOSLayawayOrder1;
                        if (result.status === "200") {
                          console.info(`Order ${layAwayOrders[i].documentno} synced to Server`);
                          db.orders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 1 });
                          let rfidData = [];
                          await db.rfidData.toArray((products) => {
                            products.map((ele) => {
                              if (ele.tag_status === "SO") {
                                rfidData.push(` {
                                    tagValue: "${ele.tag_value}"
                                    taggingDate: null
                                    batchNumber: null
                                    batchId: null
                                    warehouseId: null
                                    tagStatus: "SO"
                                    lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                                    scannedBy: null
                                    expirydate: null
                                    customAttribute: null
                                    tagType: null
                                    productCode: "${ele.product_code}"
                                    }`);
                              }
                            });
                          });
                          await Axios({
                            url: serverUrl,
                            method: "POST",
                            data: {
                              query: `mutation{
                                RFIDTag(rfidTag:[${rfidData}]){
                                status
                                message
                                }
                                }`,
                            },
                            headers: {
                              "Content-Type": "Application/json",
                              Authorization: `${setAuthTokens}`,
                            },
                          });
                        } else {
                          console.error("Failed Order Sync ====> ", response);
                          const syncFailedCount = parseInt(layAwayOrders[i].syncAttempts) + 1;
                          if (parseInt(layAwayOrders[i].syncAttempts) < 100) {
                            db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                          } else {
                            db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 2 });
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                }
              }
            }
          }
        });
    }
    if (syncTrigger === "orderHistory") {
      showOrderHistory();
    }
  };

  useEffect(() => {
    const syncOrdersInterval = setInterval(() => syncOrders(), 10000);
    return () => {
      clearTimeout(syncOrdersInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // ORDER SYNC BLOCK END

  const handleCloseModal = () => {
    history.push("/dashboard");
  };

  const confirmDiscardCart = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        className: "parkedItemsClass",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          parkBill();
          setTimeout(() => {
            history.push("/dashboard");
          }, 700);
        },
        onCancel: () => {
          handleCloseModal();
        },
      });
    } else {
      history.push("/dashboard");
    }
  };

  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponInput, setCouponInput] = useState("");

  const closeCouponModal = () => {
    setCouponModalVisible(false);
    setCouponInput("");
    setIsInputFocused(false);
  };

  const handleCouponInput = (value) => {
    if (value === "clear") {
      setCouponInput("");
    } else if (value === "x") {
      setCouponInput(`${couponInput.toString().substring(0, couponInput.toString().length - 1)}`);
    } else {
      setCouponInput(`${couponInput}${value}`);
    }
  };

  const checkCoupon = async () => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let uniqReferenceId = uuidv4().replace(/-/g, "").toUpperCase();
    let couponFlag = true;
    cart?.couponInput?.forEach((coupon) => {
      if (coupon.couponCode === couponInput) {
        couponFlag = false;
      }
      // discardCoupon(coupon);
    });
    if (couponFlag) {
      try {
        const verifyCouponResponse = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query{
                    verifyCoupons(couponcode:"${couponInput}",referenceId: "${uniqReferenceId}"){
                    mPricingCouponId
                    csClientId
                    csBunitId
                    created
                    createdBy
                    updated
                    updatedBy
                    line
                    mPricingRulesId
                    couponcode
                    status
                    redemptionCount
                    usedDate
                    upc
                }
            }
              `,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        }).catch((error) => {
          Sentry.captureException(error);
        });
        const verifyCoupons = verifyCouponResponse?.data?.data?.verifyCoupons || [];
        if (verifyCoupons?.length > 0) {
          if (verifyCoupons[0].status === "A") {
            const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(verifyCoupons[0].mPricingRulesId).toArray();
            // console.log(matchingPricingRules);
            let discardCoupon = false;
            if (matchingPricingRules.length > 0) {
              setCouponModalVisible(false);
              setCouponInput("");
              const pricingRule = matchingPricingRules[0];
              let finalObj;
              if (pricingRule.type === "TD" && pricingRule.iscoupon === "Y") {
                if (dateValidator(pricingRule.startDate, pricingRule.endDate)) {
                  if (pricingRule.timeSpecific === "Y") {
                    const weekDay = currentDay();
                    const pStartTime = pricingRule.starttime.substring(11);
                    const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                    const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                    const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                    if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                      finalObj = processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId);
                    }
                  } else {
                    finalObj = processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId);
                  }
                }
                setCart(finalObj);
                let filteredCoupon = finalObj?.couponInput?.findIndex((item) => item.mPricingCouponId === verifyCoupons[0].mPricingCouponId);
                if (filteredCoupon >= 0) {
                  message.success(`Coupon Applied: ${pricingRule.printedName}`);
                } else {
                  discardCoupon = true;
                }
              }

              if (pricingRule.type === "TDF" && pricingRule.iscoupon === "Y") {
                if (dateValidator(pricingRule.startDate, pricingRule.endDate)) {
                  if (pricingRule.timeSpecific === "Y") {
                    const weekDay = currentDay();
                    const pStartTime = pricingRule.starttime.substring(11);
                    const pEndTIme = pricingRule.endtime !== null ? pricingRule.endtime.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
                    const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
                    const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
                    if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y") {
                      processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId, verifyCoupons[0].mPricingRulesId);
                    }
                  } else {
                    processBillDiscounts(pricingRule, cart, true, couponInput, uniqReferenceId, verifyCoupons[0].mPricingCouponId, verifyCoupons[0].mPricingRulesId);
                  }
                }
              }

              if (pricingRule.type !== "TDF" && pricingRule.iscoupon === "Y" && pricingRule.type !== "TD") {
                let addToCart = cart.items.filter((item) => pricingRule.mPricingXProducts.some((pro) => item.productId === pro.mProductId));

                // Check if the filtered array is empty
                if (addToCart.length === 0 && cart.items.length > 0) {
                  addToCart = cart.items[cart.items.length - 1]; // Add the last item of the cart
                } else {
                  addToCart = addToCart[0];
                }

                let cartObj = cart;
                let iscoupon = true;
                let expiryDiscount = localStorage.getItem("expiryDiscount") !== null && localStorage.getItem("expiryDiscount") === "Y" ? true : false;
                let updatedCart = expiryDiscount
                  ? cartObj
                  : await pricingRuleController(
                      addToCart,
                      cartObj,
                      cart,
                      setCart,
                      cartRef,
                      orderType,
                      iscoupon,
                      couponInput,
                      uniqReferenceId,
                      verifyCoupons[0].mPricingCouponId,
                      verifyCoupons[0].mPricingRulesId
                    );
                let updatedTotalTax = 0;
                let updatedTotalPrice = 0;
                let updatedTotalItemsQty = 0;
                let updatedTotalDiscounts = 0;
                for (let i = 0; i < updatedCart.items.length; i += 1) {
                  updatedTotalPrice += updatedCart.items[i].nettotal;
                  updatedTotalItemsQty += updatedCart.items[i].weight;
                  updatedTotalTax += updatedCart.items[i].taxAmount;
                  updatedTotalDiscounts += updatedCart.items[i].discount;
                  updatedCart.items[i].key = i;
                }

                const updatedRoundOffValue = Math.round(updatedTotalPrice);
                const updatedTotalRoundOff = updatedTotalPrice - updatedRoundOffValue;

                let finalCartObj = {
                  ...updatedCart,
                  items: [...updatedCart.items],
                  total: parseFloat(updatedTotalPrice.toFixed(precision)),
                  tax: parseFloat(updatedTotalTax.toFixed(precision)),
                  discount: parseFloat(updatedTotalDiscounts.toFixed(precision)),
                  totalQty: updatedTotalItemsQty,
                  roundOff: parseFloat(updatedTotalRoundOff.toFixed(precision)),
                };
                setCart(finalCartObj);
                localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
                let filteredCoupon =
                  finalCartObj?.couponInput?.length > 0 ? finalCartObj?.couponInput?.findIndex((item) => item.mPricingCouponId === verifyCoupons[0].mPricingCouponId) : -1;
                if (filteredCoupon >= 0) {
                  message.success(`Coupon Applied: ${pricingRule.printedName}`);
                } else {
                  discardCoupon = true;
                }
              }

              if (discardCoupon) {
                const serverUrl = process.env.REACT_APP_serverUrl;
                const couponsData = [{ couponCode: couponInput, referenceId: uniqReferenceId }];
                // Function to discard a coupon
                const discardCoupon = async (coupon) => {
                  const discardCouponQuery = {
                    query: `query {
                        discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
                          status
                          message
                        }
                      }`,
                  };
                  try {
                    const response = await Axios({
                      url: serverUrl,
                      method: "POST",
                      data: discardCouponQuery,
                      headers: {
                        "Content-Type": "Application/json",
                        Authorization: `${setAuthTokens}`,
                      },
                    });

                    console.log(`Coupon ${coupon.couponCode} discarded:`, response.data);
                  } catch (error) {
                    console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
                  }
                };
                couponsData.forEach((coupon) => {
                  discardCoupon(coupon);
                });
                if (pricingRule.type === "TD" || pricingRule.type === "TDF") {
                  if (!finalObj) return;
                  finalObj.totalBillDicount = null;
                  setCart(finalObj);
                }
              }
              upsertPOSLog(cart, "CUP");
            } else {
              message.warning("No discount available. Please check the coupon code and try again.");
            }
          } else {
            message.warning("This coupon has expired. Please use a different coupon.");
          }
        } else {
          Sentry.captureException(new Error("Coupon code failed"), {
            extra: {
              couponcode: couponInput,
              referenceId: uniqReferenceId,
            },
          });
          message.warning(`${t("coupon_error")}`);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      message.warning("Multiple entries found for this coupon code. Please contact technical support for assistance.");
    }
    setIsInputFocused(false);
  };

  const removeCoupon = async (coupon) => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const discardCoupon = async () => {
      const discardCouponQuery = {
        query: `query {
            discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
              status
              message
            }
          }`,
      };
      try {
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: discardCouponQuery,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });
      } catch (error) {
        console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
      }
    };
    discardCoupon();
    let latestData = removeAllDiscounts();

    let cartObj = {
      ...latestData,
      customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
    };
    cartObj.couponInput = cartObj.couponInput.filter((couponCode) => couponCode.mPricingCouponId !== coupon.mPricingCouponId);
    let updatedCart = cartObj;
    let cart = cartObj;

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          addToCart.nettotal = parseFloat(addToCart.nettotal.toFixed(precision));
          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    }
    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }

    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice += nettotalFixed;
      updatedTotalItemsQty += item.weight;
      updatedTotalTax += taxAmountFixed;
      updatedTotalDiscounts += discountFixed;

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let lineTax = item.listPrice * (1 + item.taxRate / 100);
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        let unitTax = item.taxAmount / item.weight;
        item.sunitprice = taxIncludeFlag === "Y" ? item.sunitprice : parseFloat((item.sunitprice + unitTax).toFixed(precision));
        const gross_list = taxIncludeFlag === "Y" ? item.listPrice : item.listPrice * (1 + item.taxRate / 100);
        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;
        item.listPrice = taxIncludeFlag === "Y" ? item.listPrice : parseFloat(lineTax.toFixed(precision));
        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? gross_list : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);

    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
  };

  // Side Menu Drawer Starts //

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  const [displayReturnOrderSearch, setDisplayReturnOrderSearch] = useState(false);

  const handleSalesReturnFromSideMenu = () => {
    setSideMenuDrawervisible(false);
    setDisplayReturnOrderSearch(true);
  };

  // Side Menu Drawer Ends //

  // OMS Orders Modal Starts //

  const omsOrderDetailsList = JSON.parse(localStorage.getItem("omsOrderDetails"));
  const initialOmsOrderList = omsOrderDetailsList ? omsOrderDetailsList : [];
  const [omsOrdersList, setOmsOrderList] = useState(initialOmsOrderList);

  const omsOrderStatus = [
    {
      title: "New",
      imgSrc: NewWhite,
      selectedImgSrc: New,
      statusValue: "NE",
    },
    {
      title: "Preparing",
      imgSrc: PreparingWhite,
      selectedImgSrc: Preparing,
      statusValue: "UP",
    },
    {
      title: "Ready",
      imgSrc: ReadyWhite,
      selectedImgSrc: Ready,
      statusValue: "PK",
    },
    {
      title: "Today's Orders",
      imgSrc: CompletedWhite,
      selectedImgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOMSOrderItemsModal, setDisplayOMSOrderItemsModal] = useState(false);
  const [selectedOMSOrderStatus, setSelectedOMSOrderStatus] = useState(omsOrderStatus[0]);

  const [order, setOrder] = useState("");
  const [searchOrders, setSearchOrders] = useState(omsOrdersList);

  const [selectedOrder, setSelectedOrder] = useState({});

  const handleOmsOrders = async () => {
    setSideMenuDrawervisible(false);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salePrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(omsOrdersList, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              omsOrdersList.push(orderObj);
            } else {
              omsOrdersList[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
          getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
          cWCOrderId
          orderNo
          dateCreated
          customerId
          customerName
          mobileNo
          total
          noOfItems
          totalQty
          lineItems{
              productId
              name
              productCode
              quantity
              price
              subTotal
              addOnProducts{
                  id
                  name
                  price
              }
          }
      }
      }`,
    };
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      const { getNewOmsOrders } = response?.data?.data || {};
      if (getNewOmsOrders) {
        const updatedOmsOrders = [...omsOrdersList];

        for (const item of getNewOmsOrders) {
          const obj = {
            ...item,
            status: "NE",
            noOfItems: item.lineItems.length,
            posOrders: "N",
            dateCreated: new Date(item.dateCreated).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
          };

          const findIndex = _.findIndex(updatedOmsOrders, ["cWCOrderId", item.cWCOrderId]);
          if (findIndex === -1) {
            updatedOmsOrders.push(obj);
          } else {
            updatedOmsOrders[findIndex] = obj;

            const prodCodes = _.map(updatedOmsOrders[findIndex]?.lineItems || [], "productCode");
            const productsFetched = await db.products.where("value").startsWithAnyOfIgnoreCase(prodCodes).toArray();

            if (productsFetched?.length > 0) {
              const newLineItems = updatedOmsOrders[findIndex].lineItems.map((lineItem) => {
                const product = productsFetched.find((product) => product.value === lineItem.productCode);
                return product ? { ...lineItem, img: product.imageurl } : { ...lineItem };
              });
              updatedOmsOrders[findIndex].lineItems = newLineItems;
            }
          }
        }

        // Update localStorage and state with the updated orders
        localStorage.setItem("omsOrderDetails", JSON.stringify(updatedOmsOrders));
        setOmsOrderList(updatedOmsOrders);
      }
    } catch (error) {
      console.error("Error processing OMS orders:", error);
    }

    setDisplayOMSOrderItemsModal(true);
  };

  const handleOMSOrderStatusSelection = (record) => {
    setSelectedOMSOrderStatus(record);
  };

  const setOMSStatus = (status) => {
    let newOMSStatus = "";
    switch (status) {
      case "NE":
        newOMSStatus = "UP";
        break;
      case "UP":
        newOMSStatus = "PK";
        break;
      case "PK":
        newOMSStatus = "DE";
        break;
      default:
        newOMSStatus = "NE";
    }
    return newOMSStatus;
  };

  const nextOMSOrderStatus = () => {
    let newOMSOrderStatus = [...omsOrderStatus];
    let statusIndex = _.findIndex(newOMSOrderStatus, (item) => item.title === selectedOMSOrderStatus.title);
    if (statusIndex <= newOMSOrderStatus.length - 1) setSelectedOMSOrderStatus(newOMSOrderStatus[statusIndex + 1]);
  };

  const handleOMSStatusButton = async (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
          updateOMSOrderStatus(order:{
              cWCOrderId: "${record.cWCOrderId}"
              status: "${setOMSStatus(record.status)}"
          })
          {
              status
              message
          }
      }`,
    };
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        const newOMSOrderDetails = omsOrdersList.map((order) => (order.cWCOrderId === record.cWCOrderId ? { ...order, status: setOMSStatus(record.status) } : order));

        localStorage.setItem("omsOrderDetails", JSON.stringify(newOMSOrderDetails));
        setOmsOrderList(newOMSOrderDetails);
        nextOMSOrderStatus();
      } else {
        console.error("Failed to update OMS order status:", response?.data?.data);
      }
    } catch (error) {
      console.error("Error in updating OMS order status:", error);
    }
  };

  const handleOrderCard = (record) => {
    setSelectedOrder(record);
    handleOMSOrderStatusSelection(_.filter(omsOrderStatus, (statusItem) => statusItem.statusValue === record.status)?.[0]);
  };

  const handleOrderSearchInput = (value) => {
    if (value !== "") {
      const results = omsOrdersList.filter((orderDetails) => {
        return orderDetails.orderNo.startsWith(value);
      });
      setSearchOrders(results);
    } else {
      setSearchOrders(omsOrdersList);
    }
    setOrder(value);
  };

  const omsOrderTotalPrice = _.sumBy(selectedOrder.lineItems, "price");

  // OMS Orders Modal Ends //

  // Sales Representative Modal Starts //
  const [openSalesRep, setOpenSalesRep] = useState({ flag: false, level: "" });
  const [salesRepModalOpen, setSalesRepModalOpen] = useState({
    status: false,
    title: "",
  });
  const [salesRepresent, setSalesRepresent] = useState({});
  const [salesRepresentDefaultLine, setSalesRepresentDefaultLine] = useState({ salesRepresentId: null, name: null });

  const handleSalesRepresentive = (record) => {
    if (salesRepModalOpen.title === "orderSalesRep") {
      setSalesRepresentDefaultLine({ ...record });
      setCart({
        ...cart,
        salesRepId: record.salesRepresentId,
      });
      localStorage.setItem(
        "cartObj",
        JSON.stringify({
          ...cart,
          salesRepId: record.salesRepresentId,
        })
      );
      setSalesRepresent(record);
    } else if (salesRepModalOpen.title === "itemSalesRep" && !_.isEmpty(selectedProductInCart)) {
      setSalesRepresentDefaultLine({ ...record });
      let lineItemsData = [...cart.items];
      const lineItemsIndex = _.findIndex(lineItemsData, (item) => item.productId === selectedProductInCart.productId);
      lineItemsData[lineItemsIndex]["salesRepId"] = record.salesRepresentId;
      lineItemsData[lineItemsIndex]["salesRepName"] = record.name;

      setCart({
        ...cart,
        items: lineItemsData,
      });
      localStorage.setItem(
        "cartObj",
        JSON.stringify({
          ...cart,
          items: lineItemsData,
        })
      );
    }
    setSalesRepModalOpen({ status: false, title: "" });
  };

  const [salesRepresentSearchInput, setSalesRepresentSearchInput] = useState("");
  const salesReprestiveList = tillData.tillAccess.csBunit.salesRep;
  const [filteredSalesRepresentList, setFilteredSalesRepresentList] = useState(salesReprestiveList);

  const handleSalesRepresentSearchInput = (value) => {
    if (value !== "") {
      const results = salesReprestiveList.filter((list) => {
        return list.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setFilteredSalesRepresentList(results);
    } else {
      setFilteredSalesRepresentList(salesReprestiveList);
    }
    setSalesRepresentSearchInput(value);
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      if (keyCode === 83) {
        if (posConfig?.showLineSalesRep === "Y") {
          setSalesRepModalOpen({ status: true, title: "itemSalesRep" });
          setSalesRepresentSearchInput("");
          setFilteredSalesRepresentList(salesReprestiveList);
        }
      }
      if (keyCode === 67) {
        setSelectedRowKeys((v) => {
          if (v.length > 0) {
            return [];
          } else {
            return [0];
          }
        });
      }
      if (keyCode === 80) {
        openPaymentModal();
      }

      if (keyCode === 79) {
        setDisplayOrderHistory(true);
      }

      if (keyCode === 66) {
        setDisplayParkedBillModal(true);
      }

      if (keyCode === 73) {
        if (selectedRowKeysRef.current.length > 0) {
          setIsQtyUpdate(selectedProductInCartRef.current);
        }
      }

      if (keyCode === 76) {
        parkBill("parkKey");
      }

      if (keyCode === 72) {
        confirmDiscardCart();
      }
    }

    if (keyCode === 115) {
      setTimeout(() => {
        // productSearchInputRef.current.focus();
      }, 100);
    }
    if (keyCode === 27) {
      if ((e.target.id === "productSearchInputId" || e.target.id.search("productCardItem")) >= 0) {
        closeProductPanel();
      }
      setDisplayBatchSelection((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
      setSalesRepModalOpen({ status: false, title: "" });
      setSalesRepresentSearchInput("");
      closeCustomerSearch();

      if (paymentModalStateRef.current) {
        closePaymentModal();
      }
    }
    if (keyCode === 38) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] > 0) {
          return [v[0] - 1];
        } else {
          return [v[0]];
        }
      });
    }
    if (keyCode === 40) {
      setSelectedRowKeys((v) => {
        if (v.length > 0 && v[0] < cartItemsLengthRef.current - 1) {
          return [v[0] + 1];
        } else {
          return [v[0]];
        }
      });
    }

    if (keyCode === 187 || keyCode === 107) {
      if (selectedRowKeysRef.current.length > 0) {
        increaseProductQty(selectedProductInCartRef.current);
      }
    }
    if (keyCode === 109 || keyCode === 189) {
      if (selectedRowKeysRef.current.length > 0) {
        if (parseFloat(selectedProductInCartRef.current.weight) > 1) {
          decreaseProductQty(selectedProductInCartRef.current);
        }
      }
    }
    if (keyCode === 46) {
      if (selectedRowKeysRef.current.length > 0) {
        deleteProduct(selectedProductInCartRef.current);
      }
    }
  };

  const cartItemsLengthRef = useRef(0);
  const [cartObj, setOrderObj] = useState(null);

  useEffect(() => {
    cartItemsLengthRef.current = cart.items.length;
    setAmount((Math.abs(cart.total) - cart.paid).toFixed(precision));
    let flag = tillLayout !== 1 ? true : false;
    if (Math.abs(cart.total) <= cart.paid && cart.payments.length > 0 && flag && cart.layAway !== "Y") {
      orderState.current = 0;
      setPaymentProcessFlag(false);
      processOrder(cart.sOrderID);
    }
    try {
      const cartDetails = JSON.parse(localStorage.getItem("cartObj"));
      if (cartDetails !== null) {
        setOrderObj(cartDetails);
      }
    } catch (error) {
      console.error("Error parsing cart details:", error);
    }
    if (cart.discount) {
      upsertPOSLog(cart, "DAP");
    }
  }, [cart]);

  const selectedProductInCartRef = useRef({});
  useEffect(() => {
    selectedProductInCartRef.current = selectedProductInCart;
  }, [selectedProductInCart]);

  const selectedRowKeysRef = useRef([0]);
  useEffect(() => {
    selectedRowKeysRef.current = selectedRowKeys;
    if (selectedRowKeys.length > 0) {
      const cartIndex = cart.items.findIndex((ci) => ci.key === selectedRowKeys[0]);
      // setSelectedProductInCart({ ...cart.items[cartIndex] });
    }
  }, [selectedRowKeys]);

  const paymentModalStateRef = useRef(false);
  useEffect(() => {
    paymentModalStateRef.current = paymentModal;
    if (paymentModal) {
      setSelectedPaymentMethod(tillDataPaymentMethods.filter((item, index) => item.isDefault === "Y")?.[0] || {});
    }
  }, [paymentModal]);

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  });

  // Sales Representative Modal Ends //

  const [manualDiscountModalVisible, setManualDiscountModalVisible] = useState(false);
  const [manualDiscountInput, setManualDiscountInput] = useState("");
  const [manualDiscountTypes, setManualDiscountTypes] = useState([]);
  const [selectedManualDiscountType, setSelectedManualDiscountTypeValue] = useState("");
  const [enableManualDiscountInput, setEnableManualDiscountInput] = useState(false);

  useEffect(() => {
    db.pricingRules.toArray().then((pr) => {
      const manualPricingRules = pr.filter((rule) => rule.manualDiscount === "Y");
      setManualDiscountTypes([...manualPricingRules]);
    });
  }, []);

  const setSelectedManualDiscountType = (value) => {
    if (value !== "") {
      const mdi = manualDiscountTypes.findIndex((md) => md.mPricingrulesId === value);
      setSelectedManualDiscountTypeValue(manualDiscountTypes[mdi]);
      const discountValue = manualDiscountTypes[mdi].discountType === "V" ? manualDiscountTypes[mdi].amountDiscount ?? "" : manualDiscountTypes[mdi].percentageDiscount ?? "";
      if (discountValue) {
        setManualDiscountInput(discountValue);
        setEnableManualDiscountInput(true);
      } else {
        setManualDiscountInput("");
        setEnableManualDiscountInput(false);
      }
    }
  };

  const [manualDiscountForm] = Form.useForm();

  const handleManualDiscountKeyPress = (value) => {
    if (!enableManualDiscountInput) {
      if (manualDiscountInput === "" && value === "x") {
        manualDiscountForm.setFieldsValue({ discountValue: "" });
        setManualDiscountInput("");
      } else if (value === "x") {
        const updatedValue = manualDiscountInput.toString().slice(0, -1);
        manualDiscountForm.setFieldsValue({ discountValue: updatedValue });
        setManualDiscountInput(updatedValue);
      } else if (value === "clear") {
        manualDiscountForm.setFieldsValue({ discountValue: "" });
        setManualDiscountInput("");
      } else {
        const newInput = `${manualDiscountInput}${value}`;
        manualDiscountForm.setFieldsValue({ discountValue: newInput });
        setManualDiscountInput(newInput);
      }
    }
  };

  const validateUserPin = async (user, pin) => {
    return pin === "1234";
  };

  const applyManualDiscount = async (values) => {
    setManualDiscountInput("");
    setSelectedManualDiscountTypeValue(null);
    if (selectedManualDiscountType && values.discountName && values.discountValue && cart.items.length > 0) {
      manualDiscountForm.resetFields();
      setManualDiscountModalVisible(false);
      let updatedCart = cart;
      const pr = await db.pricingRules.toArray();
      const pricingRule = pr[pr.findIndex((md) => md.mPricingrulesId === values.discountName && md.manualDiscount === "Y")];
      if (pricingRule.discountType === "P" && values.discountValue > 100 && pricingRule.type === "TD") {
        message.error("Discount Percentage cannot exceed 100%. Please enter a valid percentage");
        return;
      } else if (pricingRule.discountType === "V" && values.discountValue > cart.total) {
        message.error("Discount Amount cannot exceed the Total Bill Amount. Please enter a valid amount");
        return;
      }

      if (values.discountValue > 100 && pricingRule.type === "PD") {
        message.error("Discount Percentage cannot exceed 100%. Please enter a valid percentage");
        return;
      } else if (values.discountValue > selectedProductInCart.nettotal && pricingRule.type === "FD") {
        message.error("Discount Amount cannot exceed the Total Bill Amount. Please enter a valid amount");
        return;
      }
      let approvalFlag = true;
      let discountValue = values.discountValue;
      let matchingApproval;
      const minValue = pricingRule?.mPricingApprovals?.reduce((min, item) => Math.min(min, item.min), Infinity);
      const maxValue = pricingRule?.mPricingApprovals?.reduce((max, item) => Math.max(max, item.max), -Infinity);
      if (values.discountValue >= minValue && values.discountValue <= maxValue) {
        if (pricingRule?.mPricingApprovals?.length > 0 && pricingRule?.enableApproval === "Y") {
          matchingApproval = pricingRule.mPricingApprovals.find((approval) => {
            if (approval.type === "P") {
              if (pricingRule.discountType === "V") {
                if (pricingRule.type === "TD") {
                  discountValue = (values.discountValue / cart.total) * 100;
                } else {
                  discountValue = (values.discountValue / selectedProductInCart.nettotal) * 100;
                }
                return discountValue >= approval.min && (approval?.max ? discountValue <= approval.max : true);
              } else {
                return values.discountValue >= approval.min && (approval?.max ? values.discountValue <= approval.max : true);
              }
            } else if (approval.type === "V") {
              if (pricingRule.discountType === "P") {
                if (pricingRule.type === "TD") {
                  discountValue = (values.discountValue / 100) * cart.total;
                } else {
                  discountValue = (values.discountValue / 100) * selectedProductInCart.nettotal;
                }
                return discountValue >= approval.min && (approval?.max ? discountValue <= approval.max : true);
              } else {
                return values.discountValue >= approval.min && (approval?.max ? values.discountValue <= approval.max : true);
              }
            }
          });
          if (matchingApproval) {
            approvalFlag = await new Promise((resolve) => {
              discountApproval([matchingApproval], (eventData) => {
                resolve(eventData?.approvalGranted);
              });
            });
          }
        }
      } else if (values.discountValue > maxValue) {
        return;
      }

      if (!approvalFlag) return;

      if (pricingRule.type === "FD" && Object.keys(selectedProductInCart).length > 0) {
        try {
          const newCart = await CheckoutFlatDiscount(selectedProductInCart, pricingRule, setCart, updatedCart, orderType, updatedCart, values.discountValue, matchingApproval);
          updatedCart = newCart;
        } catch (err) {
          console.error("Error applying flat discount:", err);
        }
      } else if (pricingRule.type === "PD" && Object.keys(selectedProductInCart).length > 0) {
        try {
          const newCart = await CheckoutPercentageDiscount(
            selectedProductInCart,
            pricingRule,
            setCart,
            updatedCart,
            orderType,
            updatedCart,
            values.discountValue,
            matchingApproval
          );
          updatedCart = newCart;
        } catch (err) {
          console.error("Error applying percentage discount:", err);
        }
      } else if (pricingRule.type === "TD") {
        try {
          const newCart = await CheckoutTotalManualDiscount(pricingRule, setCart, updatedCart, orderType, updatedCart, values.discountValue, matchingApproval);
          updatedCart = newCart;
        } catch (err) {
          console.error("Error applying total manual discount:", err);
        }
      }

      let updatedTotalTax = 0;
      let updatedTotalPrice = 0;
      let updatedTotalItemsQty = 0;
      let updatedTotalDiscounts = 0;

      updatedCart.items = updatedCart?.items?.map((item, i) => {
        const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
        const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
        const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

        // Update aggregated totals
        updatedTotalPrice += nettotalFixed;
        updatedTotalItemsQty += item.weight;
        updatedTotalTax += taxAmountFixed;
        updatedTotalDiscounts += discountFixed;

        // Update individual item properties
        item.discount = discountFixed;
        item.key = i;
        item.nettotal = parseFloat(item.nettotal.toFixed(precision));

        if (!item.isGiftCard) {
          let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
          if (!isFinite(unitPrice)) unitPrice = 0;
          let unitTax = item.taxAmount / item.weight;
          item.sunitprice = taxIncludeFlag === "Y" ? item.sunitprice : parseFloat((item.sunitprice + unitTax).toFixed(precision));
          const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
          const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;

          item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
          item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
          item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
          item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
          item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
          item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
          item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
          item.grosslist = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
          item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
          item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
          item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        }
        return item;
      });

      const sumLineTotals = (lines) => {
        let totalLineGross = 0;
        let totalLineTax = 0;

        lines?.forEach((line) => {
          totalLineGross += line.nettotal;
          totalLineTax += line.taxAmount;
        });

        return { totalLineGross, totalLineTax };
      };
      // Compare and Adjust Order Totals
      const adjustOrderTotals = (order, lines) => {
        const { totalLineGross, totalLineTax } = sumLineTotals(lines);
        let adjustedOrderGross = order.total;
        let adjustedOrderTax = order.tax;

        const grossDifference = totalLineGross - adjustedOrderGross;
        const taxDifference = totalLineTax - adjustedOrderTax;

        if (Math.abs(grossDifference) > 0.01) {
          adjustedOrderGross += grossDifference;
        }

        if (Math.abs(taxDifference) > 0.01) {
          adjustedOrderTax += taxDifference;
        }

        return {
          ...order,
          total: adjustedOrderGross,
          tax: adjustedOrderTax,
        };
      };
      updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);

      let finalCartObj = {
        ...updatedCart,
        items: [...updatedCart?.items],
        discount: updatedTotalDiscounts,
        totalQty: updatedTotalItemsQty,
      };
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
      setCart({ ...finalCartObj });
      upsertPOSLog("DAP");
      if (pricingRule.type === "TD") {
        verifyStock();
      }
    } else {
      message.warning("Please provide a valid input !");
    }
  };

  const processLineManualDiscount = () => {
    const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
    if (pricingRule.type === "V") {
      CheckoutFlatDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }

    if (pricingRule.type === "P") {
      CheckoutPercentageDiscount(cart, setCart, selectedRowKeys, pricingRule, manualDiscountInput, tillaccess);
    }
    setSelectedRowKeys([]);
  };

  const processTotalManualDiscount = (discountParam) => {
    CheckoutTotalManualDiscount(discountParam, setCart, cart, manualDiscountTypes, selectedManualDiscountType, manualDiscountInput, tillaccess);
  };

  const removeAllDiscounts = (discountFlag = false, type = false) => {
    setManualDiscountModalVisible(false);
    setManualDiscountInput(null);
    setSelectedManualDiscountTypeValue(null);
    const cartItems = cart.items;
    let updatedCart = cart;
    function calculateCartItem(addedToCart) {
      const sp = parseFloat(addedToCart.realPrice);
      const mrp = parseFloat(sp) * addedToCart.weight;
      const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addedToCart.taxRate / 100) : mrp * (addedToCart.taxRate / 100);
      addedToCart.salePrice = sp;
      addedToCart.taxAmount = tax;
      addedToCart.mPricingruleId = "";
      addedToCart.nettotal = taxIncludeFlag === "Y" ? mrp : mrp + tax;
      addedToCart.discount = 0;
      addedToCart.discountName = "";
      addedToCart.nextRule = "N";
      delete addedToCart.priority;
      return addedToCart;
    }

    const pricingRuleIndex = manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedProductInCart?.mPricingruleId);

    cartItems.forEach((addedToCart, i) => {
      if ((pricingRuleIndex === undefined || !manualDiscountTypes[pricingRuleIndex]) && !type) {
        updatedCart.manualDiscountData = {
          totalLevelDiscount: [],
          lineLevelDiscount: updatedCart?.manualDiscountData?.lineLevelDiscount || [],
        };
        addedToCart.allDiscounts = [];
        addedToCart.approval = [];
        cartItems[i] = calculateCartItem(addedToCart);
        console.log("Invalid pricingRuleIndex or undefined manualDiscountType");
        return;
      }
      if (discountFlag && manualDiscountTypes[pricingRuleIndex].type !== "TD" && selectedProductInCart.lineId === addedToCart.lineId) {
        updatedCart.manualDiscountData.lineLevelDiscount = updatedCart.manualDiscountData?.lineLevelDiscount?.filter((item) => item.lineId !== addedToCart.lineId);
        addedToCart.allDiscounts = (addedToCart.allDiscounts || [])?.filter((item) => item.mPricingruleId !== manualDiscountTypes[pricingRuleIndex].mPricingrulesId);
        addedToCart.approval = (addedToCart?.approval || [])?.filter((item) => item.mPricingrulesId !== manualDiscountTypes[pricingRuleIndex].mPricingrulesId);
      } else if (
        discountFlag &&
        manualDiscountTypes[pricingRuleIndex].type === "TD" &&
        selectedProductInCart.mPricingruleId === manualDiscountTypes[pricingRuleIndex].mPricingrulesId
      ) {
        updatedCart.manualDiscountData.totalLevelDiscount = updatedCart.manualDiscountData?.totalLevelDiscount?.filter(
          (item) => item.pricingRule !== manualDiscountTypes[pricingRuleIndex].mPricingrulesId
        );
        addedToCart.allDiscounts = (addedToCart.allDiscounts || [])?.filter((item) => item.mPricingruleId !== manualDiscountTypes[pricingRuleIndex].mPricingrulesId);
        addedToCart.approval = (addedToCart?.approval || [])?.filter((item) => item.mPricingrulesId !== manualDiscountTypes[pricingRuleIndex].mPricingrulesId);
      } else if (!discountFlag && Object.keys(selectedProductInCart).length === 0 && !type) {
        updatedCart.manualDiscountData = {};
      }
      cartItems[i] = calculateCartItem(addedToCart);
    });

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;

    for (let i = 0; i < cartItems.length; i += 1) {
      totalPrice += cartItems[i].nettotal;
      totalItemsQty += cartItems[i].weight;
      totalTax += cartItems[i].taxAmount;
      totalDiscounts += cartItems[i].discount;
      cartItems[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    delete cart["manualDiscountApplied"];

    if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
      let obj;
      const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
      db.fbOrderData
        .where("cwrFbTableId")
        .equals(fbOrderData?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (fbOrder) => {
              if (fbOrder.fbOrderStatus === "IP") {
                let orderLines = [];
                fbOrder.cart = {
                  ...cart,
                  items: [...cartItems],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                };
                fbOrder.fbOrderSync = "N";
                fbOrder.lines = orderLines;
                cartItems.map((obj) => {
                  orderLines.push(`{
                    fbOrderId: "${fbOrder.fbOrderId}"
                    fbOrderLineId: "${obj.fbOrderLineId}"
                    mPoductId: "${obj.productId}"
                    mBatchId: null
                    description: "good"
                    csUomId: "${obj.uom}"
                    csTaxId: "${obj.tax}"
                    discount:${obj.discount}
                    line: 1
                    qty: ${obj.weight}
                    unitPrice: ${obj.realPrice}
                    listPrice: 30
                    lineNet: 2.6
                    lineTax: ${obj.taxRate}
                    lineGross: 30
                    sOrderLineId: null
                    isOrdered: "Y"
                    meta:[]
                    }`);
                });
                await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                obj = {
                  fbOrder: {
                    fbOrderId: fbOrder.fbOrderId,
                    order: fbOrder,
                  },
                };
                // sendOrder(obj);
              }
            });
          }
        });
      SyncData(fbOrderData, "upsertFbOrder");
    }
    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    if (updatedCart?.manualDiscountData?.lineLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.lineLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        let productSelected = updatedCart.items.filter((cartItem) => cartItem.productId === item.productId);
        if (pricingRule?.type === "FD") {
          try {
            const newCart = await CheckoutFlatDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying flat discount:", err);
          }
        } else if (pricingRule?.type === "PD") {
          try {
            const newCart = await CheckoutPercentageDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying percentage discount:", err);
          }
        }
      });
    }

    if (updatedCart?.manualDiscountData?.totalLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.totalLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        if (pricingRule?.type === "TD") {
          try {
            const newCart = await CheckoutTotalManualDiscount(pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying Total discount:", err);
          }
        }
      });
    }

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice = parseFloat((updatedTotalPrice + nettotalFixed).toFixed(precision));
      updatedTotalItemsQty = parseFloat((updatedTotalItemsQty + item.weight).toFixed(precision));
      updatedTotalTax = parseFloat((updatedTotalTax + taxAmountFixed).toFixed(precision));
      updatedTotalDiscounts = parseFloat((updatedTotalDiscounts + discountFixed).toFixed(precision));

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      // if (!item.isGiftCard) {
      //   let lineTax = item.listPrice * (1 + item.taxRate / 100);
      //   let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
      //   if (!isFinite(unitPrice)) unitPrice = 0;

      //   let unitTax = item.taxAmount / item.weight;
      //   item.sunitprice = item.realPrice;
      //   const gross_list = taxIncludeFlag === "Y" ? item.listPrice : item.listPrice * (1 + item.taxRate / 100);
      //   const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
      //   const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;
      //   item.listPrice = taxIncludeFlag === "Y" ? item.listPrice : parseFloat(lineTax.toFixed(precision));
      //   item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      //   item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
      //   item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
      //   item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
      //   item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
      //   item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
      //   item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
      //   item.grosslist = Math.abs(nettotalFixed) > 0 ? gross_list : 0;
      //   item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
      //   item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
      //   item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      // }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);
    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      total: updatedTotalPrice,
      tax: updatedTotalTax,
      totalDiscountFlag: !discountFlag || Object.keys(selectedProductInCart).length === 0 ? false : true,
    };
    if (discountFlag) {
      setCart(finalCartObj);
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    }
    return finalCartObj;
  };

  const clearSelectedProductInCart = () => {
    setSelectedProductInCart({});
    // setTimeout(()=>{
    setSelectedRowKeys([]);
    // },100)
  };

  function parseDateTime(dateTimeString) {
    return new Date(dateTimeString.replace(" ", "T") + "Z");
  }

  function calculateDuration(startTime, endTime) {
    const durationMilliseconds = endTime - startTime;
    const totalSeconds = Math.floor(durationMilliseconds / 1000);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return { hours, minutes, seconds };
  }

  function formatDuration(duration) {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(duration.hours).padStart(2, "0");
    const minutes = String(duration.minutes).padStart(2, "0");
    const seconds = String(duration.seconds).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const posLogActivity = (record, activity) => {
    const timeMark = timeStamp();
    const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
    let products = "";
    let formattedDuration = "";

    // Calculate duration if orderTimeDetails are present
    if (record.orderTimeDetails) {
      const { orderStartTime, orderEndTime } = record.orderTimeDetails;
      const startTime = parseDateTime(orderStartTime);
      const endTime = parseDateTime(orderEndTime);
      const duration = calculateDuration(startTime, endTime);
      formattedDuration = formatDuration(duration);
    }
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();

    if (_.isArray(record)) {
      record.forEach((item) => {
        products += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.salePrice}, `;
      });
    } else {
      products = `SKU: ${record.value}, Qty: ${record.weight}, Unit Price: ${record.salePrice}, `;
    }

    db.logInformation.add({
      type: activity,
      action: "LOG",
      description: activity === "DLN" || activity === "DOR" || activity === "RQT" || activity === "SLR" ? products : activity === "SRD" || activity === "ACT" ? record : "",
      date: currentDate,
      time: timeMark,
      orderNo: `${cart.documentno}`,
      remarks: "",
      transactionId: `${trxId}`,
      status: "SCS",
      duration: formattedDuration ? `"${formattedDuration}"` : null,
    });
  };

  // Paytm QR Code Starts //

  const [paytmQrCodeModalOpens, setPaytmQrCodeModalOpens] = useState(false);
  const [qrCodeResponse, setQrCodeResponse] = useState({});

  const handleVerifyPayment = () => {
    let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    let verifyPaytmUrl = `${hostUrl}paytm/verifyPayment`;
    const getPaymentSuccessValues = {
      midId: "Excelo34085435005810",
      orderId: `${cart.sOrderID}`,
      merchantKey: "qQUxrwRx@qE6zTxt",
      payUrlForVerify: "https://securegw-stage.paytm.in/v3/order/status",
      clientId: "C11",
      version: "v1",
    };
    Axios({
      url: verifyPaytmUrl,
      method: "POST",
      data: getPaymentSuccessValues,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authHeaders.access_token}`,
      },
    }).then((response) => {
      if (response.data.body.resultInfo.resultStatus === "TXN_SUCCESS") {
        setPaytmQrCodeModalOpens(false);
        processPayment(selectedPaymentMethod, amount);
      }
    });
  };

  // Paytm QR Code Ends //
  const [customerFlag, setCustomerFlag] = useState(true);

  const removeCutomer = async () => {
    setCustomerFlag(true);
    let latestData = removeAllDiscounts(false, true);
    let cartObj = {
      ...latestData,
      customer: defaultCustomer,
    };
    let updatedCart = cartObj;
    updatedCart = await handleDeleteLoyaltyPayment(updatedCart, latestData.customer);

    if (cartObj.items.length > 0) {
      await Promise.all(
        cartObj.items.map(async (ele) => {
          let addToCart = ele;
          let taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
          addToCart.sunitprice = ele?.originalPrice || ele?.sunitprice;
          addToCart.price = ele?.originalPrice || ele?.sunitprice;
          addToCart.salePrice = ele?.originalPrice || ele?.sunitprice;
          addToCart.realPrice = ele?.originalPrice || ele?.sunitprice;
          const addOnsPriceSum = _.sumBy(addToCart?.selectedAddons, "price");
          const mrp = (parseFloat(addToCart.salePrice) + addOnsPriceSum * addToCart.weight) * parseFloat(addToCart.weight);
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + addToCart.taxRate / 100) : mrp * (addToCart.taxRate / 100);
          addToCart.taxAmount = tax;
          addToCart.nettotal =
            taxIncludeFlag === "Y"
              ? parseFloat((mrp - parseFloat(addToCart.discount ? addToCart.discount : 0)).toFixed(precision))
              : parseFloat((mrp - parseFloat(addToCart.discount ? addToCart.discount : 0)).toFixed(precision)) + tax;

          updatedCart = await pricingRuleController(addToCart, cartObj, cartObj, setCart, cartObj, orderType);
          return updatedCart;
        })
      );
    } else {
      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        orderStartTime: "", // Update orderStartTime to current time
      };
      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    }

    if (cartObj.totalDiscountFlag) {
      await openPaymentModalByCustomer(updatedCart);
    }

    if (updatedCart?.couponInput?.length > 0) {
      let addToCart = cartObj.items[0];
      await Promise.all(
        updatedCart.couponInput.map(async (coupon) => {
          const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(coupon.mPricingruleId).toArray();
          if (matchingPricingRules[0].type !== "TD" && matchingPricingRules[0].type !== "TDF") {
            updatedCart = await pricingRuleController(
              addToCart,
              updatedCart,
              cart,
              setCart,
              cartRef,
              orderType,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          } else {
            updatedCart = await processBillDiscounts(
              matchingPricingRules[0],
              updatedCart,
              true,
              coupon.couponCode,
              coupon.referenceId,
              coupon.mPricingCouponId,
              coupon.mPricingruleId
            );
          }
        })
      );
    }

    if (updatedCart?.manualDiscountData?.lineLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.lineLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        let productSelected = updatedCart.items.filter((cartItem) => cartItem.productId === item.productId);
        if (pricingRule?.type === "FD") {
          try {
            const newCart = await CheckoutFlatDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying flat discount:", err);
          }
        } else if (pricingRule?.type === "PD") {
          try {
            const newCart = await CheckoutPercentageDiscount(productSelected[0], pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying percentage discount:", err);
          }
        }
      });
    }

    if (updatedCart?.manualDiscountData?.totalLevelDiscount?.length > 0) {
      updatedCart.manualDiscountData.totalLevelDiscount.map(async (item) => {
        const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === item.pricingRule)];
        if (pricingRule?.type === "TD") {
          try {
            const newCart = await CheckoutTotalManualDiscount(pricingRule, setCart, updatedCart, orderType, updatedCart, item.discountValue);
            updatedCart = newCart;
          } catch (err) {
            console.error("Error applying Total discount:", err);
          }
        }
      });
    }

    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;

    updatedCart.items = updatedCart.items.map((item, i) => {
      const nettotalFixed = parseFloat(item.nettotal.toFixed(precision));
      const taxAmountFixed = parseFloat(item.taxAmount.toFixed(precision));
      const discountFixed = item.discount ? parseFloat(item.discount.toFixed(precision)) : 0;

      // Update aggregated totals
      updatedTotalPrice = parseFloat((updatedTotalPrice + nettotalFixed).toFixed(precision));
      updatedTotalItemsQty = parseFloat((updatedTotalItemsQty + item.weight).toFixed(precision));
      updatedTotalTax = parseFloat((updatedTotalTax + taxAmountFixed).toFixed(precision));
      updatedTotalDiscounts = parseFloat((updatedTotalDiscounts + discountFixed).toFixed(precision));

      // Update individual item properties
      item.discount = discountFixed;
      item.key = i;
      item.nettotal = parseFloat(item.nettotal.toFixed(precision));

      if (!item.isGiftCard) {
        let lineTax = item.listPrice * (1 + item.taxRate / 100);
        let unitPrice = item.nettotal / item.weight - (item.nettotal / item.weight / 100) * item.taxRate;
        if (!isFinite(unitPrice)) unitPrice = 0;

        let unitTax = item.taxAmount / item.weight;
        item.sunitprice = taxIncludeFlag === "Y" ? item.sunitprice : parseFloat((item.sunitprice + unitTax).toFixed(precision));
        const gross_list = taxIncludeFlag === "Y" ? item.listPrice : item.listPrice * (1 + item.taxRate / 100);
        const grossUnit = Math.abs(item.sunitprice) - Math.abs(item.discount) / Math.abs(item.weight);
        const netList = taxIncludeFlag === "Y" ? (item.listPrice / (1 + item.taxRate / 100)).toFixed(precision) : item.listPrice;
        item.listPrice = taxIncludeFlag === "Y" ? item.listPrice : parseFloat(lineTax.toFixed(precision));
        item.linetax = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
        item.linenet = Math.abs(nettotalFixed) > 0 ? parseFloat((item.nettotal - item.taxAmount).toFixed(precision)) : 0;
        item.linegross = Math.abs(nettotalFixed) > 0 ? nettotalFixed : 0;
        item.netunit = Math.abs(nettotalFixed) > 0 ? unitPrice.toFixed(precision) : 0;
        item.listprice = Math.abs(nettotalFixed) > 0 ? item.listPrice : 0;
        item.grossunit = Math.abs(nettotalFixed) > 0 ? parseFloat(grossUnit.toFixed(precision)) * (item.isReturn ? -1 : 1) : 0;
        item.grossstd = Math.abs(nettotalFixed) > 0 ? item.sunitprice : 0;
        item.grosslist = Math.abs(nettotalFixed) > 0 ? gross_list : 0;
        item.netList = Math.abs(nettotalFixed) > 0 ? netList : 0;
        item.unitPrice = Math.abs(nettotalFixed) > 0 ? unitPrice : 0;
        item.taxAmount = Math.abs(nettotalFixed) > 0 ? taxAmountFixed : 0;
      }
      return item;
    });

    const sumLineTotals = (lines) => {
      let totalLineGross = 0;
      let totalLineTax = 0;

      lines.forEach((line) => {
        totalLineGross += line.nettotal;
        totalLineTax += line.taxAmount;
      });

      return { totalLineGross, totalLineTax };
    };
    // Compare and Adjust Order Totals
    const adjustOrderTotals = (order, lines) => {
      const { totalLineGross, totalLineTax } = sumLineTotals(lines);
      let adjustedOrderGross = order.total;
      let adjustedOrderTax = order.tax;

      const grossDifference = totalLineGross - adjustedOrderGross;
      const taxDifference = totalLineTax - adjustedOrderTax;

      if (Math.abs(grossDifference) > 0.01) {
        adjustedOrderGross += grossDifference;
      }

      if (Math.abs(taxDifference) > 0.01) {
        adjustedOrderTax += taxDifference;
      }

      return {
        ...order,
        total: adjustedOrderGross,
        tax: adjustedOrderTax,
      };
    };
    updatedCart = adjustOrderTotals(updatedCart, updatedCart.items);

    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      total: updatedTotalPrice,
      tax: updatedTotalTax,
    };
    localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
    setCart({ ...finalCartObj });
    setShowPaymentMethods(false);
    upsertPOSLog(cart, "RCT");
  };

  const handleLoyaltyPayment = async (payment, cartData) => {
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const serverUrl = process.env.REACT_APP_serverUrl;
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    let value = parseFloat((payment.amount / cart.customer.redeemptionValue).toFixed(2));
    if (cartData.customer.loyaltyLevel.cwrLoyaltyLevelId) {
      return Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
                      upsertLoyaltyTransaction(transaction: [{
                          csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
                          loyaltyPoints: ${value * -1}
                          b2cCustomerId: "${cartData.customer.cwrCustomerId}"
                          referenceId: "${cartData.referenceId}"
                        }]) {
                        status   
                        message
                      }
                    }
                    `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      })
        .then(async (loyalityResponse) => {
          cartData.customer.retlLoyaltyBalance = (cartData?.customer?.retlLoyaltyBalance || 0) + parseFloat(value);
        })
        .catch((err) => {
          message.error("Network Error");
        });
    }
  };

  const handleDeleteLoyaltyPayment = async (updatedCart, customer) => {
    let newCart = JSON.parse(JSON.stringify(updatedCart));
    newCart.customer = customer;
    const matchedPayment = updatedCart.payments.find((pay) => pay.name.toLowerCase() === "loyalty");
    if (!matchedPayment) return updatedCart;
    updatedCart.payments = updatedCart.payments.filter((pay) => pay.name.toLowerCase() !== "loyalty");
    updatedCart.paid = updatedCart.payments.reduce((sum, pay) => sum + parseFloat(pay.amount), 0);
    await handleLoyaltyPayment(matchedPayment, newCart);
    updatedCart.balance = parseFloat((updatedCart.total - updatedCart.paid).toFixed(2));
    return updatedCart;
  };

  // Bill Management //

  const handleManagement = () => {
    // history.push("/bill-management");
    setManagementScreenShow(true);
  };

  const [managementScreenShow, setManagementScreenShow] = useState(false);
  const [orderTypeSelection, setOrderTypeSelection] = useState("Dine In");

  const changeCustomerId = (obj) => {
    let cartDetails = JSON.parse(localStorage.getItem("cartObj"));
    cartDetails.customer.cwrCustomerId = obj.customer.b2cCustomerId;
    localStorage.setItem("cartObj", JSON.stringify(cartDetails));
  };

  const componentProps = {
    tableCards,
    setTableCards,
    keyValue,
    setKeyValue,
    changeCustomerId,
    showProducts,
    setShowProducts,
    handleSelectedSaleTypeForProducts,
    checkIsManualWeight,
    parkBill,
    openPaymentModal,
    addProduct,
    cart,
    setCart,
    cartObj,
    setOrderObj,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    decreaseProductQty,
    deleteCart,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    setIsProductsVisible,
    isProductsVisible,
    getSearchedItem,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    loading,
    setLoading,
    onBarcodeInput,
    openDisplayParkedBillModal,
    orderType,
    parkedList,
    setAllProductCategories,
    allProductCategories,
    handleBrandCheckboxChange,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    productSearchInput,
    filtersFlag,
    setFiltersFlag,
    // productSearchInputRef,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    selectedProductQty,
    selectProduct,
    selectSalseProduct,
    selectedProductInCart,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    searchHistoryInput,
    setSearchhistoryInput,
    setDisplayCustomerSearch,
    displayUAECustomerSearch,
    setDisplayUAECustomerSearch,
    displayUAECustomer,
    setDisplayUAECustomer,
    setDisplayOrderType,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    setParkedBillSearchInput,
    showOrderHistory,
    tillData,
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCloseCustomerFlag,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    showAddNewUAECustomerFields,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    UAECustomerForm,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editFlag,
    setEditFlag,
    editOldCustomer,
    displayOrderHistory,
    setDisplayOrderHistory,
    changeOrderHistorySearchType,
    searchOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    filterdParkedList,
    parkedList,
    setFilterdParkedList,
    discardParkedBill,
    selectParkedBill,
    selectLayAwayOrder,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    requestPayment,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    handleCashPayment,
    overPayedAmount,
    setOverPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    onChangeTotalQuantity,
    displaySetOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    setDefaultImage,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    couponInput,
    setCouponInput,
    handleCouponInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    processOtpInput,
    loyaltyInputValue,
    setLoyaltyInputValue,
    handleLoyalityInput,
    checkLoyality,
    loyaltyPaymentOtp,
    setLoyaltyPaymentOtp,
    currencyType,
    setCurrencyType,
    setDisplayClock,
    pickProduct,
    addDefinedProduct,
    handleWeightManual,
    selectedProductCategory,
    filterDrawer,
    setFilterDrawer,
    // OMS Order MOdal and Side menu drawer//,
    openSideMenu,
    onClose,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    sideMenuDrawervisible,
    setDisplayOfferProductSelection,
    // Sales Representative Modal //
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresent,
    setSalesRepresent,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    salesReprestiveList,
    filteredSalesRepresentList,
    setFilteredSalesRepresentList,
    prevProductsListRef,
    prevHistoryRef,
    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    manualDiscountForm,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    clearSelectedProductInCart,
    setProductWeightModalInput,
    productListCardRef,
    paymentModalByCustomerState,
    posLogActivity,
    upsertPOSLog,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    setQrCodeResponse,
    handleVerifyPayment,
    removeCutomer,
    handleDeleteLoyaltyPayment,
    handleLoyaltyPayment,
    posConfig,
    displayReturnOrderSearch,
    setDisplayReturnOrderSearch,
    handleSalesReturnFromSideMenu,
    // Bill Management //
    handleManagement,
    managementScreenShow,
    setManagementScreenShow,
    orderTypeSelection,
    setOrderTypeSelection,
    // Cash Management
    setAddCashFlag,
    addCashFlag,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    setCouponModalVisible,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    setSelectedKeys,

    // payment
    setQtyNumberFlag,
    setAmount,
    setNumb,
    amount,
    onChangeAmount,
    processOrder,
    paymentModalLoyalityMessages,
    paymentModalInputRef,
    quantityInputRef,
    // Cash Management
    setCashAddInFlag,
    cashAddInFlag,
    handleCahInOut,
    cashManagementForm,
    cashIn,
    pettCashIn,
    onChangeCheckbox,
    setSelectedProductInCart,
    // kiosk Screen
    kioskUI,
    setKioskUI,
    kioskLogin,
    layoutType,
    setLayoutType,
    kioskFilteredProducts,
    // gift card
    giftCardFlag,
    setGiftCardFlag,
    isGiftCardFlag,
    setIsGiftCardFlag,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    giftCardItems,
    CardPaymentForm,
    setGiftCardItems,
    setAddToBagProducts,
    setAddToBagFlag,
    addToBagFlag,
    addToBagProducts,
    giftCardType,
    setGiftCardType,
    selectGiftCardItem,
    setSelectGiftCardItem,
    validateGiftCard,
    setValidateGiftCard,
    validateGiftCardForm,
    giftCardBalance,
    setGiftCardBalance,
    handleGiftCardDetails,
    redeemGiftCard,
    giftCardData,
    setGiftCardData,
    handleGiftCard,
    giftCardForm,
    // keybord
    setKeyboardType,
    keyboardType,
    layout,
    setLayout,
    inputName,
    setInputName,
    keyboardInputFields,
    setKeyboardInputFields,
    keyboardParkbill,
    keyboardRef,
    keyboardProduct,
    handleKeyboardInput,
    handleKeyPress,
    orderHistorySearchInputRef,
    orderHistoryInput,
    setLoader,
    openPaymentModalByCustomer,
    paymentProcessFlag,
    setPaymentProcessFlag,
    selectedEditOldCustomer,
    setIsInputFocused,
    isInputFocused,
    inputFocused,
    setInputFocused,
    handleSelectProduct,
    getCategoryProducts,
    setSalesHistoryType,
    salesHistoryType,
    setStartRowData,
    setFilterdDate,
    startRowData,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
    documentSequence,
    setDocumnetSequence,
    customerFlag,
    setCustomerFlag,
    notesValue,
    setNotesValue,
    selectedProductForNotes,
    setSelectedProductForNotes,
    productsData,
    orderDelay,
    setOrderDelay,
    setProductsData,
    stockList,
    setStockList,
    // restaurantProductCategory,
    removeCoupon,
    // product add on
    setDisplayAddOnSelection,
    displayAddOnSelection,
    handleAddOnValue,
    handleQty,
    handleAdd,
    addOnsList,
    handleAddOnModalClose,
    selectedAddons,
    selectedProduct,
    setSelectedProduct,
    isUpdatingAddon,
    setIsUpdatingAddon,
    addDefinedProductWithAddons,
    handleAddOnModal,
    dynamicForm,
    setDynamicForm,
    dynamicModalForm,
    submitCustomAttributs,
    isSubmitting,
    setIsSubmitting,
    showSaleType,
    setShowSaleType,
    isSaleTypeFlag,
    setIsSaleTypeFlag,
    selectedSaleType,
    setSelectedSaleType,
    selectedSaleTypeData,
    setSelectedSaleTypeData,
    openSalesRep,
    setOpenSalesRep,
    verifyStock,
    isStockModal,
    setIsStockModal,
    showPayments,
    setShowPayments,
    setStartIndex,
    startIndex,
    getFilteredPaymentMethods,
    handleCartTotalClick,
    isShowingAll,
    setIsShowingAll,
    filteredPaymentMethods,
    initialShowPayments,
    tempCart,
    setTempCart,
    callGetWeightAPI,
    open,
    setOpen,
    productSearchInputRef,
    layoutName,
    setLayoutName,
    giftCardRef,
    searchTerm,
    setSearchTerm,
    salesRepRef,
    salesRepValue,
    setSalesRepValue,
    customerSearchInputRef,
    processBillDiscounts,
    currentInput,
    setCurrentInput,
    inputValues,
    setInputValues,
    refs,
    currenciesData,
    selectedDocumentType,
    setSelectedDocumentType,
    showDocumentPopup,
    setShowDocumentPopup,
    documentTypes,
    keyboard,
    getSalesHistoryData,
    setSelectedManualDiscountTypeValue,
    saleTypeProducts,
    setSaleTypeProducts,
    cardPaymnetStatus,
    setCardPaymnetStatus,
    cardPaymnetError,
    setCardPaymnetError,
    isCardPaymentLoading,
    setIsCardPaymentLoading,
  };

  return (
    <div>
      <RenderComponent {...componentProps} />
      <CoreModals {...componentProps} />
      <ReturnBill {...componentProps} />
      <PointOfSuperMarketModals {...componentProps} />

      <Modal
        visible={productStock}
        footer={null}
        closable={false}
        centered
        style={{
          textAlign: "center",
          width: "150px",
          height: "70vh",
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            fontSize: "1vw",
          }}
        >
          <img src={NotFoundImg} alt="Stock Unavailable" style={{ width: "120px", height: "100px", marginBottom: "5px", marginTop: "10px" }} />
          <p style={{ margin: "5px 0", fontSize: "2em", fontWeight: "600", color: "#0F0718" }}>Stock is not available</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5)" }} />
          <p
            style={{
              color: "#0F0718",
              fontWeight: 400,
              fontSize: "1.25em",
              padding: "15px 15px 0 15px",
            }}
          >
            The item is not available in stock.
          </p>
        </div>
      </Modal>
    </div>
  );
};
// PointOfSale Component End

export default PointOfsaleCore;
