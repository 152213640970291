import { message, Modal } from "antd";
import { innovitiPaymentCodes } from "../../../../constants/config";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import axios from "axios";

export const paymentProvider = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setPaytmQrCodeModalOpens,
  setQrCodeResponse,
  setCardPaymnetError,
  setCardPaymnetStatus,
  setLoading,
  setIsCardPaymentLoading
) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  if (currentPaymentMethod.paymentProvider === "INV" && currentPaymentMethod.integratedPayment === true) {
    processInnovitiEDCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setLoading
    );
  } else if (currentPaymentMethod.paymentProvider === "PTM" && currentPaymentMethod.integratedPayment === true) {
    processPaytmEDCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setLoading
    );
  } else if (currentPaymentMethod.paymentProvider === "ADC" && currentPaymentMethod.integratedPayment === true) {
    processADCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setCardPaymnetError,
      setCardPaymnetStatus,
      setLoading,
      setIsCardPaymentLoading
    );
  } else if (currentPaymentMethod.paymentProvider === "PLB" && currentPaymentMethod.integratedPayment === true) {
    processPLBPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setCardPaymnetError,
      setCardPaymnetStatus,
      setLoading,
      setIsCardPaymentLoading
    );
  } else if (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === "PTM") {
    processQrPaytmPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setPaytmQrCodeModalOpens,
      setQrCodeResponse,
      setLoading
    );
  } else if (currentPaymentMethod.integratedPayment === true) {
    message.error("Invalid payment provider configuration");
  }
};

const processInnovitiEDCPayment = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setLoading
) => {
  const edcUrl = process.env.REACT_APP_edcUrl;
  if (amountAdded >= 1) {
    const requestCode = innovitiPaymentCodes[currentPaymentMethod.name];
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();
    const cashierId = tillData.tillAccess.csUserId;
    const customerMobileNo = cart.customer.mobileNo;
    setLoader(true);
    setPaymentModal(false);
    console.info(`Innoviti Request URL: ${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`);
    Axios.get(`${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`)
      .then((response) => {
        const result = response.data;
        const { ResponseCode, ResponseMessage } = result;
        if (ResponseCode === "00") {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.success("Payment Success");
          completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
        } else {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.error(`Payment Failed: ${ResponseMessage}`);
        }
      })
      .catch((error) => {
        console.error("Payment Failed:", error);
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
        message.error("Payment Failed: Transaction timeout / Check EDC Connection");
      });
  } else {
    console.warn("Minimum amount not satisfied");
  }
};

const cancelPaytmVerifyPaymentRequest = (options) => {
  options.data.type = 6;
  Axios(options);
};

const paytmVerifyEDCPaymentStatus = (options) => {
  return new Promise(async function (verify) {
    Modal.confirm({
      title: "Payment request initiated",
      content: "Please verify",
      okText: "Verify",
      onOk: () => {
        verify("retry");
      },
      onCancel: () => {
        cancelPaytmVerifyPaymentRequest(options);
        verify("cancel");
      },
    });
  });
};

const chekPaytmEDCPaymentStatus = (options) => {
  return new Promise(async function (process, reject) {
    let retries = true;
    while (retries) {
      try {
        const response = await Axios(options);
        const result = response.data;
        // const result = { "result": 0, "resultCode": "101", "resultMessage": "Transaction already present", "transactionID": null, "authorizationID": null, "request": null, "properties": null };
        if (result.result?.toString() === "200") {
          retries = false;
          process(true);
        } else if (result.result?.toString() === "202" || result.result?.toString() === "203") {
          message.info(result.resultMessage);
          const retryStatus = await paytmVerifyEDCPaymentStatus(options);
          if (retryStatus === "retry") {
            retries = true;
          } else {
            message.error("Payment canceled");
            retries = false;
            process(false);
          }
        } else {
          message.error(result.resultMessage);
          retries = false;
          process(false);
        }
      } catch (err) {
        retries = false;
        reject(err);
      }
    }
  });
};

const processPaytmEDCPayment = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setLoading
) => {
  const trxId = uuidv4().replace(/-/g, "").toUpperCase();
  setLoader(true);
  if (tillLayout === 2) {
    setPaymentModal(true);
  }
  const requestData = {
    url: `${tillData.tillAccess.cwrTill.hardwareController.imageUrl}payment`,
    method: "POST",
    data: {
      type: 0,
      transactionID: trxId,
      terminalID: "111",
      currency: "INR",
      amount: amountAdded,
      properties: {},
      paymentProvider: "Paytm",
    },
    headers: {
      "Content-Type": "Application/json",
    },
  };

  chekPaytmEDCPaymentStatus(requestData)
    .then((response) => {
      if (response) {
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
        message.success("Payment Success");
        completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
      } else {
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
      }
    })
    .catch((error) => {
      console.error("Payment Failed:", error);
      setLoader(false);
      if (tillLayout === 2) {
        setPaymentModal(true);
      }
      message.error("Payment Failed: Transaction timeout / Check EDC Connection");
    });
};

const processQrPaytmPayment = async (amountAdded, currentPaymentMethod, paymentMethod, value, tillData, cart, setPaytmQrCodeModalOpens, setQrCodeResponse) => {
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  setPaytmQrCodeModalOpens(true);
  let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let paytmUrl = `${hostUrl}paytm/generateQRCode`;
  const getQRCodeValues = {
    midId: "Excelo34085435005810",
    orderId: `${cart.sOrderID}`,
    amount: amountAdded,
    businessType: "UPI_QR_CODE",
    posId: `${tillData.tillAccess.cwrTill.cwrTillID}`,
    merchantKey: "qQUxrwRx@qE6zTxt",
    payTMQRUrl: "https://securegw-stage.paytm.in/paymentservices/qr/create",
    clientId: "C11",
    version: "v1",
  };
  await Axios({
    url: paytmUrl,
    method: "POST",
    data: getQRCodeValues,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${setAuthTokens}`,
    },
  }).then((response) => {
    setQrCodeResponse(response.data);
  });
};

const processADCPayment = async (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setLoading,
  setIsCardPaymentLoading
) => {
  let setAuthTokens;
  try {
    // Get OAuth headers
    const authHeaders = await getOAuthHeaders();
    setAuthTokens = authHeaders?.access_token ?? null; // Default to null if access_token is not available

    if (!setAuthTokens) {
      throw new Error("Authorization token is missing");
    }

    // Prepare ADC values
    const adcUrl = `${tillData?.tillAccess?.cwrTill?.pHWController?.imageUrl ?? ""}payment`;
    const tillValue = JSON.parse(localStorage.getItem("tillValue")) || {};
    const nameTill = tillValue?.name ?? "UnknownTill";
    const adcValues = {
      type: 0,
      terminal: nameTill,
      transaction: `${tillData?.tillAccess?.cwrTill?.prefix ?? ""}${cart?.documentno ?? ""}`,
      ReceiptNo: `${tillData?.tillAccess?.cwrTill?.prefix ?? ""}${cart?.documentno ?? ""}`,
      amount: amountAdded - (cart?.paid ?? 0),
      currency: tillData?.tillAccess?.csBunit?.currencies[0]?.isoCode ?? "USD",
      properties: {},
      test: true,
    };

    // Make POST request to ADC URL
    const response = await Axios.post(adcUrl, adcValues, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
    });

    // Handle ADC response
    if (response?.data?.resultCode === "00" || response?.data?.resultCode === "0000") {
      cart.cardPaymentData.payload = [adcValues];
      cart.cardPaymentData.response = [response.data];
      paymentMethod.authorization = response?.data?.authorization ?? "";
      completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
    } else {
      const errorMessage = response?.data?.resultMessage ?? "Unknown port error";
      if (errorMessage.toLowerCase().includes("port error")) {
        Modal.error({
          title: "Payment Error",
          content: "Payment processing failed. Please try again. If the problem persists, contact support.",
        });
      } else {
        Modal.error({
          title: "Payment Error",
          content: errorMessage,
        });
      }

      // Log the error to the server
      const serverUrl = process.env.REACT_APP_serverUrl ?? "";
      const cleanToken = authHeaders?.access_token ?? "";
      const additionalData = {
        tillValue: JSON.parse(localStorage.getItem("tillValue")) || {},
        adcUrl,
        adcValues,
      };
      const exceptionData = {
        CardPaymentError: {
          response: response?.data ?? {},
          requestData: response?.data?.request ?? {},
          ...additionalData,
        },
      };
      const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
      const workFlowResponse = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            upsertWorkflowEventStore(eventStore: [{
               aggregatorId:  "${uniqueId}"
               aggregatorType: "POS"
               eventType: "CE"
               eventData: "${JSON.stringify(exceptionData).replace(/"/g, '\\"')}"
            }]) {
              status
              message
            }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: cleanToken,
        },
      });

      console.log(workFlowResponse?.data?.data?.upsertWorkflowEventStore?.message ?? "Workflow event store error");
      Sentry.captureException(new Error("Card payment failed"), {
        extra: exceptionData,
      });
    }
  } catch (error) {
    Modal.error({
      title: "Payment Error",
      content: "Payment processing failed. Please try again. If the problem persists, contact support.",
    });
    console.error(error);
    Sentry.captureException(error);
  }
};

const processPLBPayment = async (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  // setShowCardPaymnteModal,
  setCardPaymnetError,
  setCardPaymnetStatus,
  setLoading,
  setIsCardPaymentLoading
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}/api/paymentGateway/pineLabs`;
  const cartObj = JSON.parse(localStorage.getItem("cartObj"));
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (!cartObj || !userData) {
    console.error("Cart or User data missing from local storage.");
    return;
  }
  setLoading(true);
  setIsCardPaymentLoading(true);

  try {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const paymentMethodList = tillData.tillAccess?.csBunit?.paymentMethodList;
    const tillId = tillData.tillAccess?.cwrTill?.cwrTillID;
    const paymentMethod = paymentMethodList?.filter((item) => item?.name?.trim().toLowerCase() === "card" && item.cwrTillId === tillId);

    // console.log("tillId------->", tillId);
    // console.log("paymentMethod------->", paymentMethod);

    const paymentProviderDetails = paymentMethod[0].paymentProviderDetails;
    const saleTypeId = paymentMethod[0].saleTypeId;
    const saleTypeName = paymentMethod[0].saleTypeName;

    // console.log("paymentProviderDetails-->", paymentProviderDetails, "saleTypeId-->", saleTypeId, "saleTypeName-->", saleTypeName);

    const requestBody = {
      PaymentProviderDetails: paymentProviderDetails,
      SaleTypeId: saleTypeId,
      SaleTypeName: saleTypeName,
    };

    const response = await axios.post(
      `${API_URL}?apiType=uploadTransaction&TransactionNumber=${cartObj.documentno}&SequenceNumber=1&AllowedPaymentMode=1&Amount=${cartObj.total}&UserID=${userData.user_id}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // const response = await axios.post(
    //   `${API_URL}?apiType=uploadTransaction&TransactionNumber=${cartObj.documentno}&SequenceNumber=1&AllowedPaymentMode=1&Amount=${cartObj.total}&UserID=${userData.user_id}&MerchantID=${process.env.REACT_APP_Merchant_ID}&SecurityToken=${process.env.REACT_APP_Security_Token}&ClientId=${process.env.REACT_APP_Client_Id}&StoreId=${process.env.REACT_APP_Store_Id}&AutoCancelDurationInMinutes=5`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );

    // console.log("Upload Response:", response.data);

    const { PlutusTransactionReferenceID } = response.data;
    const amount = cartObj.total;

    if (!PlutusTransactionReferenceID) {
      console.error("Transaction Reference ID missing in response.");
      setLoading(false);
      setIsCardPaymentLoading(false);
      return;
    }

    // Store Transaction ID
    localStorage.setItem("transactionId", PlutusTransactionReferenceID);
    localStorage.setItem("transactionAmount", amount);

    // console.log("Transaction Initiated. Checking Status...");

    // Step 2: Poll for transaction status
    await pollTransactionStatus(
      PlutusTransactionReferenceID,
      cart,
      paymentMethod,
      value,
      setCart,
      setAmount,
      setSelectedPaymentMethod,
      completePayment,
      // setShowCardPaymnteModal,
      setCardPaymnetError,
      setCardPaymnetStatus,
      setLoading,
      setIsCardPaymentLoading
    );
    // }
  } catch (error) {
    setLoading(false);
    setIsCardPaymentLoading(false);
  }
};

const pollTransactionStatus = async (
  PlutusTransactionReferenceID,
  cart,
  paymentMethod,
  value,
  setCart,
  setAmount,
  setSelectedPaymentMethod,
  completePayment,
  setCardPaymnetError,
  setCardPaymnetStatus,
  setLoading,
  setIsCardPaymentLoading,
  attempt = 1,
  maxAttempts = 10,
  interval = 5000
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}/api/paymentGateway/pineLabs`;

  if (attempt > maxAttempts) {
    console.error("Max polling attempts reached. Transaction might have failed.");
    setLoading(false);
    setIsCardPaymentLoading(false);
    return;
  }

  // console.log(`Polling attempt ${attempt} for Transaction ID: ${PlutusTransactionReferenceID}`);

  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const paymentMethodList = tillData.tillAccess?.csBunit?.paymentMethodList;
  const tillId = tillData.tillAccess?.cwrTill?.cwrTillID;
  const paymentMethod1 = paymentMethodList?.filter((item) => item?.name?.trim().toLowerCase() === "card" && item.cwrTillId === tillId);

  // console.log("tillId------->", tillId);
  // console.log("paymentMethod------->", paymentMethod1);

  const paymentProviderDetails = paymentMethod1[0].paymentProviderDetails;
  const saleTypeId = paymentMethod1[0].saleTypeId;
  const saleTypeName = paymentMethod1[0].saleTypeName;

  // console.log("paymentProviderDetails-->", paymentProviderDetails, "saleTypeId-->", saleTypeId, "saleTypeName-->", saleTypeName);

  // PaymentProviderDetails: paymentProviderDetails,
  const details = {
    PaymentProviderDetails: JSON.stringify(paymentProviderDetails),
    SaleTypeId: saleTypeId,
    SaleTypeName: saleTypeName,
  };

  try {
    // const statusResponse = await axios.get(`${API_URL}?apiType=checkStatus&transaction_id=${PlutusTransactionReferenceID}`, requestBody, {
    //   headers: { "Content-Type": "application/json" },
    // });

    const queryParams = new URLSearchParams(details).toString(); // Convert object to URL query string

    const statusResponse = await axios.get(`${API_URL}?apiType=checkStatus&transaction_id=${PlutusTransactionReferenceID}&${queryParams}`, {
      headers: { "Content-Type": "application/json" },
    });

    // console.log("Transaction Status Response:", statusResponse.data);
    const { ResponseMessage, TransactionData } = statusResponse.data;

    if (ResponseMessage === "TXN APPROVED") {
      setLoading(false);
      setIsCardPaymentLoading(false);
      // cart.cardPaymentData.payload = [{ PlutusTransactionReferenceID, amount: cart.total }];
      // cart.cardPaymentData.response = [TransactionData];

      completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
      return;
    }

    // If not approved, retry after delay
    setTimeout(() => {
      pollTransactionStatus(
        PlutusTransactionReferenceID,
        cart,
        paymentMethod,
        value,
        setCart,
        setAmount,
        setSelectedPaymentMethod,
        completePayment,
        setCardPaymnetError,
        setCardPaymnetStatus,
        setLoading,
        setIsCardPaymentLoading,
        attempt + 1,
        maxAttempts,
        interval
      );
    }, interval);
  } catch (error) {
    console.error("Error checking transaction status:", error);

    // Retry on failure
    setTimeout(() => {
      pollTransactionStatus(
        PlutusTransactionReferenceID,
        cart,
        paymentMethod,
        value,
        setCart,
        setAmount,
        setSelectedPaymentMethod,
        completePayment,
        setCardPaymnetError,
        setCardPaymnetStatus,
        setLoading,
        setIsCardPaymentLoading,
        attempt + 1,
        maxAttempts,
        interval
      );
    }, interval);
  }
};
