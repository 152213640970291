import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Table, Button, Input, Select, message, Modal, Form, Card, Typography, Drawer, Tag } from "antd";
import { CloseSquareFilled, PlusOutlined } from "@ant-design/icons";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import Axios from "axios";
import db from "../../database";
import POSLogo from "../../assets/images/NewLogoCW.svg";
import Close from "../../assets/images/close-x.svg";
import sideMenu from "../../assets/images/side-menu.svg";
import userIcon from "../../assets/images/userId.svg";
import Online from "../../assets/images/green.svg";
import Ofline from "../../assets/images/red.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import DeleteProduct from "../../assets/images/delete1.svg";
import NotFoundImg from "../../assets/images/NotFoundImg.svg";
import LockIcon from "../../assets/images/lock-icon.svg";
import LockScreen from "../../assets/images/lockScreen.svg";
import { Scrollbars } from "react-custom-scrollbars";
import unlinkTill from "../../assets/images/unlink-icon.svg";
import Logout from "../../assets/images/logout-new.svg";

const { Title, Paragraph } = Typography;

const styles = {
  container: {
    padding: "5px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    borderBottom: "1px solid #f0f0f0",
    paddingBottom: "16px",
    marginBottom: "24px",
  },
  searchContainer: {
    position: "relative",
    marginBottom: "16px",
  },
  confirmButton: {
    width: "100%",
    height: "96px",
    fontSize: "24px",
    fontWeight: "normal",
    backgroundColor: "black", // Gray to match screenshot
    borderColor: "#9e9e9e",
    color: "white",
  },
  card: {
    marginBottom: "16px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  modalTable: {
    marginTop: "8px",
  },
  inputNumber: {
    width: "100%",
    borderRadius: "6px",
  },
  modalActionButton: {
    borderRadius: "6px",
  },
};

const ProductionRMIssue = () => {
  const { access_token, username } = getOAuthHeaders();
  const history = useHistory();
  const genericUrl = process.env.REACT_APP_genericUrl;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const retailUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const domainURL = process.env.REACT_APP_domain;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfigData = JSON.parse(localStorage.getItem("posConfigData"));
  const [tillLink, setTillLink] = useState(false);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const csBunitId = tillData.tillAccess.csBunit.csBunitId;
  const inputSearchRef = useRef(null);
  const tableRef = useRef(null);
  const formattedDateTime = `${new Date().toLocaleTimeString()} | ${new Date().toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;
  const [mtsModalVisible, setMtsModalVisible] = useState(false);
  const [mtoModalVisible, setMtoModalVisible] = useState(false);
  const [makeToStockData, setMakeToStockData] = useState([]);
  const [makeToOrderData, setMakeToOrderData] = useState([]);
  const [mainProducts, setMainProducts] = useState([]);
  const [storeBins, setStoreBins] = useState([]);
  const [selectedBin, setSelectedBin] = useState("");
  const [searchedProductsFlag, setSearchedProductsFlag] = useState(false);
  const [searchedProductsDrawerData, setSearchedProductsDrawerData] = useState([]);
  const [searchTextStock, setSearchTextStock] = useState("");
  const [searchTextOrder, setSearchTextOrder] = useState("");
  const [editingInput, setEditingInput] = useState(false);
  const [finalMainProductsModalVisible, setFinalMainProductsModalVisible] = useState(false);

  // const filteredStockData = makeToStockData?.filter((item) => item?.name?.toLowerCase().includes(searchTextStock.toLowerCase()));
  // const filteredOrderData = makeToOrderData?.filter((item) => item?.product?.toLowerCase().includes(searchTextOrder.toLowerCase()));

  useEffect(() => {
    const fetchStoreBins = async () => {
      try {
        let id = tillData?.tillAccess?.cwrTill?.cwrTillID;
        if (!id) {
          message.warning("Till ID is missing.");
          return;
        }

        const params = { tillId: id };
        const stringifiedFields = JSON.stringify(params);
        const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
        const stringRequest = JSON.stringify(updatedStrings);

        const storeBinsQuery = {
          query: `mutation { executeAPIBuilder(apiBuilderId: "67b86a67f34cb07cda3d57d0", params: ${stringRequest})}`,
        };

        const headers = { "Content-Type": "application/json", Authorization: access_token };
        const storeBinsResponse = await Axios.post(genericUrl, storeBinsQuery, { headers });

        if (storeBinsResponse.status === 200 && storeBinsResponse.data?.data?.executeAPIBuilder) {
          let storeBinsData = JSON.parse(storeBinsResponse.data.data.executeAPIBuilder);

          if (!storeBinsData.length) {
            message.warning("No storage bins found.");
            return;
          }
          setStoreBins(storeBinsData);
          setSelectedBin(storeBinsData[0].m_storagebin_id);
        } else {
          console.error("Failed to fetch store bins:", storeBinsResponse.data);
          message.error("Failed to load store bins. Please try again.");
        }
      } catch (error) {
        console.error("Error in fetchStoreBins:", error.message || error);
        message.error(`Error in fetching store bins: ${error.message || error}`);
      }
    };

    fetchStoreBins();
  }, []);

  const toFixedTruncate = (num, decimalPlaces = 2) => {
    if (isNaN(num) || num === null) num = 0;
    const factor = Math.pow(10, decimalPlaces);
    const truncatedNum = Math.trunc(num * factor) / factor;
    return parseFloat(truncatedNum.toFixed(decimalPlaces));
  };

  const notFoundModal = () => {
    Modal.info({
      icon: null,
      okButtonProps: { style: { display: "none" } },
      okText: "Go Back",
      content: (
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            fontSize: "1vw",
          }}
        >
          <img src={NotFoundImg} alt="Not Found" style={{ width: "120px", height: "100px", marginBottom: "5px", marginTop: "10px" }} />
          <p style={{ margin: "5px 0", fontSize: "2em", fontWeight: "600", color: "#0F0718" }}>Product Not Found</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5" }} />
          <p style={{ color: "#0F0718", fontWeight: 400, fontSize: "1.25em", padding: "15px 15px 0 15px" }}>
            The scanned product is not in the system. Please check the barcode or enter the item manually.
          </p>
        </div>
      ),
      style: {
        textAlign: "center",
        height: "50vh",
        width: "50%",
      },
      bodyStyle: {
        padding: "0",
      },
      closable: false,
      onOk: null,
    });
    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  };

  const handleLogin = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());

    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }
    localStorage.setItem("locked", isLocked);
    const redirectWithIsLocked = `${SSOURL}sign-in/?redirect_uri=${redirectUrl}&username=${username}&readOnly=true`;
    window.location.assign(redirectWithIsLocked);
  };

  const updateProductWithUOMData = async (product) => {
    let uomData = [];
    if (product.cs_uom_id) {
      await db.productUom
        .where("csUomId")
        .equals(product.cs_uom_id)
        .toArray()
        .then((uom) => {
          if (uom.length > 0) {
            uomData = uom;
          }
        });
    }
    return {
      ...product,
      ediCode: uomData[0]?.ediCode,
      uomData,
      qty: parseFloat(product.qty),
    };
  };

  const handleMakeToStock = async () => {
    try {
      const params = {
        searchKey: "",
        bunitId: csBunitId,
        storageBinId: selectedBin,
        limit: "50",
        offset: "0",
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToStockQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b8539ef34cb07cda3d57b4", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToStockResponse = await Axios.post(genericUrl, makeToStockQuery, { headers });

      if (makeToStockResponse.status === 200 && makeToStockResponse.data?.data?.executeAPIBuilder) {
        let makeToStockResponseData = JSON.parse(makeToStockResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToStockResponseData) || makeToStockResponseData.length === 0) {
          message.warning("No stock data available.");
          return;
        }

        const updatedData = makeToStockResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: "",
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        setMakeToStockData([...modifiedData]);
        setMtsModalVisible(true);
      } else {
        console.error("API response error:", makeToStockResponse.data);
        message.error("Failed to retrieve stock data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToStock:", error.message || error);
      message.error(`Error in retrieving stock: ${error.message || error}`);
    }
  };

  const fetchMoreStockData = async () => {
    try {
      const params = {
        searchKey: `${searchTextStock}`,
        bunitId: csBunitId,
        storageBinId: selectedBin,
        limit: "50",
        offset: `${makeToStockData.length}`,
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToStockQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b8539ef34cb07cda3d57b4", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToStockResponse = await Axios.post(genericUrl, makeToStockQuery, { headers });

      if (makeToStockResponse.status === 200 && makeToStockResponse.data?.data?.executeAPIBuilder) {
        let makeToStockResponseData = JSON.parse(makeToStockResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToStockResponseData) || makeToStockResponseData.length === 0) {
          message.warning("No stock data available.");
          return;
        }

        const updatedData = makeToStockResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: "",
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        let existingData = [...makeToStockData];
        setMakeToStockData([...existingData, ...modifiedData]);
      } else {
        console.error("API response error:", makeToStockResponse.data);
        message.error("Failed to retrieve stock data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToStock:", error.message || error);
      message.error(`Error in retrieving stock: ${error.message || error}`);
    }
  };

  const handleStockScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      fetchMoreStockData();
    }
  };

  const handleSearchedStock = async () => {
    try {
      const params = {
        searchKey: `${searchTextStock}`,
        bunitId: csBunitId,
        storageBinId: selectedBin,
        limit: "50",
        offset: "0",
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToStockQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b8539ef34cb07cda3d57b4", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToStockResponse = await Axios.post(genericUrl, makeToStockQuery, { headers });

      if (makeToStockResponse.status === 200 && makeToStockResponse.data?.data?.executeAPIBuilder) {
        let makeToStockResponseData = JSON.parse(makeToStockResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToStockResponseData) || makeToStockResponseData.length === 0) {
          message.warning("No stock data available.");
          return;
        }

        const updatedData = makeToStockResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: "",
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        // let existingData = [...makeToStockData];
        setMakeToStockData([...modifiedData]);
      } else {
        console.error("API response error:", makeToStockResponse.data);
        message.error("Failed to retrieve stock data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToStock:", error.message || error);
      message.error(`Error in retrieving stock: ${error.message || error}`);
    }
  };

  const handleMakeToOrder = async () => {
    try {
      const params = {
        searchKey: "",
        bunitId: csBunitId,
        tillId: tillData?.tillAccess?.cwrTill?.cwrTillID,
        storageBinId: selectedBin,
        limit: "50",
        offset: "0",
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToOrderQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b83968f34cb07cda3d57b0", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToOrderResponse = await Axios.post(genericUrl, makeToOrderQuery, { headers });
      if (makeToOrderResponse.status === 200 && makeToOrderResponse.data?.data?.executeAPIBuilder) {
        let makeToOrderResponseData = JSON.parse(makeToOrderResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToOrderResponseData) || makeToOrderResponseData.length === 0) {
          message.warning("No order data available.");
          return;
        }

        const updatedData = makeToOrderResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: Math.max(0, item?.qtyrequested - item?.qtyavailable),
          qtyavailable: item?.qtyavailable || 0,
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        setMakeToOrderData([...modifiedData]);
        setMtoModalVisible(true);
      } else {
        console.error("API response error:", makeToOrderResponse.data);
        message.error("Failed to retrieve order data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToOrder:", error.message || error);
      message.error(`Error in retrieving orders: ${error.message || error}`);
    }
  };

  const fetchMoreOrderData = async () => {
    try {
      const params = {
        searchKey: `${searchTextOrder}`,
        bunitId: csBunitId,
        tillId: tillData?.tillAccess?.cwrTill?.cwrTillID,
        storageBinId: selectedBin,
        limit: "50",
        offset: `${makeToOrderData.length}`,
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToOrderQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b83968f34cb07cda3d57b0", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToOrderResponse = await Axios.post(genericUrl, makeToOrderQuery, { headers });
      if (makeToOrderResponse.status === 200 && makeToOrderResponse.data?.data?.executeAPIBuilder) {
        let makeToOrderResponseData = JSON.parse(makeToOrderResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToOrderResponseData) || makeToOrderResponseData.length === 0) {
          message.warning("No order data available.");
          return;
        }

        const updatedData = makeToOrderResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: Math.max(0, item?.qtyrequested - item?.qtyavailable),
          qtyavailable: item?.qtyavailable || 0,
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        let existingData = [...makeToOrderData];
        setMakeToOrderData([existingData, ...modifiedData]);
      } else {
        console.error("API response error:", makeToOrderResponse.data);
        message.error("Failed to retrieve order data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToOrder:", error.message || error);
      message.error(`Error in retrieving orders: ${error.message || error}`);
    }
  };

  const handleOrderScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      fetchMoreOrderData();
    }
  };

  const handleSearchedOrder = async () => {
    try {
      const params = {
        searchKey: `${searchTextOrder}`,
        bunitId: csBunitId,
        tillId: tillData?.tillAccess?.cwrTill?.cwrTillID,
        storageBinId: selectedBin,
        limit: "50",
        offset: "0",
      };

      const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
      const makeToOrderQuery = {
        query: `mutation { executeAPIBuilder ( apiBuilderId : "67b83968f34cb07cda3d57b0", params: "${paramsString}" )}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };

      const makeToOrderResponse = await Axios.post(genericUrl, makeToOrderQuery, { headers });
      if (makeToOrderResponse.status === 200 && makeToOrderResponse.data?.data?.executeAPIBuilder) {
        let makeToOrderResponseData = JSON.parse(makeToOrderResponse.data.data.executeAPIBuilder);

        if (!Array.isArray(makeToOrderResponseData) || makeToOrderResponseData.length === 0) {
          message.warning("No order data available.");
          return;
        }

        const updatedData = makeToOrderResponseData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          requiredQuantity: Math.max(0, item?.qtyrequested - item?.qtyavailable),
          qtyavailable: item?.qtyavailable || 0,
          qtyonhand: item?.qtyonhand || 0,
        }));

        const modifiedData = await Promise.all(updatedData.map((product) => updateProductWithUOMData(product)));
        // let existingData = [...makeToOrderData];
        setMakeToOrderData([...modifiedData]);
      } else {
        console.error("API response error:", makeToOrderResponse.data);
        message.error("Failed to retrieve order data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleMakeToOrder:", error.message || error);
      message.error(`Error in retrieving orders: ${error.message || error}`);
    }
  };

  const updateMakeToStockData = (productId, value) => {
    setMakeToStockData((prevData) => {
      const data = [...prevData];
      const index = data.findIndex((item) => item.m_product_id === productId);
      if (index === -1) return prevData;
      let sanitizedValue = value;
      const isDecimalAllowed = data[index]?.uomData?.[0]?.decimal === "Y";
      const stdPrecision = data[index]?.uomData?.[0]?.stdprecision || 3;
      sanitizedValue = isDecimalAllowed ? sanitizedValue.replace(/[^0-9.]/g, "") : sanitizedValue.replace(/[^0-9]/g, "");
      if (isDecimalAllowed) {
        const dotCount = (sanitizedValue.match(/\./g) || []).length;
        if (dotCount > 1) {
          sanitizedValue = sanitizedValue.slice(0, sanitizedValue.lastIndexOf("."));
        }
        if (sanitizedValue.startsWith(".")) {
          sanitizedValue = "0" + sanitizedValue;
        }
      }
      if (isDecimalAllowed && sanitizedValue.includes(".")) {
        const [integerPart, decimalPart] = sanitizedValue.split(".");
        sanitizedValue = integerPart + "." + decimalPart.slice(0, stdPrecision);
      }
      const parsedValue =
        sanitizedValue === "" || sanitizedValue === "." ? "" : isDecimalAllowed && sanitizedValue.endsWith(".") ? sanitizedValue : parseFloat(sanitizedValue) || 0;
      data[index] = { ...data[index], requiredQuantity: parsedValue };
      return data;
    });
  };

  const updateMakeToOrderData = (productId, value) => {
    setMakeToOrderData((prevData) => {
      const data = [...prevData];
      const index = data.findIndex((item) => item.m_product_id === productId);
      if (index === -1) return prevData;
      let sanitizedValue = value;
      const isDecimalAllowed = data[index]?.uomData?.[0]?.decimal === "Y";
      const stdPrecision = data[index]?.uomData?.[0]?.stdprecision || 3;
      sanitizedValue = isDecimalAllowed ? sanitizedValue.replace(/[^0-9.]/g, "") : sanitizedValue.replace(/[^0-9]/g, "");
      if (isDecimalAllowed) {
        const dotCount = (sanitizedValue.match(/\./g) || []).length;
        if (dotCount > 1) {
          sanitizedValue = sanitizedValue.slice(0, sanitizedValue.lastIndexOf("."));
        }
        if (sanitizedValue.startsWith(".")) {
          sanitizedValue = "0" + sanitizedValue;
        }
      }
      if (isDecimalAllowed && sanitizedValue.includes(".")) {
        const [integerPart, decimalPart] = sanitizedValue.split(".");
        sanitizedValue = integerPart + "." + decimalPart.slice(0, stdPrecision);
      }
      const parsedValue =
        sanitizedValue === "" || sanitizedValue === "." ? "" : isDecimalAllowed && sanitizedValue.endsWith(".") ? sanitizedValue : parseFloat(sanitizedValue) || 0;
      data[index] = { ...data[index], requiredQuantity: parsedValue };
      return data;
    });
  };

  const mergeProducts = (products) => {
    const mergedProducts = products.reduce((acc, product) => {
      const existingProduct = acc.find((item) => item.m_product_id === product.m_product_id);

      if (existingProduct) {
        existingProduct.qty += product.qty;
        existingProduct.qtyonhand += product.qtyonhand;
      } else {
        acc.push({ ...product });
      }

      return acc;
    }, []);

    return mergedProducts;
  };

  const handleProductSelect = async (product) => {
    try {
      if (!product || !product.m_product_id) {
        message.warning("Invalid product selection.");
        return;
      }
      const params = {
        productId: product.m_product_id,
        bunitId: csBunitId,
        tillId: tillData?.tillAccess?.cwrTill?.cwrTillID,
        storageBinId: selectedBin,
      };
      const stringifiedFields = JSON.stringify(params);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const stringRequest = JSON.stringify(updatedStrings);
      const productQuery = {
        query: `mutation { executeAPIBuilder(apiBuilderId: "67b85e97f34cb07cda3d57cc", params: ${stringRequest})}`,
      };
      const headers = { "Content-Type": "application/json", Authorization: access_token };
      const productResponse = await Axios.post(genericUrl, productQuery, { headers });
      if (productResponse.status === 200 && productResponse.data?.data?.executeAPIBuilder) {
        let productData = JSON.parse(productResponse.data.data.executeAPIBuilder);
        if (!Array.isArray(productData) || productData.length === 0) {
          message.warning("No product data available.");
          return;
        }
        const modifiedData = await Promise.all(productData.map((product) => updateProductWithUOMData(product)));
        const updatedData = modifiedData.map((item) => ({
          ...item,
          key: item?.m_product_id,
          parentProductId: product?.m_product_id,
          parentProduct: product?.name ?? product?.product,
          qtyonhand: toFixedTruncate(item?.qtyonhand, item.uomData[0]?.stdprecision),
          requiredQty: toFixedTruncate(item?.qty * product.requiredQuantity, item.uomData[0]?.stdprecision),
          issuedQty: toFixedTruncate(Math.min(item?.qty * product.requiredQuantity, item?.qtyonhand), item.uomData[0]?.stdprecision),
        }));
        const mergedMainProducts = mergeProducts([...mainProducts, ...updatedData]);
        setMainProducts(mergedMainProducts);
        setMtsModalVisible(false);
        setMtoModalVisible(false);
        setSearchTextStock("");
        setSearchTextOrder("");
        setMakeToStockData([]);
      } else {
        console.error("API response error:", productResponse.data);
        message.error("Failed to retrieve product data. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleProductSelect:", error.message || error);
      message.error(`Error selecting product: ${error.message || error}`);
    }
  };

  const handleAddSearchedProduct = (product) => () => {
    const mergedMainProducts = mergeProducts([...mainProducts, product]);
    setMainProducts(mergedMainProducts);
    setSearchedProductsFlag(false);
    setSearchedProductsDrawerData([]);
    setSearchedProduct("");
  };

  const handleProductSearch = async () => {
    try {
      if (!searchedProduct.trim()) {
        message.warning("Please enter a product name or ID.");
        return;
      }
      let params = { searchKey: searchedProduct, bunitId: csBunitId };
      const stringifiedFields = JSON.stringify(params);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const stringRequest = JSON.stringify(updatedStrings);
      const searchedProductQuery = {
        query: `mutation { executeAPIBuilder(apiBuilderId: "67b85dcbf34cb07cda3d57c7", params: ${stringRequest})}`,
      };
      const headers = { "Content-Type": "application/json", Authorization: access_token };
      const searchedProductResponse = await Axios.post(genericUrl, searchedProductQuery, { headers });
      if (searchedProductResponse.status === 200 && searchedProductResponse.data?.data?.executeAPIBuilder) {
        let searchedProductData = JSON.parse(searchedProductResponse.data.data.executeAPIBuilder);
        if (!Array.isArray(searchedProductData) || searchedProductData.length === 0) {
          notFoundModal();
          setSearchedProduct("");
          return;
        }
        const modifiedData = await Promise.all(searchedProductData.map((product) => updateProductWithUOMData(product)));
        const updatedData = modifiedData.map((item) => ({
          ...item,
          requiredQty: toFixedTruncate(1, item?.uomData[0]?.stdprecision),
          issuedQty: toFixedTruncate(Math.min(1, item?.qtyonhand || 0), item?.uomData[0]?.stdprecision),
          qtyonhand: toFixedTruncate(item?.qtyonhand || 0, item?.uomData[0]?.stdprecision),
          isqtyonhand: toFixedTruncate(item?.isqtyonhand || 0, item?.uomData[0]?.stdprecision),
        }));
        if (updatedData.length > 1) {
          setSearchedProductsFlag(true);
          setSearchedProductsDrawerData(updatedData);
        } else {
          const mergedMainProducts = mergeProducts([...mainProducts, ...updatedData]);
          setMainProducts(mergedMainProducts);
          setSearchedProduct("");
          message.success("Product(s) added successfully.");
        }
      } else {
        console.error("API response error:", searchedProductResponse.data);
        message.error("Failed to fetch products. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleProductSearch:", error.message || error);
      message.error(`Error searching for product: ${error.message || error}`);
    }
  };

  const handleDelete = (record) => {
    const updatedProducts = mainProducts?.filter((item) => item.m_product_id !== record.m_product_id);
    setMainProducts(updatedProducts);
  };

  const handleConfirmIssue = async () => {
    if (!mainProducts.length) {
      message.warning("No products selected for issue.");
      return;
    }
    let id = tillData?.tillAccess?.cwrTill?.cwrTillID;
    if (!id) {
      message.warning("Till ID is missing.");
      return;
    }
    if (!selectedBin) {
      message.warning("Please select a storage bin.");
      return;
    }
    let date = new Date().toISOString().split("T")[0];
    let filteredProducts = mainProducts?.filter((item) => {
      if (item.issuedQty <= item.qtyonhand && item.issuedQty > 0) {
        return item;
      }
    });
    let lines = filteredProducts?.map((item) => ({
      mProductId: item.m_product_id,
      movementQty: item.issuedQty,
      mStoragebinToId: selectedBin,
      parentProductId: item.parentProductId,
    }));
    const query = {
      query: `mutation{
            upsertProductionRMIssue(order:{
                    cSBunitID:"${csBunitId}",
                    cwrTillId: "${id}",
                    movementdate:"${date}",
                    lines: ${JSON.stringify(lines).replace(/"(\w+)":/g, "$1:")}
                }){
                status
                message
                recordId
            }
        }`,
    };
    const headers = { "Content-Type": "application/json", Authorization: access_token };
    const response = await Axios.post(serverUrl, query, { headers });
    if (response.status === 200) {
      let responseData = response.data.data.upsertProductionRMIssue;
      message.success(`${responseData?.recordId} ${responseData?.message}`);
      setMainProducts([]);
      setFinalMainProductsModalVisible(false);
    } else {
      console.error("API response error:", response.data);
      message.error("API response error:", response.data);
      setFinalMainProductsModalVisible(false);
    }
  };

  const confirmUnlink = () => {
    Modal.confirm({
      title: "Confirm Unlink",
      content: "Are you sure you want to unlink the till?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        await tillUnlink(); // Perform the unlink operation on confirmation
      },
      onCancel: () => {
        console.log("Unlink operation cancelled");
      },
    });
  };

  const tillUnlink = async () => {
    const unlinkTillAPI = async () => {
      try {
        let id = tillData.tillAccess.cwrTill.cwrTillID; // Ensure `tillData` is defined and accessible
        const response = await Axios({
          url: retailUrl, // Ensure `retailUrl` is defined
          method: "POST",
          data: {
            query: `mutation {
                        unlinkTill(tillId: "${id}") {
                            status
                            message
                        } 
                    }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${access_token}`, // Ensure `access_token` is defined
          },
        });

        if (response.data.data.unlinkTill.status === "200") {
          const keepKey = ""; // Specify any keys to retain if needed
          localStorage.clear(); // Clear local storage

          // Clear IndexedDB tables
          await Promise.all(
            db.tables.map(async (table) => {
              if (table.name !== keepKey) {
                await table.clear();
              }
            })
          );
        }

        localStorage.removeItem("unlink"); // Remove specific item
        window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`); // Redirect to SSO URL
      } catch (error) {
        console.error("Error in unlinkTillAPI:", error);
      }
    };

    await unlinkTillAPI(); // Await the internal API call
  };

  return (
    <>
      <div style={{ background: "#fff", height: "100vh" }}>
        <Row
          style={{
            padding: "0.5% 0",
            height: "9vh",
            alignItems: "center",
            fontSize: "1vw",
          }}
        >
          <Col
            onClick={() => {
              setTillLink(true);
            }}
            style={{ cursor: "pointer" }}
            span={1}
          >
            <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
          </Col>
          <Col span={3}>
            <img
              src={POSLogo}
              onClick={() => (posConfigData?.posType !== "PRD" ? history.push("/pos") : null)}
              style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }}
            />
          </Col>
          <Col
            span={6}
            style={{
              display: "flex",
              //   justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "1.5em",
                color: "#0F0718",
                marginLeft: "5vw",
                borderRadius: "2px",
                marginTop: "0.45rem",
              }}
            >
              Production RM Issue
            </span>
          </Col>
          <Col span={4} />
          <Col span={1} />
          <Col span={2} />
          <Col
            span={4}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "1em",
                color: "#929098",
                marginLeft: "1vw",
                marginRight: "1vw",
              }}
            >
              {" "}
              {formattedDateTime}
            </span>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                setIsLocked(true);
              }}
              src={LockIcon}
              style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0.5vw",
                border: "1px solid #3C424B",
                borderRadius: "50%",
              }}
            >
              <img src={userIcon} style={{ height: "2.7vh" }} />
            </div>
            <div
              style={{
                marginLeft: "1vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "1em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                {userData.user}
              </span>
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "0.8em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
              </span>
            </div>
            <div style={{ marginLeft: "3%" }}>
              <img
                src={Online}
                style={{
                  display: !navigator.onLine && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
              <img
                src={Ofline}
                style={{
                  display: navigator.onLine && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", height: "91vh" }}>
          <div style={{ width: "63.9%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "0 0 0 1.7%",
              }}
            >
              <div style={{ width: "98.3%" }}>
                <Input
                  ref={inputSearchRef}
                  placeholder="Search for products by code/name"
                  id="sm-product-search"
                  className="bomSearch-sm"
                  style={{
                    borderRadius: "7px",
                    padding: "0.8vh 0.8vw",
                    borderColor: "#fff",
                    border: "1px solid #ccc",
                  }}
                  suffix={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {searchedProduct.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => setSearchedProduct("")} /> : null}
                    </div>
                  }
                  prefix={<img src={SearchIcon} alt="" />}
                  value={searchedProduct}
                  onChange={(ev) => {
                    setSearchedProduct(ev.target.value);
                  }}
                  onPressEnter={() => handleProductSearch()}
                />
              </div>
            </div>
            <div style={{ padding: "1.5%" }}>
              <Table
                className="sm-cart-table-pos"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  borderRadius: "7px",
                  padding: "0px 7px",
                  height: "auto",
                  background: "#fff",
                  border: "1px solid #ccc",
                }}
                pagination={false}
                sticky
                id="sm-cart-table"
                columns={[
                  {
                    title: "Item",
                    dataIndex: "name",
                    key: "name",
                    width: "20%",
                  },
                  {
                    title: "UOM",
                    dataIndex: "ediCode",
                    key: "ediCode",
                    width: "7%",
                  },
                  {
                    title: "Required Qty",
                    dataIndex: "requiredQty",
                    key: "requiredQty",
                    width: "9%",
                    align: "center",
                    render: (text, record) => (
                      <span
                        style={{
                          color: record?.requiredQty > record?.qtyonhand + record?.isqtyonhand ? "red" : "black",
                        }}
                      >
                        {record?.requiredQty}
                      </span>
                    ),
                  },
                  {
                    title: "Issued Qty",
                    dataIndex: "issuedQty",
                    key: "issuedQty",
                    width: "9%",
                    align: "center",
                    render: (text, record) => (
                      <Input
                        style={{
                          textAlign: "center",
                        }}
                        value={record?.issuedQty}
                        onChange={(e) => {
                          setEditingInput(true);
                          const inputValue = e.target.value;
                          const stdPrecision = record?.uomData[0].stdprecision || 2;
                          if (!/^\d*\.?\d*$/.test(inputValue)) return;
                          const sanitizedValue = inputValue.includes(".") ? inputValue.split(".")[0] + "." + (inputValue.split(".")[1] || "").slice(0, stdPrecision) : inputValue;
                          const data = [...mainProducts];
                          const index = data.findIndex((item) => item.m_product_id === record?.m_product_id);
                          if (index !== -1) {
                            data[index].issuedQty = sanitizedValue;
                            setMakeToStockData([...data]);
                          }
                        }}
                        onBlur={() => {
                          const data = [...mainProducts];
                          const index = data.findIndex((item) => item.m_product_id === record?.m_product_id);
                          if (index !== -1) {
                            const stdPrecision = record?.uomData[0].stdprecision || 2; // Default precision
                            const parsedValue = parseFloat(data[index].issuedQty) || 0;
                            data[index].issuedQty = parseFloat(parsedValue.toFixed(stdPrecision));
                            setMakeToStockData([...data]);
                          }
                          setEditingInput(false);
                        }}
                        onPressEnter={() => {
                          const data = [...mainProducts];
                          const index = data.findIndex((item) => item.m_product_id === record?.m_product_id);
                          if (index !== -1) {
                            const stdPrecision = record?.uomData[0].stdprecision || 2; // Default precision
                            const parsedValue = parseFloat(data[index].issuedQty) || 0;
                            data[index].issuedQty = parseFloat(parsedValue.toFixed(stdPrecision));
                            setMakeToStockData([...data]);
                          }
                          setEditingInput(false);
                        }}
                      />
                    ),
                  },
                  {
                    title: "Stock on Hand",
                    dataIndex: "qtyonhand",
                    key: "qtyonhand",
                    width: "10%",
                    align: "center",
                    render: (text, record) => (
                      <span
                        style={{
                          color: record?.issuedQty > record?.qtyonhand || record?.requiredQty > record?.qtyonhand + record?.isqtyonhand || record?.qtyonhand == 0 ? "red" : "black",
                        }}
                      >
                        {record?.qtyonhand}
                      </span>
                    ),
                  },
                  {
                    title: `${storeBins?.find((item) => item?.m_storagebin_id === selectedBin)?.name} Qty`,
                    dataIndex: "isqtyonhand",
                    key: "isqtyonhand",
                    width: "10%",
                    align: "center",
                    render: (text, record) => <span style={{ color: record?.requiredQty > record?.qtyonhand + record?.isqtyonhand ? "red" : "black" }}>{record?.isqtyonhand}</span>,
                  },
                  {
                    title: "For Product",
                    dataIndex: "parentProduct",
                    key: "parentProduct",
                    width: "10%",
                    align: "center",
                    render: (text, record) => <span>{record?.parentProduct}</span>,
                  },
                  {
                    title: "",
                    dataIndex: "",
                    key: "",
                    width: "3%",
                    align: "center",
                    render: (text, record) => (
                      <img
                        src={DeleteProduct}
                        style={{
                          cursor: "pointer",
                          height: "17px",
                          width: "17px",
                        }}
                        onClick={(e) => {
                          handleDelete(record);
                        }}
                      />
                    ),
                  },
                ]}
                dataSource={mainProducts}
                // scroll={{ x: 1000 }}
              />
            </div>
          </div>
          <div
            style={{
              width: "35.6%",
              padding: "0% 0% 0 0.4%",
              borderRadius: "7px",
              paddingBottom: "0",
              height: "89.5vh",
              marginRight: "1%",
              backgroundColor: "#fff",
              position: "relative",
              fontSize: "1vw",
              //   border: "1px solid #ccc",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span style={{ fontSize: "1.2vw", fontWeight: "600" }}>Issue To :</span>
              <Select
                value={selectedBin}
                style={{ width: "80%", border: "1px solid #d9d9d9", borderRadius: "4px", marginBottom: "8px", flex: "end" }}
                placeholder="Select"
                onChange={(value) => {
                  setSelectedBin(value);
                }}
              >
                {storeBins?.map((item) => (
                  <Select.Option value={item?.m_storagebin_id}>{item?.name}</Select.Option>
                ))}
              </Select>
            </div>
            <Card hoverable style={styles.card} bodyStyle={{ padding: "24px" }} onClick={() => handleMakeToStock()}>
              <Title level={4} style={{ margin: 0, marginBottom: "8px" }}>
                Make to Stock (MTS)
              </Title>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Select finished goods to produce in advance based on demand forecasts. The system will fetch required raw materials automatically.
              </Paragraph>
            </Card>
            <Card hoverable style={styles.card} bodyStyle={{ padding: "24px" }} onClick={() => handleMakeToOrder()}>
              <Title level={4} style={{ margin: 0, marginBottom: "8px" }}>
                Make to Order (MTO)
              </Title>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Select from pending Transfer orders to produce items as per immediate demand. The system will fetch the required raw materials automatically.
              </Paragraph>
            </Card>
            <Button
              type="primary"
              style={styles.confirmButton}
              disabled={mainProducts.length === 0 || mainProducts?.filter((item) => item.issuedQty <= item.qtyonhand && item.issuedQty > 0).length === 0}
              onClick={() => setFinalMainProductsModalVisible(true)}
            >
              Confirm Issue
            </Button>
            <Modal
              title="Select Make to Stock Items"
              visible={mtsModalVisible}
              onCancel={() => {
                setMtsModalVisible(false);
                setMakeToStockData([]);
                setSearchTextStock("");
              }}
              footer={null}
              width={900}
              centered={true}
              maskClosable={true}
              bodyStyle={{ padding: "16px" }}
            >
              <Input
                style={{
                  width: "50%",
                }}
                placeholder="Search for products by code/name"
                value={searchTextStock}
                onChange={(e) => setSearchTextStock(e.target.value)}
                onPressEnter={() => handleSearchedStock()}
              />
              <Scrollbars ref={tableRef} className="productionScroll" onScrollFrame={handleStockScroll}>
                <Table
                  className="sm-cart-table-pos"
                  columns={[
                    {
                      title: "Product",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "UOM",
                      dataIndex: "uom",
                      key: "uom",
                    },
                    {
                      title: "Available Qty",
                      dataIndex: "qtyonhand",
                      key: "qtyonhand",
                    },
                    {
                      title: "Required Qty",
                      key: "requiredQty",
                      render: (_, record) => (
                        <Input
                          value={record.requiredQuantity === 0 ? "" : record.requiredQuantity} // Prevent showing 0 when clearing input
                          onChange={(e) => updateMakeToStockData(record.m_product_id, e.target.value)}
                          style={styles.inputNumber}
                        />
                      ),
                    },
                    {
                      title: "Action",
                      key: "action",
                      render: (_, record) => (
                        <Button type="default" onClick={() => handleProductSelect(record)} disabled={!record.requiredQuantity > 0} style={styles.modalActionButton}>
                          Select
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={makeToStockData}
                  pagination={false}
                  rowKey="id"
                  style={styles.modalTable}
                />
              </Scrollbars>
            </Modal>
            {/* MTO Modal - Change 'open' to 'visible' */}
            <Modal
              title="Select Make to Order Items"
              visible={mtoModalVisible}
              onCancel={() => {
                setMtoModalVisible(false);
                setMakeToOrderData([]);
                setSearchTextOrder("");
              }}
              footer={null}
              width={900}
              maskClosable={true}
              centered={true}
              bodyStyle={{ padding: "16px" }}
            >
              <Input
                style={{
                  width: "50%",
                }}
                placeholder="Search for products by code/name"
                value={searchTextOrder}
                onChange={(e) => setSearchTextOrder(e.target.value)}
                onPressEnter={() => handleSearchedOrder()}
              />
              <Scrollbars ref={tableRef} className="productionScroll" onScrollFrame={handleOrderScroll}>
                <Table
                  className="sm-cart-table-pos"
                  columns={[
                    {
                      title: "Finished Good",
                      dataIndex: "product",
                      key: "product",
                    },
                    {
                      title: "UOM",
                      dataIndex: "uom",
                      key: "uom",
                    },
                    {
                      title: "Indent Qty",
                      dataIndex: "qtyrequested",
                      key: "qtyrequested",
                    },
                    {
                      title: "Available Qty",
                      dataIndex: "qtyavailable",
                      key: "qtyavailable",
                    },
                    {
                      title: "Required Qty",
                      key: "requiredQuantity",
                      render: (_, record) => (
                        <Input value={record.requiredQuantity} onChange={(e) => updateMakeToOrderData(record.m_product_id, e.target.value)} style={styles.inputNumber} />
                      ),
                    },
                    {
                      title: "Status",
                      key: "status",
                      align: "center",
                      render: (_, record) => (
                        <Tag
                          style={{
                            textAlign: "center",
                          }}
                          color={record?.status === "Issued" ? "green" : "orange"}
                        >
                          {record?.status || "Pending"}
                        </Tag>
                      ),
                    },
                    {
                      title: "Action",
                      key: "action",
                      align: "center",
                      render: (_, record) => (
                        <Button
                          icon={<PlusOutlined />}
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#9e9e9e",
                            borderColor: "#9e9e9e",
                            color: "white",
                          }}
                          onClick={() => handleProductSelect(record)}
                          disabled={!record.requiredQuantity > 0}
                        />
                      ),
                    },
                  ]}
                  dataSource={makeToOrderData}
                  pagination={false}
                  rowKey="id"
                  style={styles.modalTable}
                />
              </Scrollbars>
            </Modal>
            <Modal
              title="Confirm Stock Issue?"
              visible={finalMainProductsModalVisible}
              onCancel={() => {
                setFinalMainProductsModalVisible(false);
              }}
              footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="default" onClick={() => setFinalMainProductsModalVisible(false)}>
                    Cancel
                  </Button>
                  <Button type="primary" onClick={() => handleConfirmIssue()}>
                    Ok
                  </Button>
                </div>
              }
              width={900}
              centered={true}
              maskClosable={true}
              bodyStyle={{ padding: "16px" }}
            >
              <Table
                className="sm-cart-table-pos"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  borderRadius: "7px",
                  padding: "0px 7px",
                  height: "auto",
                  background: "#fff",
                  border: "1px solid #ccc",
                }}
                pagination={false}
                sticky
                id="sm-cart-table"
                columns={[
                  {
                    title: "Item",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "UOM",
                    dataIndex: "ediCode",
                    key: "ediCode",
                  },
                  {
                    title: "Issued Qty",
                    dataIndex: "issuedQty",
                    key: "issuedQty",
                    align: "center",
                    render: (text, record) => <span>{record?.issuedQty}</span>,
                  },
                ]}
                dataSource={mainProducts?.filter((item) => {
                  if (item.issuedQty <= item.qtyonhand && item.issuedQty > 0) {
                    return item;
                  }
                })}
                scroll={{ y: 300 }}
              />
            </Modal>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: colorVariable ? colorVariable : "#f3f4f9",
          height: "100vh",
          width: "100%",
          display: isLocked ? "block" : "none",
        }}
      >
        <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
          <Col span={6}>
            <img
              src={POSLogo}
              style={{
                height: "2.8vw",
                paddingLeft: "1vw",
                paddingTop: "1vh",
              }}
            />
          </Col>
          <Col
            span={18}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0.5vw",
                border: "1px solid #3C424B",
                borderRadius: "50%",
              }}
            >
              <img src={userIcon} style={{ height: "2.7vh" }} />
            </div>
            <span
              style={{
                color: "#0F0718",
                fontSize: "1vw",
                fontFamily: "Inter",
                padding: "0 2%",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
            </span>
            <div style={{ paddingRight: "2%" }}>
              <img src={navigator.onLine ? Online : Ofline} style={{ height: "3.5vh", cursor: "pointer" }} />
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "17vh" }}>
          <Col span={8} />
          <Col
            span={8}
            style={{
              background: "#fff",
              borderRadius: "5px",
              height: "45vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px #00000033",
            }}
          >
            <img
              src={LockScreen}
              style={{
                marginBottom: "0.2vh",
                marginTop: "-17vh",
                width: "25%",
              }}
              alt=""
            />
            <span style={{ fontSize: "14px", fontWeight: 400 }}>Screen Locked </span>
            <Form layout="vertical" style={{ width: "80%" }}>
              <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                <label style={{ fontWeight: 500, fontSize: "15px" }}>Username</label>
                <Form.Item>
                  <Input
                    value={userData.user}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontWeight: 500,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                <Button
                  style={{
                    background: "#2F3856",
                    fontWeight: 500,
                    fontSize: "18px",
                    color: "#fff",
                    width: "100%",
                    height: "7vh",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={handleLogin}
                >
                  Log In
                </Button>
              </Col>
            </Form>
          </Col>
          <Col span={8} />
        </Row>
        <Drawer
          placement="left"
          width="20%"
          closable={false}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={POSLogo} style={{ height: "2vw" }} />
              {/* <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Add Discount</p> */}
              <img
                src={Close}
                onClick={() => setTillLink(false)}
                // style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }}
                style={{
                  marginLeft: "auto",
                  // paddingBottom: "4vh",
                  paddingTop: "2vh",
                  cursor: "pointer",
                  width: "1.5vw",
                }}
              />
            </div>
          }
          bodyStyle={{ paddingTop: 0 }}
          visible={tillLink}
          onClose={() => setTillLink(false)}
        >
          <Row gutter={[16, 16]} style={{ fontSize: "1vw" }}>
            <Col span={24}>
              <Scrollbars style={{ height: "80vh" }}>
                <Row>
                  <Col
                    span={1}
                    style={{
                      cursor: "pointer",
                      display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                    }}
                  />
                  <Col
                    // onClick={tillUnlink}
                    onClick={confirmUnlink}
                    style={{
                      cursor: "pointer",
                      display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                      marginBottom: "2vh",
                    }}
                    span={22}
                  >
                    <img src={unlinkTill} style={{ height: "2.5vh" }} alt="" />
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "1.2em",
                        paddingLeft: "1vw",
                        color: "#0F0718",
                      }}
                    >
                      Unlink Till
                    </span>
                  </Col>
                  <Col
                    span={1}
                    style={{
                      cursor: "pointer",
                      display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                    }}
                  />
                </Row>
              </Scrollbars>
            </Col>
            {/* ) : (
            ""
          )} */}
            {/* <Col span={24}>
            <Row>
              <Col span={1} />
              <Col style={{ cursor: "pointer" }} span={22}>
                <img src={couponIcon} style={{ height: "2.5vh" }} alt="" />
                <span style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2vw", paddingLeft: "1vw" }}>Coupon Code</span>
              </Col>
            </Row>
          </Col> */}
            <Col
              span={24}
              style={{
                width: "80%",
                borderTop: "1px solid #929098",
                position: "absolute",
                bottom: "2.5vh",
                padding: "1vh 1vw",
              }}
            >
              <Row>
                <Col span={1} />

                <Col
                  style={{ cursor: "pointer", paddingTop: "1vh" }}
                  onClick={async () => {
                    // if (posConfigData?.posType === "PRD") {
                    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
                    for (let i = 0; i < cookiesToDelete.length; i++) {
                      const cookie = cookiesToDelete[i];
                      const eqPos = cookie.indexOf("=");
                      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                      const domain = "domain=" + domainURL;
                      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                    }
                    localStorage.removeItem("tillSession");
                    window.location.assign("/");
                  }}
                  span={20}
                >
                  <img src={Logout} alt="" />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "1.2em",
                      color: "#0F0718",
                    }}
                  >
                    Logout
                  </span>
                </Col>
                <Col span={24} style={{ position: "absolute", bottom: "5vh", right: "0" }}>
                  <p style={{ color: "#0f071840", textAlign: "right", paddingRight: "1vw", fontSize: "0.6rem", fontWeight: 400 }}>v24Q3.4.0</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Drawer>
        <Drawer
          closable={false}
          placement="bottom"
          visible={searchedProductsFlag}
          closeIcon={null}
          maskClosable={false}
          className="filter"
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          onClose={() => setSearchedProductsFlag(false)}
          height="85vh"
          title={
            <div style={{ position: "relative", padding: "1vh 1vw" }}>
              <img
                src={Close}
                style={{
                  width: "1.5vw",
                  position: "absolute",
                  top: "2vh",
                  right: "0.2vw",
                  cursor: "pointer",
                }}
                onClick={() => setSearchedProductsFlag(false)}
                alt="Close"
              />
            </div>
          }
        >
          <Scrollbars className="productionScroll">
            {searchedProductsDrawerData?.map((product) => {
              return (
                <Row gutter={[15, 11]} style={{ width: "98%", backgroundColor: "#fff", marginTop: "2.5vh", marginLeft: "1vw", borderRadius: "10px" }}>
                  <Col span={16}>
                    <div style={{ maxWidth: "100%", overflow: "hidden", paddingTop: "1vh" }}>
                      <label style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }} className="productName"></label>
                    </div>
                    <p className="productName">
                      {`${product?.name} - ${product?.value}`}
                      <span style={{ display: "block" }}>{`Stock Qty - ${product?.qtyonhand ? product?.qtyonhand : 0}`}</span>
                    </p>
                  </Col>
                  <Col span={8} style={{ display: "flex", alignItems: "end", justifyContent: "center", flexDirection: "column" }}>
                    <span
                      style={{ fontSize: "0.8em", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 2.5vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}
                      onClick={handleAddSearchedProduct(product)}
                    >
                      ADD
                    </span>
                  </Col>
                  <span tabIndex={0}></span>
                </Row>
              );
            })}
          </Scrollbars>
        </Drawer>
      </div>
    </>
  );
};

export default ProductionRMIssue;
