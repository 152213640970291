import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Input, Button, message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { clock } from "../../utility/clock";
import { v4 as uuidv4 } from "uuid";
import { MinusOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import POSLogo from "../../assets/images/icon.png";
import leftLoading from "../../assets/images/opo.gif";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import _ from "lodash";
import moment from "moment";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { getMultilingualText } from "../../utility/getMultilingualText ";
import LoadingScreen from "../PointOfSale/Retail/SuperMarket/LoadingScreen";

const OpenTill = () => {
  const history = useHistory();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const [loading, setLoading] = useState(false);
  const paymentMethodList = tillData?.tillAccess?.csBunit?.paymentMethodList || [];
  const [noteValue, setNoteValue] = useState("");
  const [isCashPayment, setIsCashPayment] = useState(false);
  const [isExecuting, setIsExecuting] = useState(false);

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  const formattedDateTime = `${new Date().toLocaleTimeString()} | ${new Date().toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  useEffect(async () => {
    const tillSession = localStorage.getItem("tillSession");

    if (tillSession) {
      const parsedTillSession = JSON.parse(tillSession);
      if (parsedTillSession && parsedTillSession.tillStatus === "open") {
        history.push("/pos");
      }
    } else {
      await db.tillRegistrations
        .toArray()
        .then((reg) => {
          if (reg.length > 0) {
            const tillAccess = reg[0]?.tillAccess?.[0];

            if (tillAccess) {
              const csBunit = tillAccess.csBunit;
              if (csBunit && csBunit.csCurrency) {
                const curList = csBunit.csCurrency;
                const csCurrencyId = curList?.csCurrencyId;

                if (csCurrencyId) {
                  localStorage.setItem("csCurrencyId", csCurrencyId);

                  setCurrencyType({
                    currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
                    currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
                    stdPrecision: curList.stdPrecision || 2, // Default precision if not available
                  });
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error("Error fetching till registration data", err);
          // Handle the error fetching data from the database
        });
    }
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(async () => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count: 0,
      amount: 0,
    }));
    const sortedDenominations = modifiedCurrenciesList?.sort((a, b) => a.value - b.value);

    setDenominations([...sortedDenominations]);
    const cashPayment = paymentMethodList.find((p) => p.name.toLowerCase() === "cash");
    if (!cashPayment) {
      setIsCashPayment(true);
      excuteOpenTill();
    }
  }, []);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(JSON.parse(localStorage.getItem("tillData")).tillAccess.cwrTill.cashToKeep || 0);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const denominationName = parseInt(name);
    const denominationValue = parseInt(value);
    const denominationAmount = denominationName * denominationValue;
    const di = denominations.findIndex((d) => d.value === denominationName);
    denominations[di].count = isNaN(denominationValue) ? "" : denominationValue;
    denominations[di].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total + JSON.parse(localStorage.getItem("tillData")).tillAccess.cwrTill.cashToKeep);
    setDenominations([...denominations]);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const di = denominations.findIndex((d) => d.value === parseInt(e.target.name));
      denominations[di].count = 0;
      setDenominations([...denominations]);
    }
  };

  const onFieldButton = (field, action) => {
    if (action === "plus") denominations[field].count += 1;
    if (action === "minus" && denominations[field].count > 0) denominations[field].count -= 1;
    const denominationAmount = denominations[field].value * denominations[field].count;
    const denominationAmountValue = isNaN(denominationAmount) ? 0 : denominationAmount;
    denominations[field].amount = denominationAmountValue;
    let total = 0;
    denominations.forEach((denom) => {
      total += denom.amount;
    });
    setTotalAmount(total + JSON.parse(localStorage.getItem("tillData")).tillAccess.cwrTill.cashToKeep);
    setDenominations([...denominations]);
  };

  const confirmBtnRef = useRef(null);

  const excuteOpenTill = async () => {
    setLoading(true);
    if (isExecuting) {
      setLoading(false);

      return; // If already executing, prevent re-triggering
    }

    setIsExecuting(true);
    const authHeaders = getOAuthHeaders();

    if (authHeaders && authHeaders.access_token) {
      if (confirmBtnRef?.current) {
        confirmBtnRef.current.disabled = true;
      }

      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const tillData = JSON.parse(localStorage.getItem("tillData"));
      const userData = JSON.parse(localStorage.getItem("userData"));
      const posConfig = JSON.parse(localStorage.getItem("posConfig"));

      const openingCashToKeep = tillData?.tillAccess?.cwrTill?.cashToKeep || 0;
      const user = userData?.user || "";

      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        closingTime: null,
        openingCashDenominations: denominations,
        closingCashDenominations: null,
        openingCash: totalAmount,
        closingCash: 0,
        totalOpeningAmount: totalAmount,
        totalClosingExpectedAmount: 0,
        totalClosingTransactions: 0,
        totalClosingAmount: 0,
        user: user,
      };

      const ordersData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        netTotal: 0,
        grossTotal: 0,
        totalTax: 0,
        items: [],
        netReturnTotal: 0,
        grossReturnTotal: 0,
        totaReturnlTax: 0,
        salesTransactions: 0,
        returnsTransactions: 0,
        salesTax: [],
        returnTax: [],
      };

      const paymentsData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        payments: paymentMethodList || [], // Fallback to an empty array if no payment methods
      };

      if (posConfig?.DON === "Y") {
        const timeMark = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayOpeningArray = [];
        const trxId = uuidv4().replace(/-/g, "").toUpperCase();
        dayOpeningArray.push(`{
          type: "DON",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
          transactionId: "${trxId}",
          status: "SCS",
          duration: null
        }`);

        try {
          const tillId = tillData?.tillAccess?.cwrTill?.cwrTillID || "";
          const userId = tillData?.tillAccess?.csUserId || "";
          const bUnitId = tillData?.tillAccess?.csBunit?.csBunitId || "";

          if (!tillId || !userId || !bUnitId || !dayOpeningArray) {
            console.error("Missing required fields for upsertPOSLog mutation");
            return;
          }

          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `
                mutation {
                  upsertPOSLog(order: {
                    tillId: "${tillId}",
                    userId: "${userId}", 
                    bUnitId: "${bUnitId}", 
                    lines: [${dayOpeningArray}]
                  }) {
                    status   
                    message
                  }
                }
              `,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          });
        } catch (error) {
          console.error("Error during upsertPOSLog mutation:", error);
        }
      }

      if (confirmBtnRef.current) {
        confirmBtnRef.current.disabled = false;
      }

      const cashEventDetails = openTillData.openingCashDenominations.map((denomination) => ({
        cwrCashEventdetailsID: uuidv4().replace(/-/g, "").toUpperCase(),
        count: denomination.count || 0,
        amount: denomination.amount || 0,
        denomination: denomination.value || "",
      }));

      const tillCashData = tillData?.tillAccess?.csBunit?.paymentMethodList.map((payment) => {
        const isCash = payment.name.toLowerCase() === "cash";

        return {
          cwrTillCashId: uuidv4().replace(/-/g, "").toUpperCase(),
          date: moment().format("YYYY-MM-DD"),
          finPaymentmethodId: payment?.finPaymentmethodId || "",
          finFinancialAccount2Id: null,
          opening: parseFloat(isCash ? totalAmount : 0).toFixed(currencyType.stdPrecision),
          opentime: moment().format("YYYY-MM-DD HH:mm:ss"),
          closetime: null,
          notes: noteValue || "No Notes",
          sales: 0,
          netsales: 0,
          cashin: 0,
          cashout: 0,
          retainAmt: 0,
          closing: 0,
          returns: 0,
          iscash: 1,
          isclose: 0,
          pettyCashIn: 0,
          pettyCashOut: 0,
          cashInOut: [],
          cashEvents: [
            {
              cwrCashEventsID: uuidv4().replace(/-/g, "").toUpperCase(),
              amount: isCash ? openTillData.openingCash : 0,
              expected: 0,
              diff: 0,
              transactionCount: 0,
              type: "Open",
              description: "",
              cashEventDetails: isCash ? cashEventDetails : [],
            },
          ],
        };
      });

      let taxInfoData = {
        cwrTillID: tillData?.tillAccess?.cwrTill?.cwrTillID || "", // Fallback to a default value
        csbunitID: tillData?.tillAccess?.csBunit?.csBunitId || "",
        csclientID: tillData?.tillAccess?.csClientId || "",
        created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        createdby: tillData?.tillAccess?.csUserId || "",
        updated: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updatedby: tillData?.tillAccess?.csUserId || "",
        storeDailyOpsTillid: null,
        tillCash: tillCashData,
      };

      const stringifiedData = JSON.stringify(taxInfoData);
      const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"'); // Escape double quotes
      const newStringRequest = JSON.stringify(newStringifiedFields);

      const paramsInput = {
        query: `mutation{
          upsertTill(tillInfo:${newStringRequest})
          {    
            status
            message
            cwrTillID
            tillCash {
              cwrTillCashId
              date
              finPaymentmethodId
              opening
              sales
              notes
              netsales
              cashin
              cashout
              retainAmt
              closing
              returns
              iscash
              isclose
              storeDailyOpsTillid
              cashEvents {
                cwrCashEventsID
                amount
                expected
                diff
                transactionCount
                type
                description
                cashEventDetails {
                  cwrCashEventdetailsID
                  count
                  amount
                  denomination
                }
              }
            }
          }
        }`,
      };

      try {
        const response = await Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });

        const upsertTillResponse = response?.data?.data?.upsertTill;

        if (upsertTillResponse?.status === "200") {
          await db.tillEvents.add(openTillData);
          await db.ordersData.add(ordersData);
          await db.paymentsData.add(paymentsData);

          localStorage.setItem("tillSession", JSON.stringify(openTillData));
          localStorage.setItem("tillCash", JSON.stringify(upsertTillResponse.tillCash));
          localStorage.setItem("storeDailyOpsTillid", upsertTillResponse.tillCash[0]?.storeDailyOpsTillid);

          history.push("/pos");
        } else {
          localStorage.removeItem("tillSession");
          message.error(upsertTillResponse?.message || "Failed to upsert till.");
          setIsExecuting(false);
        }
      } catch (error) {
        console.error("Error during upsertTill:", error);
        setIsExecuting(false);
      }
    }
    setLoading(false);
  };

  const focusFirstElement = () => {
    setTimeout(() => {
      // cancelBtnRef.current.focus();
    }, 100);
  };

  const resetFocus = (index) => {
    if (index + 1 === denominations.length) {
      focusFirstElement();
    }
  };

  let reconstructedObject;

  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    // Find the object with the key "Open Till"
    const openTillObject = rawDataArray.find((item) => item.key === "Open Till");

    // Access the value property of the found object
    const openTillValue = openTillObject?.value;

    if (openTillValue !== undefined) {
      // Clean up the string (remove extra characters)
      const cleanedData = openTillValue.replace(/[{}"]/g, "");

      // Split the string into key-value pairs
      const keyValuePairs = cleanedData.split(",");

      // Create an object from key-value pairs
      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        // Check if the pair contains a colon
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where openTillValue is undefined
      console.error("openTillValue is undefined");
    }
  }

  const tillData1 = JSON.parse(localStorage.getItem("tillData"))?.tillAccess?.cwrTill;
  const cashToKeep = tillData1 ? tillData1?.cashToKeep : 0;

  return (
    <>
      {loading && isCashPayment ? (
        <LoadingScreen
          POSLogo={POSLogo}
          formattedDateTime={formattedDateTime}
          clock1={displayClock}
          leftLoading={leftLoading}
          percent={99}
          productCategoryPercentage={100}
          productPercentage={100}
          promotionPercentage={100}
        />
      ) : (
        <div style={data.openTill.mainContainer}>
          <Row style={{ height: "6vh", padding: "1% 0 0 1.5%" }}>
            <Col span={19} style={{ display: "flex", alignItems: "center", fontSize: "1vw" }}>
              <img src={POSLogo} style={data.openTill.header.menuIcon} alt="" />
              <label style={data.openTill.header.timeFmt}>{getMultilingualText("Open Till")}</label>
            </Col>
            <Col span={2}></Col>
            <Col span={8}></Col>
            <Col span={4} style={data.openTill.header.right}></Col>
          </Row>
          <div style={data.openTill.mainCardContainer}>
            <div style={{ padding: "1vw", display: "flex", paddingBottom: "0", fontSize: "1vw" }}>
              <div style={{ borderRadius: "3px", width: "68.5%" }}>
                <div key="z" style={data.openTill.mainCardFirst}>
                  <Row style={{ padding: "1% 0%", borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }}>
                    <Col span={7} style={data.openTill.colHead}>
                      {/* <strong>Denomination</strong> */}
                      <span>{getMultilingualText("Denomination")}</span>
                    </Col>
                    <Col span={10} style={data.openTill.colHead}>
                      {/* <strong>Count</strong> */}
                      <span>{getMultilingualText("Count")}</span>
                    </Col>
                    <Col span={7} style={data.openTill.colHead}>
                      {/* <strong>Total Amount</strong> */}
                      <span>{getMultilingualText("Total Amount")}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Scrollbars className="tillScroll">
                      {denominations.map((denom, index) => (
                        <Row style={{ fontSize: "1vw" }}>
                          <Col span={7} style={data.openTill.colAlign}>
                            <Input tabIndex={-1} readOnly={true} style={data.openTill.currency} value={denom.value} />
                          </Col>
                          <Col span={10} style={data.openTill.colAlign}>
                            <div style={data.openTill.calInputDiv}>
                              {/* <span style={data.openTill.decInpt}> */}
                              <MinusOutlined style={data.openTill.incInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                              {/* </span> */}
                              <Form.Item onBlur={() => resetFocus(index)} style={data.openTill.countFormitem}>
                                <Input tabIndex={0} name={`${denom.value}`} value={denom.count} style={data.openTill.calInput} onChange={onFieldInput} onBlur={onFieldOut} />
                              </Form.Item>
                              <PlusOutlined style={data.openTill.incInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                            </div>
                          </Col>
                          <Col span={7} style={data.openTill.colAlign}>
                            <Input
                              tabIndex={-1}
                              style={data.openTill.totalInput}
                              readOnly={true}
                              value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${denom.amount} ${
                                currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                              }`}
                            />
                          </Col>
                        </Row>
                      ))}
                      <span tabIndex={0}></span>
                    </Scrollbars>
                  </Row>
                </div>
              </div>
              <div style={{ width: "32.5%", paddingLeft: "2.3%", fontSize: "1vw", position: "relative" }}>
                <div style={data.openTill.opentillRightCard}>
                  <p style={data.openTill.emptyP}>{getMultilingualText("Opened On")}</p>
                  <div style={{ marginBottom: "3%", marginTop: "-6px", marginTop: "0.5vh" }}>
                    <span style={data.openTill.currentDate}>{currentDate}</span>
                    <span style={data.openTill.displayClock}>{displayClock}</span>
                  </div>
                  <p
                    style={{
                      marginBottom: "2%",
                      fontSize: "1.2em",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      letterSpacing: "0px",
                      color: "#0F0718",
                    }}
                  >
                    {getMultilingualText("Transactions")}
                  </p>
                  <Form>
                    <Form.Item style={{ marginBottom: "2vh", fontSize: "1vw" }}>
                      <p style={data.openTill.transactionsAmt}>{getMultilingualText("Opening Amount")}</p>
                      <Input
                        placeholder="₹0"
                        tabIndex={-1}
                        // value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${
                        //   currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                        // }`}
                        value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount)}   ${
                          currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""
                        }`}
                        readOnly={true}
                        className="transactionAmtInput"
                        style={{ marginBottom: "2vh", height: "6vh" }}
                      />

                      <p style={{ ...data.openTill.transactionsAmt, marginTop: "1vh" }}>{getMultilingualText("Notes")}</p>
                      <Input
                        tabIndex={-1}
                        placeholder="Enter note"
                        onChange={(e) => setNoteValue(e.target.value)}
                        // readOnly={true}
                        className="transactionAmtInput"
                        style={{ height: "6vh" }}
                      />
                    </Form.Item>
                  </Form>
                  {/* <p style={{
                "marginBottom": "0.5%",
                "fontSize": "2.3vh",
                "fontFamily": "Inter",
                "fontWeight": "600",
                "letterSpacing": "0px",
                "marginTop": "2.3vh",
                "color": "#000000",
              }}>Last Closure Details</p> */}

                  <Row style={{ height: "auto" }}>
                    <Scrollbars className="openTillScroll1">
                      <Row>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Outlet")}</p>
                        </Col>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{tillData.tillAccess.csBunit.name}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Register")}</p>
                        </Col>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{tillData.tillAccess.cwrTill.till}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Opening Amount")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashSaleAmount === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Cash Sale Amount")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashSaleAmount === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Cash In")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Cash Out")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Petty Cash In")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Petty Cash Out")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Expected Cash")}</p>
                        </Col>
                        <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmt}>{getMultilingualText("Actual Cash")}</p>
                        </Col>
                        <Col span={12}>
                          <p style={data.openTill.transactionsAmtRight}>{currenciesList[0].currSymbol}0</p>
                        </Col>
                      </Row>
                    </Scrollbars>
                  </Row>
                  <Row style={{ width: "29vw", paddingLeft: 0, position: "absolute", bottom: 0 }}>
                    <Button
                      tabIndex={1}
                      disabled={isExecuting}
                      className="focusButtonsTill"
                      ref={confirmBtnRef}
                      style={data.openTill.btnConfirm1}
                      /*onClick={()=>setNextFlag(true)}*/ onClick={excuteOpenTill}
                    >
                      {getMultilingualText("Next")}
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OpenTill;
